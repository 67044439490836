import React from "react";
import { format } from "date-fns";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Button,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
  NavItem,
  NavLink,
  Nav,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader,
  Modal,
  CardBody,
  Label,
} from "reactstrap";
import {
  withRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";
import { useHistory } from "react-router-dom";
// core components
import { ToastContainer, toast } from "react-toastify";
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";
import { getInvocies, setValue } from "../../redux/actions/CompanyActions";
import { connect } from "react-redux";
import ViewContract from "./ViewContract";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { FaEllipsisV } from "react-icons/fa";

import ActionTypes from "redux/ActionTypes";
import { db, auth } from "./Fire";
var delay = (function () {
  var timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();
class ViewInvoice extends React.Component {
  onError(e) {
    //(e, "error in file-viewer"); hi
  }

  componentDidMount() {
    this.authListener();
    this.props.setValue(ActionTypes.SET_ACTIVE_VIEW, 0);
    this.setState({
      status: this.props.companyState.setCurrentInvoice.data.status,
    });
    this.setState({
      colour: this.props.companyState.setCurrentInvoice.data.stat,
    });
  }

  state = {
    defaultModal: false,
    validated: false,
    interval: 0,
    status: "",
    colour: "",
    draft: false,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

markAsPaid = async() => {


  var string = "";
  string = this.props.companyState.setCurrentInvoice.id;
  const cityRef = db.collection("Invoices").doc(string);

  // Set the 'capital' field of the city
  const res = await cityRef
    .update({
      throughFruitbowl:false,
      status: "PAID",
      stat: "info",
    })
    .then((doc) => {
      const notify = () =>
        toast.success("Invoice marked as paid", {
          autoClose: 2000,
        });
      notify();
      //this.toggleModal("defaultModal");
    });

  if (this.state.status == "DRAFT") {
    this.setState({ status: "PAID" });
    this.setState({ colour: "info" });
    this.setState({ draft: false });
  }

}

  handleChangeInterval = ({ target }) => {
    var test = false;
    this.setState({ interval: target.value });
    //(target.value);
    if (!target.value || target.value < 0) {
      this.setState({ validated: false });
    } else {
      this.setState({ validated: true });
    }
  };

  prepareToSend = () => {
    this.setState({ validated: false });
    this.toggleModal("defaultModal");
  };

  renderService = (invoice, index) => {
    const { services } = this.props.companyState.setCurrentInvoice.data;
    const { currency } = this.props.companyState.setCurrentInvoice.data;
    var items = services;
    //(items);

    return (
      <tr key={index}>
        <td>{items[index].name}</td>
        <td>{items[index].qty}</td>
        <td>
          {currency.symbol}
          {items[index].price}
        </td>
        <td></td>
      </tr>
    );
  };

  renderTimeline = () => {
    var events = [{
      date: this.props.companyState.setCurrentInvoice.data.createdOnString,
      title: "Invoice Created",
      icon: "",
    },{
      date: this.props.companyState.setCurrentInvoice.data.paidOnString,
      title: "Invoice Paid",
      icon: "",
    },{
      date: this.props.companyState.setCurrentInvoice.data.contractSigned,
      title: "Contract Signed",
      icon: "",
    } ];


    

  
  return (

    <TimelineEvent
      bubbleStyle={{ color: "#000" }}
      title= "{events[0].title}"
      createdAt= "{events[0].date}"
      icon={<i className="material-icons md-18"></i>}
    >
     dsada
    </TimelineEvent>
  );


   
  };

  sendInvoice = async () => {
    this.toggleModal("defualtModal");
    var d = new Date();
    //  d.getDate()

    var interval = this.state.interval;

    var dueDate = new Date(
      new Date().getTime() + interval * 24 * 60 * 60 * 1000
    );
    var dueDateString = format(dueDate, "MMM d, yyyy");

    var string = "";
    string = this.props.companyState.setCurrentInvoice.id;
    const cityRef = db.collection("Invoices").doc(string);

    // Set the 'capital' field of the city
    const res = await cityRef
      .update({
        dueOn: dueDate,
        dueOnString: dueDateString,
        status: "SENT",
        stat: "primary",
      })
      .then((doc) => {
        const notify = () =>
          toast.success("Invoice has been sent successfully!", {
            autoClose: 2000,
          });
        notify();
        this.toggleModal("defaultModal");
      });

    if (this.state.status == "DRAFT") {
      this.setState({ status: "SENT" });
      this.setState({ colour: "primary" });
      this.setState({ draft: false });
    }
  };
  authListener = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.setState({ onboarded: true });
      } else {
        this.setState({ onboarded: false });
      }
    });
  };
  render() {
    const serviceList =
      this.props.companyState.setCurrentInvoice.data.services || [];

    const isEnabled1 = this.state.validated == true;

    return (
      <>
        {
        this.props.companyState.newUserProgress !== 3 ||
        this.state.onboarded == false ? (
          <Redirect from="/detail/viewinvoice" to="/auth/newuser" />
        ) : (
          <div>
            <Header></Header>
            <ToastContainer />
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.defaultModal}
              toggle={() => this.toggleModal("defaultModal")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="modal-title-default">
                  Send Invoice #{this.props.companyState.setCurrentInvoice.id}
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <Col>
                <Row className="justify-content-center">
                  <p>
                    When will you expect payment from{" "}
                    <strong>
                      {this.props.companyState.setCurrentInvoice.data.client}?
                    </strong>
                  </p>
                </Row>
              </Col>
              <div style={{ marginTop: "24px" }} className="modal-body">
                <p>
                  <b>Payment Due</b>
                </p>
                <Row>
                  <Col md="4">
                    <Input
                      id="exampleFormControlInput1"
                      placeholder="30"
                      onChange={this.handleChangeInterval}
                      type="number"
                      min="0"
                    />
                  </Col>
                  <Col style={{ marginTop: "10px" }} md="8">
                    {" "}
                    <p>days after invoice is sent</p>{" "}
                  </Col>
                </Row>
              </div>

              <div className="modal-footer">
                <Button
                  color="primary"
                  disabled={!isEnabled1}
                  onClick={() => this.sendInvoice()}
                  type="button"
                >
                  Send Invoice{" "}
                </Button>
              </div>
            </Modal>

            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow border-0">
                    <CardHeader className="bg-transparent pb-1">
                      <Row className="align-items-center">
                        <Button
                          onClick={() => this.props.history.goBack()}
                          className="btn-icon btn-2"
                          color="primary"
                          type="button"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-bold-left" />
                          </span>
                        </Button>
                        <div className="col">
                          <h1 className="text-black mb-0">View Invoice </h1>{" "}
                          <h5>
                            INVOICE ID:{" "}
                            {this.props.companyState.setCurrentInvoice.id}
                          </h5>
                        </div>
                        <div className="col">
                          <Nav className=" justify-content-end " pills>
                            <NavItem>
                              <h1>
                                <Badge color={this.state.colour} pill>
                                  {this.state.status}
                                </Badge>
                              </h1>
                            </NavItem>

                            {this.props.companyState.setCurrentInvoice.data
                              .status == "DRAFT" ? (
                                <NavItem>
                                <UncontrolledDropdown nav>
                                <DropdownToggle color="default" style={{backgroundColor:'#f7f7f7', color:'#000'}} nav className="nav-link-icon">
                                 <FaEllipsisV />
                                </DropdownToggle>
                                <DropdownMenu
                                  aria-labelledby="navbar-default_dropdown_1"
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem onClick={this.prepareToSend}>Send Invoice</DropdownItem>
                                
                                  {/* <DropdownItem divider />
                                  <DropdownItem>Something else here</DropdownItem> */}
                                </DropdownMenu>
                              </UncontrolledDropdown> 
                            </NavItem>
                             
                            ) : null}
                          </Nav>
                        </div>
                      </Row>
                    </CardHeader>

                    <CardBody>
                      <Row className="align-items-start">
                        <Col>
                          <Card style={{ width: "100%" }}>
                            <CardHeader className="bg-transparent pb-1">
                              <h4>
                                Created on:{" "}
                                {
                                  this.props.companyState.setCurrentInvoice.data
                                    .createdOnString
                                }
                              </h4>

                              {this.props.companyState.setCurrentInvoice.data
                                .status == "SENT" ? (
                                <h4>
                                  Due date:{" "}
                                  {
                                    this.props.companyState.setCurrentInvoice
                                      .data.dueOnString
                                  }
                                </h4>
                              ) : null}

                              {this.props.companyState.setCurrentInvoice.data
                                .status == "PAID" ? (    <h4>
                                  Paid on:{" "}
                                  {
                                    this.props.companyState.setCurrentInvoice
                                      .data.paidOnString
                                  }
                                </h4>) : null}
                                {this.props.companyState.setCurrentInvoice.data
                                .contractSigned  ? (    <h4>
                                  Signed on:{" "}
                                  {
                                    this.props.companyState.setCurrentInvoice
                                      .data.paidOnString
                                  }
                                </h4>) : null}
                            </CardHeader>

                            <CardBody>
                              <Row className="justify-content-start">
                                <Col md="4">
                                  <h5 className="form-control-label">Client</h5>
                                  <p>
                                    {
                                      this.props.companyState.setCurrentInvoice
                                        .data.client
                                    }{" "}
                                    <br />
                                    {
                                      this.props.companyState.setCurrentInvoice
                                        .data.clientName
                                    }
                                      <br />
                                      {
                                      this.props.companyState.setCurrentInvoice
                                        .data.clientEmail
                                    }
                                  </p>
                                </Col>

                                <Col md="4">
                                  <Label className="form-control-label">
                                    Contract
                                  </Label>
                                  <p>
                                    {this.props.companyState.setCurrentInvoice
                                      .data.invoiceType === false
                                    
                                      ? 
                                      <>
                                      {this.props.companyState
                                          .setCurrentInvoice.data.contractName}
                                           <Row>
                                             <Col>
                                            {this.props.companyState.setCurrentInvoice.data.contractSigned === true ? <img src={this.props.companyState.setCurrentInvoice.data.clientSignature}></img> :
                                          <p style={{fontSize:'12px'}}><i>Once your client signs the contract, their signature will appear here.</i></p>}
                                   </Col>   </Row>
                                          </>
                                      : "No Contract"}
                                  </p>
                                  {/* <p>Client Signed: 23/05/2020</p> */}
                                </Col>

                                <Col md="4">
                                  <Label className="form-control-label">
                                    Billing Schedule
                                  </Label>
                                  <p>
                                    {
                                      this.props.companyState.setCurrentInvoice
                                        .data.billingSchedule
                                    }
                                  </p>
                               
                                  {/* {this.props.companyState.setCurrentInvoice
                                    .data.billingSchedule !== "Once Off" ? (
                                      <>
                                    <p>Next Billing Date: </p>
                                    <Button size="sm">Cancel Subscription</Button>
                                    </>
                                  ) : null} */}
                                    
                                </Col>
                                <Col md="4"></Col>
                              </Row>
                            </CardBody>
                          </Card>

{this.props.companyState.setCurrentInvoice.data.memo ?
   <Card style={{ marginTop: "32px" }}>
                            <CardBody>
                            <Row className="justify-content-start">
                              <Col md="4">
                                <h5 className="form-control-label">Invoice Memo</h5>
                               
                            <p>{this.props.companyState.setCurrentInvoice.data.memo}</p>
                              </Col>
                       
                        </Row>
                      
                          
                         
                          </CardBody> </Card>: null
  }
                                

                          <Card style={{ marginTop: "32px" }}>
                            <CardHeader className="bg-transparent pb-1">
                              <h2>Services</h2>
                            </CardHeader>

                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Item</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Price</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {serviceList.map(this.renderService)}
                                {/* <td>
                                  <b>Tax Rate</b>
                                </td> */}
                              
                                {/* <td>
                                  <b>
                                    {
                                      this.props.companyState.setCurrentInvoice
                                        .data.taxRate
                                    }
                                    %
                                  </b>
                                </td> */}
                              </tbody>
                            </Table>
                            <CardFooter>
                              <Row className="align-items-center">
                                <div className="col">
                                  <Nav className="justify-content-end" pills>
                                    <NavItem>
                                      <h4>
                                        {" "}
                                        Subtotal:{" "}
                                        {
                                         
                                            this.props.companyState
                                            .setCurrentInvoice.data.currency
                                            .symbol
                                        }
                                        {(this.props.companyState
                                          .setCurrentInvoice.data.total /
                                          (this.props.companyState
                                            .setCurrentInvoice.data.taxRate /
                                            100 +
                                            1)).toFixed(2)}
                                      </h4>
                                      <h4>
                                        {" "}
                                        Tax ({
                                      this.props.companyState.setCurrentInvoice
                                        .data.taxRate
                                    }
                                    %){": "}
                                        {
                                          this.props.companyState
                                            .setCurrentInvoice.data.currency
                                            .symbol
                                        }
                                        {(this.props.companyState
                                          .setCurrentInvoice.data.total -
                                          this.props.companyState
                                            .setCurrentInvoice.data.total /
                                            (this.props.companyState
                                              .setCurrentInvoice.data.taxRate /
                                              100 +
                                              1)).toFixed(2)}
                                      </h4>
                                      <h4>
                                        {" "}
                                        Total:{" "}
                                        {
                                          this.props.companyState
                                            .setCurrentInvoice.data.currency
                                            .symbol
                                        }
                                        {
                                          this.props.companyState
                                            .setCurrentInvoice.data.total
                                        }
                                      </h4>
                                    </NavItem>
                                  </Nav>
                                </div>
                              </Row>
                            </CardFooter>
                          </Card>
                          {/* <Card style={{ marginTop: "40px" }}>
                            <CardHeader className="bg-transparent pb-1">
                              <h2>Timeline</h2>
                            </CardHeader>
                            <CardBody>
                              <Row className="justify-content-start">
                                <Timeline>
                                 
                                
                                </Timeline>
                              </Row>
                            </CardBody>
                          </Card> */}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </Container>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyState: state.companyState,
});
export default connect(mapStateToProps, {
  getInvocies,
  setValue,
})(ViewInvoice);
