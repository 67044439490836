/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { auth, db , fb} from "./Fire";
// reactstrap components
import {

  createStripeCustomer, 
} from "../../redux/actions/CompanyActions";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  UncontrolledTooltip,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
  Container,
  Modal,
  ModalBody,
} from "reactstrap";
import Login from "./Login";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import ActionTypes from "redux/ActionTypes";
import { setValue} from "../../redux/actions/CompanyActions";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

class Register extends React.Component {
  handleChangeEmail = ({ target }) => {
    // this.setState({ [e.target.name]: e.target.value });
    //(target.value);
    this.props.setValue(ActionTypes.SET_USER_EMAIL, target.value);
    this.setState({ email: target.value });
  };
  handleChangePwd = ({ target }) => {
    // this.setState({ [e.target.name]: e.target.value });
    //(target.value);
   // this.props.setValue(ActionTypes.SET_USER_PWD, target.value);
    this.setState({ password: target.value });
    var password = 20;
  };

  componentDidMount() {

    this.getCurrentUser()
  }



  getCurrentUser = async () => {
    var userId = "";

    await auth.onAuthStateChanged((user) => {
      if (user) {
        userId = user.uid;
        //(userId);
        this.setState({ userId: user.uid });
        this.getOnboardState(user.uid);



      }
      return userId;
    });
  };

  getOnboardState = async (uid) => {
    var testState = 0;
    let citiesRef = await db.collection("Users");

    //var uid = await this.getCurrentUser()

    let query = await citiesRef
      .where("uid", "==", uid)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const user = {
            id: doc.id,
            data: doc.data(),
          };

          testState = user.data.newUserProgress;
          // //(user.data)
        });

        //(testState);
        this.setState({ newUserState: testState });
      });

    return testState;
  };

  state = {
    defaultModal: false,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  constructor(props) {
    super(props);
    // this.signUp = this.signUp.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePwd = this.handleChangePwd.bind(this);
    this.state = {
      email: "",
      password: "",
      name: "",
      company: "",
      loading: false
    };
  }
  nextPath(path) {
    this.props.history.push(path);
  }

  saveNewUser = async (url) => {
    this.setState({ loading: true });

    this.props.setValue(ActionTypes.SET_GLOBAL_LOADING, true);
    const customer = {
      name: this.state.name,
      email: this.state.email,
    };

    //  this.props.createStripeCustomer(customer)
    //save cust id to user document

   await auth
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(async (u) => {
        var t = await u.user;
        this.props.setValue(ActionTypes.SET_ONBOARDING_NAME, "");
        this.createFirestoreUser(u.user)

        // //(r);
      })
      .catch((error) => {
        this.props.setValue(ActionTypes.SET_GLOBAL_LOADING, false);

        //(error);
        const notify = () =>
        toast.error("Unable to create account. Please try again later", {
          autoClose: 3000,
        });
  
      return notify();
      });
      this.setState({ loading: false });

  };


  googleSignIn = async() => {
    var provider = new fb.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    auth.signInWithPopup(provider).then((result) => {
      // This gives you a Google Access Token.
      var token = result.credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      console.log(user)
      this.props.setValue(ActionTypes.SET_ONBOARDING_NAME, user.displayName);
      console.log(user)
      this.createFirestoreUser(user)
     }).catch((er)=> {
       console.log(er)
     });
    // var provider = new auth.signInWithPopup("p")

  }

  createFirestoreUser = async(user) => {
    const customer = {
      name: this.state.name,
      email: user.email,
    };
    await db.collection("Users").add({
          
           
      email: user.email,
     
      newUserProgress: 1,
      uid: user.uid,
      
      onboardingComplete: false,
     
    })
    .then(async (x) => {
      this.props.setValue(ActionTypes.SET_ONBOARDING_COMPLETE, false);
      this.props.setValue(ActionTypes.SET_USER_ID, user.uid);
    
    //  this.props.setValue(ActionTypes.SET_PROGRESS_TOTAL, 40);
      this.props.setValue(ActionTypes.SET_ONBOARDING_AGENCY, "");
  
      
      this.props.setValue(ActionTypes.SET_USER_DOC, x.id);
   
      this.props.setValue(ActionTypes.NEW_USER_PROGRESS, 1)
      this.props.setValue(ActionTypes.SET_GLOBAL_LOADING, false);

      this.props.createStripeCustomer(customer, x.id, true)
      this.nextPath("/detail/onboarding")
     

    }).catch((error) => {

      this.props.setValue(ActionTypes.SET_GLOBAL_LOADING, false);


    });


  }

 

  nextView = () => {
  
    this.props.setValue(ActionTypes.SET_PROGRESS_TOTAL, 40);
    this.props.setValue(ActionTypes.SET_ONBOARDING_AGENCY, "");
    this.props.setValue(ActionTypes.SET_ONBOARDING_AGENCY, "");
    this.props.setValue(ActionTypes.SET_ONBOARDING_NAME, "");
  };

  checkTotal = () => {
    const { email, password } = this.state;
    const isEnabled = validateEmail(email) && password.length > 7;
    if (isEnabled) {
      this.props.setValue(ActionTypes.SET_PROGRESS_TOTAL, 40);
    } else {
      this.props.setValue(ActionTypes.SET_PROGRESS_TOTAL, 10);
    }
  };

  render() {
    const { email, password } = this.state;
    const isEnabled = validateEmail(email) && password.length > 7;
    var progress = this.state.newUserState;
    //(progress);
    var loading = this.state.loading
    return (
      <>
       {/* {progress == null ? (
          <Spinner></Spinner>
        ) : progress === 1 ? ( //0
          <Redirect from="/detail/onboarding" to="/detail/onboarding" />
        ) : progress === 2 ? (
          <Redirect from="/detail/onboarding" to="/detail/billing" />
        ) : progress === 3 ? ( //3
          <Redirect from="/detail/onboarding" to="/admin/proposal" />
        ) : progress === 0 ? ( //1 */}
              <Col md="5" style={{marginBottom:'24px'}}>
                  <Card className="shadow border-0" style={{marginTop:''}}>
                  <CardHeader className="bg-transparent pb-1">
                        <h2>Create an Account</h2>
                        <p>Streamline your onboarding with the simplicity of Fruitbowl.io - Invoicing & Contracts all in one seamless platform</p>
                      </CardHeader>
                      <CardBody>
                        <p>Sign up & start your FREE 7 day trial. All our plans let you:</p>
                      <i style={{color:'#42e490'}} class="ni ni-check-bold"></i> <span>Send Invoices Quickly</span> 
                     <br></br>
                     <br></br>
                      <i style={{color:'#42e490'}} class="ni ni-check-bold"></i> <span>Securely Sign Digital Contracts</span> 
                      <br></br>
                      <br></br>
                      <i style={{color:'#42e490'}} class="ni ni-check-bold"></i> <span>No Added Transaction Fees</span> 
                      <br></br>
                      <br></br>
                      <i style={{color:'#42e490'}} class="ni ni-check-bold"></i> <span>Process Payments with Stripe</span> 
                      </CardBody>
                    </Card>
                      {/* <h5>You Win</h5>
                     
                        
                      <i style={{color:'#000'}} class="ni ni-check-bold"></i> <span>Send Invoices Quickly</span> 
                     <br></br>
                      <i style={{color:'#000'}} class="ni ni-check-bold"></i> <span>Securely Sign Digital Contracts</span> 
                      <br></br>
                      <i style={{color:'#000'}} class="ni ni-check-bold"></i> <span>Create & Send Invoices</span> 
                        */}
                    </Col>
       <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
            <img width="70%" src="https://d1pnnwteuly8z3.cloudfront.net/images/957037a9-a90f-436e-a37a-418387aac81a/bdeaea6e-09b6-4338-b995-7d53ce8aec42.png"></img>
               <Row className="justify-content-center" style={{marginBottom:'16px'}}>
               <small>Sign up with</small>
               </Row> 
               <div className="text-center">
                {/* <Button
                  className="btn-neutral btn-icon mr-4"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>  */}
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={this.googleSignIn}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
            </div> 
            </div>
            </CardHeader>
            <ToastContainer />

            <CardBody className="px-lg-5 py-lg-5">
             
              <Form role="form">
              <Row className="justify-content-center" style={{marginBottom:'16px'}}>
               <small>Sign up with email</small>
               </Row> 
                <FormGroup>
                  <InputGroup className="input-group mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" name="email" onChange={this.handleChangeEmail} autoComplete="new-email"/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name="password" onChange={this.handleChangePwd} placeholder="Password" type="password" autoComplete="new-password"/>
                  </InputGroup>
                </FormGroup>
                <div className="text-muted font">
                  <small>
                 
                        <span className="text-muted">
                          By signing up you agree with the{" "}
                          <a target="blank" href="https://www.fruitbowl.io/privacy" >
                            Privacy Policy
                          </a>
                        </span>
                    
                  </small>
                </div>
                {/* <Row className="my-4">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">
                          I agree with the{" "}
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row> */}
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="button" onClick={this.saveNewUser} disabled={this.props.companyState.globalLoading === true || !isEnabled}>
                    Create account
                  </Button>
                  {this.props.companyState.globalLoading == true ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : null}
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
        {/* <UncontrolledTooltip
            delay={0}
            placement="right"
            target="tooltip159654437"
          >
            e.g yourname@gmail.com
          </UncontrolledTooltip>
          <UncontrolledTooltip
            delay={0}
            placement="right"
            target="tooltip159654433"
          >
            Passwords must be at least 8 characters
          </UncontrolledTooltip> */}
         
     
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyState: state.companyState,
});
export default connect(mapStateToProps, {
  setValue, createStripeCustomer
})(Register);
