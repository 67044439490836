import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
// dev tool
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import thunkMiddleware from "redux-thunk";
import * as actionCreators from "../actions";
import reducers from "../reducers/index";

const middleware = [thunkMiddleware];

const persistConfig = {
  timeout: 0,
  key: "root",
  keyPrefix: "",
  storage,
  blacklist: ["firebaseState"],
  // whitelist : ["UI" , "Firebase", "User", "Team", "Service", "Company", "Bookings", "Booking"]
};

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers = composeWithDevTools({
  actionCreators,
  trace: true,
  traceLimit: 25,
  realtime: true,
  port: 24446,
  hostname: "localhost",
  name: "My Redux",
});

 export  const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);




// export default () => {

//   let store = createStore(persistedReducer,
//     composeEnhancers(
//       applyMiddleware(...middleware)
//     )
//           )

//   let persistor = persistStore(store)
//   return { store, persistor }
// }

//  export const store = createStore(
//   reducers,
//   composeEnhancers(
//     applyMiddleware(...middleware)
//   )
// );

// export default function Store(initialState) {

//     rootReducer = combineReducers({
//       BookingReducer,
//       UserReducer,
//     })

//     const store = createStore(rootReducer, initialState, middleware);

//     // if (module.hot) {
//     //   // Enable Webpack hot module replacement for reducers
//     //   module.hot.accept('../reducers', () => {
//     //     const nextRootReducer = require('../reducers/index');
//     //     store.replaceReducer(nextRootReducer);
//     //   });
//     // }

//     return store;
//   }