/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { format } from "date-fns";
import { Redirect } from "react-router-dom";
import moment from "moment";

import ReactDatetime from "react-datetime";
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Spinner,
  DropdownToggle,
  Media,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Modal,
  Table,
  Button,
  Alert,
  Container,
  CardBody,
  Input,
  FormGroup,
  Row,
  UncontrolledTooltip,
  Col,
  Nav,
  CardTitle,
  NavItem,
  ModalHeader,
} from "reactstrap";
// core components
import { ToastContainer, toast } from 'react-toastify';
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";
import "assets/scss/argon-dashboard-react.scss";
import NewClientModal from "./NewClientModal";
import ProposalAddServices from "./ProposalAddServices";
import ProposalAddContract from "./ProposalAddContract";
import ProposalAddClient from "./ProposalAddClient";
import { db, auth } from "./Fire";
import { connect } from "react-redux";

import { getContracts, setValue, createStripePrice, createStripeProduct,saveRecurringInvoice } from "../../redux/actions/CompanyActions";
import ActionTypes from "redux/ActionTypes";

var delay = ( function() {
  var timer = 0;
  return function(callback, ms) {
      clearTimeout (timer);
      timer = setTimeout(callback, ms);
  };
})();

function Activeview(props) {
  if (props.value === 0) {
    return <ProposalAddClient />;
  }
  if (props.value === 1) {
    return <ProposalAddServices />;
  }
  if (props.value === 2) {
    return <ProposalAddContract />;
  }
}

function ActiveTitle(props) {
  if (props.value === 0) {
    return <h2>Select Your Client</h2>;
  }
  if (props.value === 1) {
    return <h2>Add Your Services</h2>;
  }
  if (props.value === 2) {
    return <h2>Attatch A Contract</h2>;
  }
}


function testLayout() {
  return (
    <NavItem>
      <Button color="primary" onClick={this.handleForward()}>
        Next
      </Button>
    </NavItem>
  );
}

function testLayout2() {
  return (
    <div>
      <NavItem>
        <Button onClick={this.handleBack()}>Back</Button>
      </NavItem>

      <NavItem>
        <Button color="primary" onClick={this.handleForward()}>
          Next
        </Button>
      </NavItem>
    </div>
  );
}
Number.prototype.toFixedDown = function(digits) {
  var re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
      m = this.toString().match(re);
  return m ? parseFloat(m[1]) : this.valueOf();
};
class CreateProposal extends React.Component {
  state = {
    value: 0,
    loading:false,
    onboarded:true
  };

  ButtonLayout = () => {
    if (this.props.companyState.setActiveView === 0) {
      return (
        <NavItem>
          <Button color="primary" onClick={this.handleForward()}>
            Next
          </Button>
        </NavItem>
      );
    }
    if (this.props.companyState.setActiveView === 1) {
      return (
        <div>
          <NavItem>
            <Button onClick={this.handleBack()}>Back</Button>
          </NavItem>

          <NavItem>
            <Button color="primary" onClick={this.handleForward()}>
              Next
            </Button>
          </NavItem>
        </div>
      );
    }
    if (this.props.companyState.setActiveView === 2) {
      return (
        <div>
          <NavItem>
            <Button onClick={this.handleBack()}>Back</Button>
          </NavItem>
          <NavItem>
            <Button color="secondary" onClick={this.saveInvoice()}>
              Save
            </Button>
          </NavItem>
        </div>
      );
    }
  };

  constructor(props) {
    super(props);
    this.state = { value: 0, defaultModal: false };
  }

  handleForward = () => {
    this.props.setValue(
      ActionTypes.SET_ACTIVE_VIEW,
      this.props.companyState.setActiveView + 1
    );
  };
  handleBack = () => {
    this.props.setValue(
      ActionTypes.SET_ACTIVE_VIEW,
      this.props.companyState.setActiveView - 1
    );
  };


  
  saveInvoice = async(status) => {

  if (!this.props.companyState.setUser.data.onboardingComplete){
      const notify = () =>
      toast.warning("Connect your Stripe to create invoices. You can do this in Settings", {
        autoClose: 5000,
      });
    notify();
    }
    else{
  
    
   
    this.props.setValue(ActionTypes.SET_GLOBAL_LOADING, true);

    var colour = ""
    var toastType = {
      colour:"",
      message:""
    }

    if (status == "SENT") {
      colour = "warning"
      toastType.colour = "success"
      toastType.message= "successfully sent"

    }
    else  if (status == "DRAFT") {
      colour = "jdskl"
      toastType.colour = "info"
      toastType.message= "saved as draft"


    }   
    function calc(theform) {
      var num = theform.original.value, rounded = theform.rounded
      var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      rounded.value = with2Decimals
  }


 
    var d = new Date();
  //  d.getDate()
    
    var interval =  this.props.companyState.invoiceInterval

    var createdDate= new Date(d.getFullYear(),d.getMonth(),d.getDate())
    var dueDate = new Date(new Date().getTime()+(interval*24*60*60*1000));

  
    var dueDateString = format(dueDate, "MMM d, yyyy");
    var createdDateString = format(createdDate, "MMM d, yyyy");

    ////(formattedDate)
    //(createdDate)

    this.setState({loading:true})


//Save Customer stripe ID and currency and stripe account id

    if (this.props.companyState.setInvoiceType == true) {
      db.collection("Invoices").add({
     
        createdOn: createdDate,
        createdOnString: createdDateString,
        dueOn: dueDate,
        symbol:this.props.companyState.setUser.data.currency.symbol,
        dueOnString: dueDateString,
        clientEmail: this.props.companyState.invoiceClient.data.email,
        clientName: this.props.companyState.invoiceClient.data.name,
        stripeCustomerId: this.props.companyState.invoiceClient.data.stripeCustomerId,
        stripeAccountId: this.props.companyState.setUser.data.stripeAccountId,
        memo: this.props.companyState.setMemo,
        logo:this.props.companyState.setUser.data.logo,
        user:this.props.companyState.setUser.data.name,
        userEmail:this.props.companyState.setUser.data.email,
        company:this.props.companyState.setUser.data.company,
        currency: this.props.companyState.setUser.data.currency,
        currencyIso: this.props.companyState.setUser.data.currencyIso,
        client: this.props.companyState.invoiceClient.data.company,
        clientID: this.props.companyState.invoiceClient.id,
        billingSchedule: this.props.companyState.billingSchedule,
        services: this.props.companyState.setServices,
        invoiceType: true,
        uid: this.props.companyState.setUserID,
        status: status,
        total: this.newTotal(),
        stat: colour,
        taxRate: this.props.companyState.taxRate,

      }).then(async(doc) => {

        if (status == "DRAFT") {
          const notify = () => toast.info("Invoice " + toastType.message, {autoClose:3000});
  
          notify()
        
        
        }
      
        const inovice = {

          currency: this.props.companyState.setUser.data.currencyIso,
          amount: this.newTotal() * 100,
          stripeAccount: this.props.companyState.setUser.data.stripeAccountId,
          name:"test",
          docId: doc.id
      
        }
      
        if (this.props.companyState.billingSchedule === "Once Off"){
              
        }
        else if (this.props.companyState.billingSchedule === "Monthly"){
             await  this.props.saveRecurringInvoice(inovice, "month");
       //   this.props.createStripePrice(inovice, "month");
        }
        else if (this.props.companyState.billingSchedule === "Weekly"){
             await  this.props.saveRecurringInvoice(inovice, "week");
         // this.props.createStripePrice(inovice, "week");
        }
    
       
          this.props.history.push("/admin/proposal");
      

      });
    } else if (this.props.companyState.setInvoiceType == false) {
      db.collection("Invoices").add({
      
        createdOn: createdDate,
        createdOnString: createdDateString,
        dueOn: dueDate,
        dueOnString: dueDateString,
        clientEmail: this.props.companyState.invoiceClient.data.email,
        clientName: this.props.companyState.invoiceClient.data.name,
        company:this.props.companyState.setUser.data.company,
        user:this.props.companyState.setUser.data.name,
        symbol:this.props.companyState.setUser.data.currency.symbol,
        logo:this.props.companyState.setUser.data.logo,
        stripeCustomerId: this.props.companyState.invoiceClient.data.stripeCustomerId,
        stripeAccountId: this.props.companyState.setUser.data.stripeAccountId,
        userEmail:this.props.companyState.setUser.data.email,
        memo:    this.props.companyState.setMemo,
        currency: this.props.companyState.setUser.data.currency,
        currencyIso: this.props.companyState.setUser.data.currencyIso,
        client: this.props.companyState.invoiceClient.data.company,
        clientID: this.props.companyState.invoiceClient.id,
        billingSchedule: this.props.companyState.billingSchedule,
        services: this.props.companyState.setServices,
        invoiceType: false,
        contractName: this.props.companyState.invoiceContract.data.name,
        contractPath: this.props.companyState.invoiceContract.data.fileURL,
        uid: this.props.companyState.setUserID,
        status: status,
        total: this.newTotal(),
        contractSigned: false,
        stat: colour,
        taxRate: this.props.companyState.taxRate,
      }).then(async(doc) => {




        const inovice = {

          currency: this.props.companyState.setUser.data.currencyIso,
          amount: this.newTotal() * 100,
          stripeAccount: this.props.companyState.setUser.data.stripeAccountId,
          name:"test",
          docId: doc.id
      
        }
      
        if (this.props.companyState.billingSchedule === "Once Off"){
              
        }
        else if (this.props.companyState.billingSchedule === "Monthly"){
             await  this.props.saveRecurringInvoice(inovice, "month");
       //   this.props.createStripePrice(inovice, "month");
        }
        else if (this.props.companyState.billingSchedule === "Weekly"){
             await  this.props.saveRecurringInvoice(inovice, "week");
         // this.props.createStripePrice(inovice, "week");
        }
    
      

        if (status == "DRAFT") {
          const notify = () => toast.info("Invoice " + toastType.message, {autoClose:3000});
  
          notify()
         
          
        }
        this.props.history.push("/admin/proposal");
        
      });
    }

    //(this.props.companyState.setServices.services);
    
    this.setState({loading:false})

  }};

  newTotal = () => {
    var total = 0;
    var tax = this.props.companyState.taxRate / 100 + 1;
    var realTax = this.props.companyState.taxRate / 100;
    var items = this.props.companyState.setServices || [];
    var test = 0;
    var testSub = 0;
    //(items);

    const usersByLikes = items.map((item) => {
      const { qty, price } = item;

      const subtotal = qty * price;

      total = subtotal + total;
      testSub = subtotal;
    });

    total = total * tax;

    //(total);
    //(realTax);

    total = total.toFixed(2);


    return total;
  };
  componentDidMount() {
    this.authListener()
    var d = new Date();
    d.getDate()
    var formattedDate = format(d, "MMM d, yyyy");
   // this.props.createStripePrice();
    this.props.setValue(ActionTypes.SET_INVOICE_CONTRACT, null)
    this.props.setValue(ActionTypes.SET_INVOICE_TYPE, null);
    this.props.setValue(ActionTypes.SET_ACTIVE_VIEW, 0);
    this.props.setValue(ActionTypes.SET_SERVICES, []);
    this.props.setValue(ActionTypes.CHOSE_CONTRACT, false);
    this.props.setValue(ActionTypes.SET_INVOICE_CLIENT_ID, null);
    this.props.setValue(ActionTypes.SET_INVOICE_CLIENT, null);
    this.props.setValue(ActionTypes.SET_INVOICE_DATE, formattedDate);
    this.props.setValue(ActionTypes.SET_INVOICE_CREATED, d);
    this.props.setValue(ActionTypes.SET_SERVICE_TAX, 0);
  
    this.props.setValue(ActionTypes.SET_MEMO, "");
    this.props.setValue(ActionTypes.SET_INTERVAL, "");
  //(this.props.companyState.createdDate)
 
  var requiredDate=new Date(d.getFullYear(),d.getMonth(),d.getDate()+5)
  //(requiredDate)
  }
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };
  authListener = async() => {
    auth.onAuthStateChanged(async(user) => {
      if (user) {
        this.setState({  onboarded:true });
    
       
      
      } else {
        this.setState({  onboarded:false  });
      }
    });
  };
  previewInvoice = () => {


    var interval =  this.props.companyState.invoiceInterval

   
    var dueDate = new Date(new Date().getTime()+(interval*24*60*60*1000));

  
    var dueDateString = format(dueDate, "MMM d, yyyy");

    this.props.setValue(ActionTypes.SET_DUE_DATE, dueDateString);

    this.toggleModal("defaultModal");
  };

  renderServices = (service, index) => {
    // const { name, price, qty } = this.props.service

    return (
      <tr key={index}>
        <td>{service.name}</td>

        <td>{service.qty}</td>
        <td>{service.price}</td>
      </tr>
    );
  };

  render() {
    //return(<ProposalAddServices></ProposalAddServices>)
    // this.props.setValue(ActionTypes.SET_ACTIVE_VIEW, 0);

    const isEnabled1 =
      this.props.companyState.invoiceDate != null &&
      this.props.companyState.invoiceClient != null;
    const isEnabled2 =
      this.props.companyState.setServices.length > 0 &&
      this.props.companyState.billingSchedule != null &&
      this.props.companyState.invoiceInterval != ""
    const isEnabled3 =
      this.props.companyState.setServices.length > 0 &&
      this.props.companyState.choseContract != false;

    const services = this.props.companyState.setServices;

    return (
      <>

        <Header></Header>
        <Container className="mt--7" fluid>
          <Row>
          <ToastContainer />
            <div className="col">
              <Card className="shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <Row className="align-items-center">
                    <Col>
                      <Row>
                        <Button
                          onClick={() => this.props.history.goBack()}
                          className="btn-icon btn-2"
                          color="primary"
                          type="button"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-bold-left" />
                          </span>
                        </Button>

                        <h1 className="text-black mb-0">New Invoice</h1>
                      </Row>
                    </Col>
                    <div className="col">
                      <Nav className="justify-content-end" pills>
                        {this.props.companyState.setActiveView === 0 ? (
                          <NavItem>
                            <Button
                              color="primary"
                              onClick={() => this.handleForward()}
                              disabled={!isEnabled1}
                            >
                              Next
                            </Button>
                          </NavItem>
                        ) : this.props.companyState.setActiveView === 1 ? (
                          <Row>
                            <Col>
                              <NavItem>
                                <Button onClick={() => this.handleBack()}>
                                  Back
                                </Button>
                              </NavItem>
                            </Col>
                            <Col>
                              <NavItem>
                                <Button
                                  color="primary"
                                  onClick={() => this.handleForward()}
                                   disabled={!isEnabled2}
                                >
                                  Next
                                </Button>
                              </NavItem>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col>
                              <NavItem>
                                <Button onClick={() => this.handleBack()}>
                                  Back
                                </Button>
                              </NavItem>
                            </Col>
                            <Col>
                              <NavItem>
                                <Button
                                  color="primary"
                                  onClick={() => this.previewInvoice()}
                                  disabled={!isEnabled3}
                                >
                                  Save & Preview
                                </Button>
                              </NavItem>
                            </Col>
                          </Row>
                        )}
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <Modal
                  width="70%"
                  className="modal-lg"
                  isOpen={this.state.defaultModal}
                  toggle={() => this.toggleModal("defaultModal")}
                >
                  <div className="modal-header">
                    <h2 className="modal-title" id="modal-title-default">
                      Preview Invoice to { this.props.companyState.invoiceClient != null ? this.props.companyState.invoiceClient.data
                                  .name : ""} ({ this.props.companyState.invoiceClient != null ? this.props.companyState.invoiceClient.data
                                    .email : ""})
                    </h2>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.toggleModal("defaultModal")}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>

                  <CardHeader>
                    <div className="modal-body">
                      <Col>
                        <div className="justify-content-center">
                          <Row className="justify-content-center">
                            {this.props.companyState.setUser.data.customLogo ? 
                            <img
                              src={this.props.companyState.setUser.data.logo}
                              width="50%"
                            /> : <h4>[Your logo will show here]</h4>}
                          </Row>
                          <Row className="justify-content-center">
                            <small
                              className="text-muted"
                              style={{ fontSize: "15px", marginTop: "16px" }}
                            >
                              <b>
                                Invoice from{" "}
                                {this.props.companyState.setUser.data.company}
                              </b>{" "}
                            </small>
                          </Row>
                          <Row className="justify-content-center">
                            <small
                              className="text-muted"
                              style={{ fontSize: "15px", marginTop: "8px" }}
                            >
                              Billed to{" "}
                              {
                               this.props.companyState.invoiceClient != null ? this.props.companyState.invoiceClient.data
                                  .company : ""
                              }{" "}
                              ({ this.props.companyState.invoiceClient != null ? this.props.companyState.invoiceClient.data.name : ""} 
                              )
                            </small>
                          </Row>
                        </div>
                      </Col>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Col>
                        <Row className="justify-content-center">
                          <strong>
                            <p style={{ fontSize: "24px" }}>
                            {this.props.companyState.setUser.data.currency.symbol}{this.newTotal()} {this.props.companyState.setUser.data.currency.code} Due on {this.props.companyState.dueDate}
                            </p>
                          </strong>
                        </Row>
                        <Row style={{marginTop:'16px', marginBottom:'16px'}} className="justify-content-center">
                         
                            <p>{this.props.companyState.setMemo}</p>
                        </Row>
                        <Row
                          style={{ marginTop: "16px" }}
                          className="justify-content-center"
                        >
                          <Table
                            className="align-items-center table-flush"
                            responsive
                          >
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Item</th>

                                <th scope="col">Quantity</th>
                                <th scope="col">Price</th>

                                <th> </th>
                              </tr>
                            </thead>
                            <tbody>{services.map(this.renderServices)}</tbody>
                          </Table>
                        </Row>

                        <Row className="justify-content-end">
                          <small style={{ textAlign: "left" , fontSize:'16px'}}>
                            <b>
                              Subtotal: {this.props.companyState.setUser.data.currency.symbol}
                              {(this.newTotal() /
                                (
                                this.props.companyState.taxRate / 100 + 1)).toFixed(2)}
                            </b>
                          </small>
                        </Row>
                        
                        <Row
                          className="justify-content-end"
                          style={{ marginTop: "8px" }}
                        >
                           <small style={{fontSize:'16px'}}>
                            <b>
                              Tax ({this.props.companyState.taxRate}%): {this.props.companyState.setUser.data.currency.symbol}
                              {(this.newTotal() -
                                this.newTotal() /
                                  (this.props.companyState.taxRate / 100 + 1)).toFixed(2)}
                            </b>
                          </small>
                        </Row>
                        <Row
                          className="justify-content-end"
                          style={{ marginTop: "8px" }}
                        >
                          
                          <small style={{fontSize:'16px'}}>
                            <b>Amount Due: {this.props.companyState.setUser.data.currency.symbol}{this.newTotal()}</b>
                          </small>
                        </Row>
                      
                      </Col>
                    </div>
                  </CardBody>
                 <CardBody>
                 {this.props.companyState.setInvoiceType == true ?  null : <div>
                   
                   <Col>
                   <Row className="justify-content-center">
                    <p style={{fontSize: '16px'}}>Contract Attatched : {this.props.companyState.invoiceContract != null ?this.props.companyState.invoiceContract.data.name : ""}</p>
                   </Row>
                   <Row className="justify-content-center">
                    <p style={{fontSize: '12px'}}><b><i>After your client has paid the invoice, they will be sent to a page where they'll be able to read and e-sign your contract.</i></b></p>
                   </Row>
                 
                   </Col>
                   </div>}
                   </CardBody>
                  <CardFooter>
                    <Col></Col>
                  </CardFooter>
                  <div className="modal-footer">
                   
                    <Button
                      className="ml-auto"
                      color="link"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.saveInvoice("DRAFT")}
                    >
                      Save as draft
                    </Button>
                    <Button color="primary" type="button" onClick={() => this.saveInvoice("SENT")}>
                      {this.props.companyState.setInvoiceType == true ? "Send Invoice" : "Send Invoice & Contract"}
                    </Button>
                    {this.props.companyState.globalLoading == true ?   <Spinner animation="border" role="status">
  <span className="sr-only">Loading...</span>
</Spinner> : null}
                  </div>
                </Modal>

                <CardBody>
                  <Card
                    className="shadow border-0"
                    style={{ backgroundColor: "#fafafa" }}
                  >
                    <CardHeader className="bg-transparent pb-1">
                      <ActiveTitle
                        value={this.props.companyState.setActiveView}
                      />
                    </CardHeader>
                    <Activeview value={this.props.companyState.setActiveView} />

                    <CardFooter>
                      <Row className="align-items-center">
                        <div className="col">
                          <Nav className="justify-content-end" pills>
                            <NavItem>
                              <h4>
                                {" "}
                                Subtotal: {this.props.companyState.setUser.data.currency.symbol}
                                {this.newTotal()
                                  ? (this.newTotal() /
                                    (this.props.companyState.taxRate / 100 + 1)).toFixed(2)
                                  : "0.00"}
                              </h4>
                              <h4>
                                {" "}
                                Tax: {this.props.companyState.setUser.data.currency.symbol}
                                {this.newTotal()
                                  ? (this.newTotal() -
                                    this.newTotal() /
                                      (this.props.companyState.taxRate / 100 +
                                        1)).toFixed(2)
                                  : "0.00"}
                              </h4>
                              <h4>
                                {" "}
                                Total: {this.props.companyState.setUser.data.currency.symbol}
                                {this.newTotal() ? this.newTotal() : "0.00"}
                              </h4>
                            </NavItem>
                          </Nav>
                        </div>
                      </Row>
                    </CardFooter>
                  </Card>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
                                    
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyState: state.companyState,
});

export default connect(mapStateToProps, {
  setValue, createStripePrice, createStripeProduct, saveRecurringInvoice
})(CreateProposal);
