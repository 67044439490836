import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";
import "assets/scss/argon-dashboard-react.scss";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";

import {
  withRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Button,
  Container,
  CardBody,
  Spinner,
  Nav,
  Input,
  FormGroup,
  Row,
  UncontrolledTooltip,
  Col,
  Form,
  CardText,
  Label,
  Modal,
} from "reactstrap";
import { connect } from "react-redux";

import Text from "react";
import {
  getClients,
  setValue,
  getUserInfo,
  createStripeCustomer,
  updateUser,
  createStripeCharge,
  myCompanyState,
  createStripeSubscription,
  createStripePrice,
  attatchPaymentMethodFruitbowl,
  checkLoading,
} from "../../redux/actions/CompanyActions";
import ReactDatetime from "react-datetime";
import ActionTypes from "redux/ActionTypes";
import { auth, db, storage } from "./Fire";
//import store from '../../redux/store/index'
import InjectedCheckoutForm from "./CheckoutForm";
import { isThisSecond } from "date-fns";

function refreshPage() {
  window.location.reload(false);
}

const stripePromise = loadStripe("pk_live_VboC8sR4clpBJFBGdagUVyvQ00UO4YDqfg");

export default (props) => {
  const dispatch = useDispatch();
  // const { companyState } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const [userProgress, setUserProgress] = useState(null);
  const [uid, setUid] = useState(null);
  const [ukserDoc, setUserDoc] = useState("");

  const [stripeId, setStripeId] = useState("");

  const [test, setTest] = useState("");

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const [isProcessing, setProccessingTo] = useState(false);
    const [loading, setLoading] = useState(false);
    const { companyState } = useSelector((state) => state);
    const handleSubmit = async (event) => {
      event.preventDefault();
      // dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true))

      //(dispatch(checkLoading()))

      // dispatch(setValue(ActionTypes.SET_LOADING, true))
      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);

      dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

        // dispatch(setValue(ActionTypes.SET_LOADING, false))
      } else {
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

        attatchPaymentSource(paymentMethod);
        //  dispatch(setValue(ActionTypes.SET_LOADING, false))
      }
    };

    return (
      <div style={{ width: "100%", marginTop: "32px" }}>
        {" "}
        <Form disabled={companyState.globalLoading} onSubmit={handleSubmit}>
          <FormGroup>
            <CardElement width="100%" style={{ marginBottom: "32px" }} />

            <Button
              color="primary"
              block
              size="sm"
              style={{ marginTop: "16px", marginBottom: "32px" }}
              type="submit"
              disabled={companyState.globalLoading}
            >
              {companyState.globalLoading ? "Proccessing..." : "Pay Now"}
            </Button>
            <Row className="justify-content-center">
              {companyState.globalLoading ? <Spinner></Spinner> : null}{" "}
            </Row>
          </FormGroup>
        </Form>
      </div>
    );
  };

  var progress = userProgress;
  const getCurrentUser = async () => {
    var userId = "";

    await auth.onAuthStateChanged((user) => {
      if (user) {
        userId = user.uid;
        //(userId);
        // this.setState({ userId: user.uid });
        setUid(user.uid);
        getOnboardState(user.uid);
      }
      return userId;
    });
  };

  const getOnboardState = async (uid) => {
    var testState = 0;
    let citiesRef = await db.collection("Users");
    var doc = "";
    //var uid = await this.getCurrentUser()

    let query = await citiesRef
      .where("uid", "==", uid)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const user = {
            id: doc.id,
            data: doc.data(),
          };

          testSaveDocId(user.id);
          setTest(user.id);
          //(setTest);

          testState = user.data.newUserProgress;
          doc = user.id;
        });

        //(testState);
        //(doc);
        setUserProgress(testState);
      });

    return testState;
  };

  const testSaveDocId = (docId) => {
    testDocument = docId;

    return docId;
  };

  const nextPath = (path) => {
    props.history.push(path);
  };

  const getUid = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        await dispatch(getUserInfo(user.uid));
        await getStripeId(user.uid);
        //(user.uid);
      } else {
      }
    });
  };
  var testDocument = "";
  const getStripeId = async (uid) => {
    let citiesRef = await db.collection("Users");

    //var clientList = [];
    let query = await citiesRef
      .where("uid", "==", uid)

      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const cust = {
            id: doc.id,
            data: doc.data(),
          };

          setStripeId(cust.data.stripeCustomerId);

          //(cust.data);
        });
      });
  };

  const attatchPaymentSource = async (paymentMethod) => {
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));

    dispatch(setValue(ActionTypes.SET_LOADING, true));

    //("trying to attatch")
    // setLoading(true);
    await dispatch(
      attatchPaymentMethodFruitbowl(
        paymentMethod.id,
        stripeId,
        "acct_1FCad5AAaeaoFWBp",
        "companyState.setUser.id"
      )
    );

    // newSubscription();
    //await payInvoice();
  };

  const newSubscription = async () => {
    let citiesRef = await db.collection("Users").where("uid", "==", uid);

    const subscription = {
      customerId: stripeId,
      priceId: "price_1HBOLLAAaeaoFWBpVqUOWEmg",
      stripeAccount: "acct_1FCad5AAaeaoFWBp",
    };

    // update customer with payment method

    dispatch(createStripeSubscription(subscription, testDocument, true));
    //
  
    //("user Update successfuly");

    //dispatch(setValue(ActionTypes.SET_LOADING, false))
  };

  const toProposal = () => {
    // const userDoc =   props.companyState.userDoc;
    // //("s");
    // //(userDoc);

   
    nextPath("/admin/proposal");
  };

  useEffect(() => {
    getCurrentUser();
    dispatch(setValue(ActionTypes.SET_LOADING, false));
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

    //var t = dispatch(myCompanyState)
    //setCompState(t);
    getUid();
  }, []);

  return (
    <>
      {progress == null ? (
        <Spinner></Spinner>
      ) : progress === 0 ? ( //0
        <Redirect from="/detail/onboarding" to="/auth/login" />
      ) : progress === 1 ? (
        <Redirect from="/detail/onboarding" to="/detail/onboarding" />
      ) : progress === 3 ? ( //3
        <Redirect from="/detail/onboarding" to="/admin/proposal" />
      ) : progress === 2 ? ( //1
        <div>
          <Header />
          {/* Page content */}
          <Container className="mt--7" fluid>
            <Row>
              <div className="col">
                <Row className="justify-content-center">
                  <img
                    width="250px"
                    src="https://d1pnnwteuly8z3.cloudfront.net/images/957037a9-a90f-436e-a37a-418387aac81a/bdeaea6e-09b6-4338-b995-7d53ce8aec42.png"
                  />
                </Row>

                <Row className="justify-content-center">
                  <CardBody>
                    <Row className="justify-content-center">
                      <Col md="8">
                        <Row className="justify-content-center">
                          <Col md="8">
                            <h2>Billing Information</h2>
                            <Card className="shadow border-0">
                              <CardHeader>
                                <Row className="justify-content-center">
                                  You selected the{" "}
                                  <strong
                                    color="#42e490"
                                    style={{ color: "#42e490" }}
                                  >
                                    {" "}
                                    &nbsp; Fruitbowl.io Essentials Plan
                                  </strong>
                                </Row>
                              </CardHeader>
                              <CardBody>
                                <p>With this plan:</p>
                                <i
                                  style={{ color: "#42e490" }}
                                  class="ni ni-check-bold"
                                ></i>{" "}
                                <span>Create & Send Invoices</span>
                                <br></br>
                                <br></br>
                                <i
                                  style={{ color: "#42e490" }}
                                  class="ni ni-check-bold"
                                ></i>{" "}
                                <span>Get Contracts E-Signed</span>
                                <br></br>
                                <br></br>
                                <i
                                  style={{ color: "#42e490" }}
                                  class="ni ni-check-bold"
                                ></i>{" "}
                                <span>No Added Transaction Fees</span>
                                <br></br>
                                <br></br>
                                <i
                                  style={{ color: "#42e490" }}
                                  class="ni ni-check-bold"
                                ></i>{" "}
                                <span>Process Payments with Stripe</span>
                              </CardBody>
                              <CardFooter>
                                <p>
                                  Billed now: <b>£29.00 GBP</b>
                                </p>
                                <p>
                                  You'll be charged £29.00 GBP monthly. You can
                                  cancel at anytime and get a full refund within
                                  14 days of starting your subscription.
                                </p>

                                <Col>
                                  <Row>
                                    <h3
                                      style={{
                                        textAlign: "left",
                                        marginTop: "16px",
                                      }}
                                    >
                                      Payment Details
                                    </h3>
                                  </Row>
                                  <Row className="justify-content-center">
                                    <Elements stripe={stripePromise}>
                                      <CheckoutForm />
                                    </Elements>
                                  </Row>
                                </Col>
                              </CardFooter>
                            </Card>
                          </Col>
                        </Row>

                        <Col md="12"></Col>
                        <Row className="justify-content-center">
                          {" "}
                          <Col></Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      ) : (
        <Redirect from="/detail/onboarding" to="/auth/login" />
      )}
    </>
  );
};
