import { combineReducers } from "redux";
import AppReducer from "./AppReducer";
import CompanyReducer from "./CompanyReducer";
import UserReducer from "./UserReducer";

const reducers = combineReducers({
  userState: UserReducer,
  companyState: CompanyReducer,
  appState: AppReducer,
});

export default reducers;