import React from "react";
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";
import "assets/scss/argon-dashboard-react.scss";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Button,
  Container,
  CardBody,
  Input,
  FormGroup,
  Row,
  UncontrolledTooltip,
  Col,
  Form,
  CardText,
  Modal,
} from "reactstrap";
import { connect } from "react-redux";

import Text from "react";
import { getClients, setValue } from "../../redux/actions/CompanyActions";
import ReactDatetime from "react-datetime";
import ActionTypes from "redux/ActionTypes";
import { auth, db } from "./Fire";

function refreshPage() {
  window.location.reload(false);
}

class OnboardingIntegrations extends React.Component {
  constructor(props) {
    super(props);
    //this.logOut = this.logOut.bind(this);

 
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      name: "",
      email: "",
      company: "",
      uid: "",
      clientList: [],
    };
  }
  componentDidMount() {
   
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
 

  state = {
    defaultModal: false,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  render() {
  

    return (
      <Row className="justify-content-center">
        <FormGroup>
        

          <InputGroup
            className="input-group-alternative"
            style={{ width: "400px", marginBottom: "16px", marginTop: "64px" }}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
            </InputGroupAddon>
            <ReactDatetime
              defaultValue={Date.now()}
              value={this.props.companyState.invoiceDate}
              onChange={(e) =>
                this.props.setValue(ActionTypes.SET_INVOICE_DATE, e)
              }
              inputProps={{
                placeholder: "Invoice Start Date",
              }}
              timeFormat={false}
            />
          </InputGroup>
          <UncontrolledDropdown
            group
            style={{ width: "400px", marginBottom: "16px" }}
          >
            <DropdownToggle caret color="default" className="btn-block">
              {this.props.companyState.invoiceClient
                ? this.props.companyState.invoiceClient.data.company
                : "Choose a Client"}
            </DropdownToggle>
            <DropdownMenu style={{ width: "400px" }}>
              <DropdownItem
                href="#pablo"
                onClick={() => this.toggleModal("defaultModal")}
              >
                Add new client
              </DropdownItem>
              <DropdownItem divider />
              {/* {clients.map(this.renderClientList)} */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </FormGroup>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  companyState: state.companyState,
});

export default connect(mapStateToProps, {
  getClients,
  setValue,
})(OnboardingIntegrations);
