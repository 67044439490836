import ActionType from "../ActionTypes";

const INITIAL_STATE = {};

export default function CompanyReducer(state = INITIAL_STATE, action) {
    //(action)
  switch (action.type) {

    // case ActionType.SET_SELECTED_GIFT_CARDS: {
    //   return {
    //     ...state,
    //     giftCard: action.payload,
    //   };
    // }
    case ActionType.SET_CLIENTS: {
      return {
        ...state,
        clients: action.payload,
      };
    }
    case ActionType.SET_CONTRACTS: {
      return {
        ...state,
        contracts: action.payload,
      };
    }
    case ActionType.SET_INVOICE_DATE: {
      return {
        ...state,
        invoiceDate: action.payload,
      };
    }
    case ActionType.SET_SERVICE_NAME: {
      return {
        ...state,
        serviceName: action.payload,
      };
    }
    case ActionType.SET_SERVICE_QUANTITY: {
      return {
        ...state,
        serviceQuantity: action.payload,
      };
    }
    case ActionType.SET_SERVICE_PRICE: {
      return {
        ...state,
        servicePrice: action.payload,
      };
    }
    case ActionType.SET_BILLING_SCHEDULE: {
      return {
        ...state,
        billingSchedule: action.payload,
      };
    }
    case ActionType.SET_INVOICE_CLIENT: {
      return {
        ...state,
        invoiceClient: action.payload,
      };
      
    }
    case ActionType.SET_INVOICE_CONTRACT: {
      return {
        ...state,
        invoiceContract: action.payload,
      };
    }
    case ActionType.SET_INVOICE_CLIENT_ID: {
      return {
        ...state,
        invoiceClientID: action.payload,
      };
    }
    case ActionType.SET_SERVICES: {
      return {
        ...state,
        setServices: action.payload,
      };
    }
    case ActionType.SET_INVOICES: {
      return {
        ...state,
        setInvoices: action.payload,
      };
    }
    case ActionType.SET_CURRENT_INVOICE: {
      return {
        ...state,
        setCurrentInvoice: action.payload,
      };
    }
    case ActionType.SET_CURRENT_CONTRACT: {
      return {
        ...state,
        setCurrentContract: action.payload,
      };
    }
    case ActionType.SET_ACTIVE_VIEW: {
      return {
        ...state,
        setActiveView: action.payload,
      };
    }
    case ActionType.SET_USER: {
      return {
        ...state,
        setUser: action.payload,
      };
    }
    case ActionType.SET_USER_ID: {
      return {
        ...state,
        setUserID: action.payload,
      };
    }
    case ActionType.SET_INVOICE_TYPE: {
      return {
        ...state,
        setInvoiceType: action.payload,
      };
    }
    case ActionType.SERVICES: {
      return {
        ...state,
        services: action.payload,
      };
    }
    case ActionType.SET_SERVICE_TAX: {
      return {
        ...state,
        taxRate: action.payload,
      };
    }
    case ActionType.SET_ACTIVE_ONBOARDING: {
      return {
        ...state,
        onboardingView: action.payload,
      };
    }
    case ActionType.SET_ONBOARDING_COMPLETE: {
      return {
        ...state,
        onboardingComplete: action.payload,
      };
    }
    case ActionType.SET_ONBOARDING_NAME: {
      return {
        ...state,
        onboardingName: action.payload,
      };
    }
    case ActionType.SET_ONBOARDING_AGENCY: {
      return {
        ...state,
        onboardingAgency: action.payload,
      };
    }
    case ActionType.SET_ONBOARDING_AGENCY_LOGO: {
      return {
        ...state,
        onboardingAgencyLogo: action.payload,
      };
    }
    case ActionType.SET_AUTH_VIEW: {
      return {
        ...state,
        setAuthView: action.payload,
      };
    }
    case ActionType.SET_USER_EMAIL: {
      return {
        ...state,
        setUserEmail: action.payload,
      };
    }
    case ActionType.SET_USER_PWD: {
      return {
        ...state,
        setUserPwd: action.payload,
      };
    }
    case ActionType.SET_AGENCY_LOGO_URL: {
      return {
        ...state,
        agnecyLogoUrl: action.payload,
      };
    }
    case ActionType.SET_HAS_INVOICES: {
      return {
        ...state,
        hasInvoices: action.payload,
      };
    }
    case ActionType.SET_HAS_CLIENTS: {
      return {
        ...state,
        hasClients: action.payload,
      };
    }
    case ActionType.SET_HAS_CONTRACTS: {
      return {
        ...state,
        hasContracts: action.payload,
      };
    }
    

    case ActionType.SET_AUTH_PROGRESS: {
      return {
        ...state,
        setAuthProgress: action.payload,
      };
    }
    case ActionType.SET_AUTH_PROGRESS_PWD: {
      return {
        ...state,
        authPwd: action.payload,
      };
    }
    case ActionType.SET_PROGRESS_TOTAL: {
      return {
        ...state,
        progressTotal: action.payload,
      };
    }
    case ActionType.CHOSE_CONTRACT: {
      return {
        ...state,
        choseContract: action.payload,
      };
    }
    case ActionType.SET_TOTAL_PRICE: {
      return {
        ...state,
        totalPrice: action.payload,
      };
    }
    case ActionType.SET_MEMO: {
      return {
        ...state,
        setMemo: action.payload,
      };
    }
    case ActionType.SET_INVOICE_STATUS: {
      return {
        ...state,
        invoiceStatus: action.payload,
      };
    }
    case ActionType.SET_INVOICE_CREATED: {
      return {
        ...state,
        createdDate: action.payload,
      };
    }
    case ActionType.SET_INTERVAL: {
      return {
        ...state,
        invoiceInterval: action.payload,
      };
    }
    case ActionType.SET_DUE_DATE: {
      return {
        ...state,
        dueDate: action.payload,
      };
    }
    case ActionType.SET_USER_DOC: {
      return {
        ...state,
        userDoc: action.payload,
      };
    }
    case ActionType.NEW_USER_PROGRESS: {
      return {
        ...state,
        newUserProgress: action.payload,
      };
    }
    case ActionType.SET_CURRENCY_ISO: {
      return {
        ...state,
        currencyIso: action.payload,
      };
    }
    case ActionType.SET_CURRENCY: {
      return {
        ...state,
        currency: action.payload,
      };
    }
    case ActionType.SET_ACTIVE_INVOICE: {
      return {
        ...state,
        activeInvoice: action.payload,
      };
    }
    case ActionType.SET_NEW_CLIENT: {
      return {
        ...state,
        newClient: action.payload,
      };
    }
    case ActionType.SET_ACTIVE_INVOICE_ID: {
      return {
        ...state,
        activeInvoiceId: action.payload,
      };
    }
    case ActionType.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case ActionType.SET_GLOBAL_LOADING: {
      return {
        ...state,
        globalLoading: action.payload,
      };
    }
    case ActionType.SET_STRIPE_ID: {
      return {
        ...state,
        stripeComplete: action.payload,
      };
    }
    default:
        return state;
}
}
