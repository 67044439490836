import React from "react";
import FileViewer from "react-file-viewer";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Button,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
  NavItem,
  NavLink,
  Nav,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader,
  Modal,
  CardBody,
} from "reactstrap";
import { withRouter,  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,} from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
//import { CustomErrorComponent } from 'custom-error';
import { auth, db, storage } from "./Fire";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";
import ActionTypes from "redux/ActionTypes";
import { getContracts, setValue } from "../../redux/actions/CompanyActions";
import { connect } from "react-redux";
class ViewContract extends React.Component {
  state = {
    numPages: null,
    pageNumber: 1,
  };

componentDidMount(){
  this.authListener()
}

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  onError(e) {
    //(e, "error in file-viewer");
  }

  goForward = () => {
    this.setState((state) => ({ pageNumber: state.pageNumber + 1 }));
  };
  goback = () => {
    this.setState((state) => ({ pageNumber: state.pageNumber - 1 }));
  };
  downloadFile = () => {
    const url = this.props.companyState.setCurrentContract.data.fileURL;
    // `url` is the download URL for 'images/stars.jpg'

    // This can be downloaded directly:
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function (event) {
      var blob = xhr.response;
    };
    xhr.open("GET", url);
    xhr.send();

    return url;
    // Or inserted into an <img> element:
    ////(xhr.status)
  };

  authListener = async() => {
    auth.onAuthStateChanged(async(user) => {
      if (user) {
        this.setState({  onboarded:true });
    
       
      
      } else {
        this.setState({  onboarded:false  });
      }
    });
  };
  render() {
    const { pageNumber, numPages } = this.state;

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    return (
      <>
         {this.state.onboarded == false || this.props.companyState.newUserProgress !== 3 || this.state.onboarded == false ?   <Redirect from="/detail/viewcontract" to="/auth/newuser" /> : <div>
          <Header></Header>
          <Container className="mt--7" fluid>
            <Row>
              <div className="col">
                <Card className="shadow border-0">
                  <CardHeader className="bg-transparent pb-5">
                    <Row className="align-items-center">
                      <Button
                        onClick={() => this.props.history.goBack()}
                        className="btn-icon btn-2"
                        color="primary"
                        type="button"
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-bold-left" />
                        </span>
                      </Button>
                      <div className="col">
                        <h1 className="text-black mb-0">
                          {this.props.companyState.setCurrentContract.data.name}
                        </h1>
                      </div>
                      <div className="col">
                        <Nav className=" justify-content-end " pills>
                          <NavItem>
                            {this.state.pageNumber > 1 ? (
                              <Button
                                size="sm"
                                color="default"
                                onClick={this.goback}
                              >
                                Previous Page
                              </Button>
                            ) : null}
                          </NavItem>
                          <NavItem>
                            {this.state.pageNumber == this.state.numPages ? null :     <Button
                              size="sm"
                              color="default"
                              onClick={this.goForward}
                            >
                              Next Page
                            </Button>}
                        
                          </NavItem>
                        </Nav>
                      </div>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Row className="justify-content-center">
                      <Document
                        file={this.downloadFile()}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </Row>
                    <Row className="justify-content-center">
                      {" "}
                      <p>
                        <b>
                          Page {pageNumber} of {numPages}
                        </b>
                      </p>
                    </Row>
                    {/* <FileViewer
        fileType={'pdf'}
        filePath={ this.downloadFile()}
       // errorComponent={CustomErrorComponent}
        onError={this.onError}/>   */}
                  </CardBody>
              
                </Card>
                <CardFooter> </CardFooter>
              </div>
            </Row>
          </Container>
      </div> }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyState: state.companyState,
});
export default connect(mapStateToProps, {
  getContracts,
  setValue,
})(ViewContract);
