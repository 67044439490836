/*!

=========================================================


*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { auth, db } from "../../views/examples/Fire";
import { getUserInfo, setValue } from "../../redux/actions/CompanyActions";
import { connect } from "react-redux";
import ActionTypes from 'redux/ActionTypes';
import {
  withRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";
function redirect() {
  return (
    <Redirect from="/admin/" to="/auth/login" />)
  }
class AdminNavbar extends React.Component {

  
  logOut = (test) => {

    test.preventDefault()
    this.props.setValue(ActionTypes.NEW_USER_PROGRESS, 0);
    this.props.setValue(ActionTypes.SET_AUTH_VIEW, 0);
    auth.signOut();
    redirect();

  }

componentDidMount(){
  //this.checkAuth()
}

  // checkAuth = () => {
  //   auth.onAuthStateChanged((user) =>{
  //     if (user) {
  //       // this.props.setValue(
  //       //   ActionTypes.SET_USER_ID, auth.currentUser.uid
          
  //       // );
  //       return this.props.companyState.setUser[0].data.logo
          
  //     }
  //     else{
  //        return  "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.pixelstalk.net%2Fwp-content%2Fuploads%2F2016%2F11%2FAuto-Ferrari-Laferrari-Photos.jpg&f=1&nofb=1"
  //     }
  // });

  // }

  render() {
  
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
             
            </Link>
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    {/* <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={this.checkAuth}
                      />
                    </span> */}
                    <Media style={{color:'black'}} className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        
                      { this.props.companyState.setUser == null ? "" : this.props.companyState.setUser.data.name} 
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
            
                  <DropdownItem
                  
                    onClick={this.logOut}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyState: state.companyState,
});
export default connect(mapStateToProps, {
  getUserInfo,
  setValue,
})(AdminNavbar);
