/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { auth, db, fb } from "./Fire";
import { connect } from "react-redux";
import ActionTypes from "redux/ActionTypes";
import { setValue } from "../../redux/actions/CompanyActions";
import { getUserInfo, showError, showSuccess } from "../../redux/actions/CompanyActions";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Spinner,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Modal,
  Col,
  Container,
} from "reactstrap";
import {
  withRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      email: "",
      password: "",
      loading: false,
      onboarded: false,
      fgEmail: "",
      newUserProgress: 0
    };
  }
  componentDidMount() {
    this.authListener();
    this.props.setValue(ActionTypes.SET_ONBOARDING_NAME, "");
    this.props.setValue(ActionTypes.SET_ONBOARDING_AGENCY, "");
  }

  nextPath(path) {
    this.props.history.push(path);
  }

  authListener = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.setState({ onboarded: true });
        await this.props.getUserInfo(user.uid);
        //(user);
        this.getOnboardState(user.uid)
      } else {
        this.setState({ onboarded: false });
        //("user");
      }
    });
  };
  getOnboardState = async (uid) => {
    var testState = 0;
    let citiesRef = await db.collection("Users");

    //var uid = await this.getCurrentUser()

    let query = await citiesRef
      .where("uid", "==", uid)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const user = {
            id: doc.id,
            data: doc.data(),
          };

          testState = user.data.newUserProgress;
          // //(user.data)
        });

        //(testState);
        this.setState({ newUserProgress: testState });
      });

    return testState;
  };
  login = async (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    await auth
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(async (u) => {
       // this.props.getUserInfo(u.user.uid)
   
   
       await this.getOnboardState(u.user.uid)
        this.props.setValue(ActionTypes.SET_USER_ID, u.user.uid);
        this.props.setValue(ActionTypes.NEW_USER_PROGRESS, 3);
        this.nextPath("/admin/proposal");

        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        //(error);
        const notify = () =>
        toast.error("Invalid passoword, please try again.", {
          autoClose: 3000,
        });
    
      return notify();
        
      });
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeForgot = (e) => {
    this.setState({ fgEmail: e.target.value });
    //(this.state.fgEmail);
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };
  nextPath(path) {
    this.props.history.push(path);
  }
  toNext = () => {
    this.nextPath("/auth/register");
  };

  resetPassword = (e) => {
    e.preventDefault();

    auth
      .sendPasswordResetEmail(this.state.fgEmail)
      .then( (e) => {
      
        //("email sent");
        const notify = () =>
        toast.success("Password reset email sent!", {
          autoClose: 3000,
        });
  
      return notify();
      })
      .catch(function (error) {
        //(error);
      });
    this.toggleModal("defaultModal");
    const notify = () =>
    toast.error("Could not reset password. Please try again", {
      autoClose: 3000,
    });

  return notify();
  };

  googleSignIn = async() => {
    var provider = new fb.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    
    auth.signInWithPopup(provider).then(async(result) => {
      // This gives you a Google Access Token.
      var token = result.credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      console.log(user)



      await this.getOnboardState(result.user.uid)
      this.props.setValue(ActionTypes.SET_USER_ID, result.user.uid);
      this.props.setValue(ActionTypes.NEW_USER_PROGRESS, 3);
      this.nextPath("/admin/proposal");

      this.setState({ loading: false });

    //  this.createFirestoreUser(user)
     }).catch((er)=> {
       console.log(er)
       const notify = () =>
       toast.error("Could not sign you in. Please try again", {
         autoClose: 3000,
       });
       this.setState({ loading: false });
     return notify();
     });
    // var provider = new auth.signInWithPopup("p")

  }

  render() {
    const isValid = validateEmail(this.state.fgEmail);
    const { email, password } = this.state;
    const isEnabled = validateEmail(email) && password.length > 7;
    const progress = this.state.newUserProgress
    return (
      <>
        {/* 
      <Form>
        <FormGroup>
          <Input value={this.state.email} onChange={this.handleChange}></Input>
          <Input value={this.state.password} onChange={this.handleChange}></Input>
          <Button className="my-4" color="primary" type="button" onClick={this.login}>
                    Sign in
                  </Button>
        </FormGroup>
      </Form> */}



       
       
{progress == null ? (
        <Spinner></Spinner>
      ) : progress === 3 ? ( //0
        <Redirect from="/detail/onboarding" to="/admin/proposal" />
      ) : progress === 1 ? (
        <Redirect from="/detail/onboarding" to="/detail/onboarding" />
      ) : progress === 2 ? ( //3
        <Redirect from="/detail/onboarding" to="/detail/billing" />
      ) : progress === 0 ? ( //1
      

          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <div className="text-muted text-center mt-2 mb-3">
                  <img
                    width="70%"
                    src="https://d1pnnwteuly8z3.cloudfront.net/images/957037a9-a90f-436e-a37a-418387aac81a/bdeaea6e-09b6-4338-b995-7d53ce8aec42.png"
                  ></img>
                  <Row className="justify-content-center">
                    <small>Sign In With</small>
                  </Row>
                </div>
                <div className="btn-wrapper text-center">
                {/* <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button> */}
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={this.googleSignIn}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
                          <ToastContainer />

              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                <Row className="justify-content-center" style={{marginBottom:'16px'}}>
                    <small>Sign In With Email</small>
                  </Row>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                        name="email"
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="new-password"
                        name="password"
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  {/* <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div> */}
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={this.login}
                      disabled={!isEnabled}
                    >
                      Sign in
                    </Button>
                    {this.state.loading == true ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : null}
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
              <Col className="text-right" xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => this.toNext()}
                >
                  <small>Create new account</small>
                </a>
              </Col>
            </Row>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.defaultModal}
              toggle={() => this.toggleModal("defaultModal")}
            >
              <div className="modal-header">
                <h2 className="modal-title" id="modal-title-default">
                  Reset Your Password
                </h2>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Enter your email below and we will send you a link to reset
                  your Fruitbowl.io password.
                </p>
                <Input
                  // className="form-control-alternative"
                  id="exampleFormControlInput1"
                  placeholder="name@example.com"
                  type="email"
                  onChange={(e) => this.handleChangeForgot(e)}
                />
              </div>
              <div className="modal-footer">
                <Button
                  disabled={!isValid}
                  color="primary"
                  onClick={(e) => this.resetPassword(e)}
                  type="button"
                >
                  Reset Password
                </Button>
              </div>
            </Modal>
          </Col>
        )  : null }
      </>
    );
  }

  teset = () => {};
}
const mapStateToProps = (state) => ({
  companyState: state.companyState,
});
export default connect(mapStateToProps, {
  setValue,
  getUserInfo,
  showError,
  showSuccess
})(Login);
