const ActionTypes = {


    SET_CLIENTS : "SET_CLIENTS",
    SET_USER :"SET_USER",
    SET_INVOICE_TYPE :"SET_INVOICE_TYPE",
    SET_USER_ID :"SET_USER_ID",
    SET_INVOICE_DATE : "SET_INVOICE_DATE",
    SET_INVOICE_CREATED : "SET_INVOICE_CREATED",
    SET_INVOICE_CLIENT : "SET_INVOICE_CLIENT",
    SET_SERVICE_NAME: "SET_SERVICE_NAME",
    SET_SERVICE_QUANTITY: "SET_SERVICE_QUANTITY",
    SET_SERVICE_PRICE: "SET_SERVICE_PRICE",
    SET_INVOICE_CONTRACT: "SET_INVOICE_CONTRACT",
    SET_BILLING_SCHEDULE: "SET_BILLING_SCHEDULE",
    SET_CONTRACTS: "SET_CONTRACTS",
    SET_INVOICE_CLIENT_ID: "SET_INVOICE_CLIENT_ID",
    SET_SERVICES: "SET_SERVICES",
    SET_INVOICES: "SET_INVOICES",
    SET_CURRENT_INVOICE: "SET_CURRENT_INVOICE",
    SET_ACTIVE_VIEW: "SET_ACTIVE_VIEW",
    SET_CURRENT_CONTRACT: "SET_CURRENT_CONTRACT",
    SERVICES: "SERVICES",
    SET_AUTH_VIEW: "SET_AUTH_VIEW",
    SET_SERVICE_TAX: "SET_SERVICE_TAX",
    SET_ACTIVE_ONBOARDING: "SET_ACTIVE_ONBOARDING",
    SET_ONBOARDING_COMPLETE:"SET_ONBOARDING_COMPLETE",
    SET_ONBOARDING_NAME:"SET_ONBOARDING_NAME",
    SET_ONBOARDING_AGENCY:"SET_ONBOARDING_AGENCY",
    SET_ONBOARDING_AGENCY_LOGO:"SET_ONBOARDING_AGENCY_LOGO",
    SET_USER_EMAIL: "SET_USER_EMAIL",
    SET_USER_PWD: "SET_USER_PWD",
    SET_AGENCY_LOGO_URL: "SET_AGENCY_LOGO_URL",
    SET_HAS_INVOICES:"SET_HAS_INVOICES",
    SET_HAS_CLIENTS:"SET_HAS_CLIENTS",
    SET_HAS_CONTRACTS:"SET_HAS_CONTRACTS",
    SET_AUTH_PROGRESS:"SET_AUTH_PROGRESS",
    SET_AUTH_PROGRESS_PWD:"SET_AUTH_PROGRESS_PWD",
    SET_PROGRESS_TOTAL:"SET_PROGRESS_TOTAL",
    CHOSE_CONTRACT: "CHOSE_CONTRACT",
    SET_TOTAL_PRICE:"SET_TOTAL_PRICE",
    SET_MEMO:"SET_MEMO",
    SET_INVOICE_STATUS:"SET_INVOICE_STATUS",
    SET_INTERVAL:"SET_INTERVAL",
    SET_DUE_DATE:"SET_DUE_DATE",
    SET_USER_DOC:"SET_USER_DOC",
    NEW_USER_PROGRESS: "NEW_USER_PROGRESS",
    SET_CURRENCY: "SET_CURRENCY",
    SET_CURRENCY_ISO:"SET_CURRENCY_ISO",
    SET_ACTIVE_INVOICE:"SET_ACTIVE_INVOICE",
    SET_NEW_CLIENT:"SET_NEW_CLIENT",
    SET_ACTIVE_INVOICE_ID: "SET_ACTIVE_INVOICE_ID",
    SET_LOADING: "SET_LOADING",
    SET_GLOBAL_LOADING: "SET_GLOBAL_LOADING",
    SET_STRIPE_ID: "SET_STRIPE_ID",
}

export default ActionTypes