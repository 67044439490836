import React from 'react';
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";
import "assets/scss/argon-dashboard-react.scss";
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Button,
    Container,
    CardBody,
    Input,
    FormGroup,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    Col,
    Form,
    Label,
    CardText,
    CardSubtitle
    
} from "reactstrap";
import { format } from "date-fns";

import Text from 'react'
import { connect } from "react-redux";
import { db, auth, storage } from "./Fire";
import { getContracts, setValue } from "../../redux/actions/CompanyActions";
import ActionTypes from 'redux/ActionTypes';

class ProposalAddContract extends React.Component{

  state = {
    defaultModal: false,
    hasContracts: false,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      files: null,
      name: "",
      fileURL: "",
      uid: "",
      progress:0,
      contractList: [],
      testName: null,
    };
  }
  handleChange = (files) => {
    this.setState({
      files: files,
      testName: files[0].name,
    });
  };

componentDidMount(){

    this.props.getContracts(this.props.companyState.setUserID);

}
chooseInvoiceType = (value) =>{


    this.props.setValue(ActionTypes.SET_INVOICE_TYPE, true);
   value.preventDefault()
   this.props.setValue(ActionTypes.CHOSE_CONTRACT, true);
  


}
handleSave = () => {
  let file = this.state.files[0];

  const uploadTask = storage.ref(`Contracts/${file.name + " " + this.props.companyState.setUserID}`).put(file);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      // progrss function ....
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
        this.setState({progress:progress})
      //(progress);
    },
    (error) => {
      // error function ....
      //(error);
    },
    () => {
      // complete function ....
      storage
        .ref("Contracts")
        .child(file.name + " " + this.props.companyState.setUserID)
        .getDownloadURL()
        .then((url) => {
          //(url);
          this.uploadContract(url, file.name);
          
        });
    }
  );
};

uploadContract = async (url, name) => {


var date = Date.now()
var formattedDate = format(date, "MMM d, yyyy");

  const cityRef = db
    .collection("Contracts")
    .add ({
      date: formattedDate,
      fileURL: url ,
      name: name,
      uid: this.props.companyState.setUserID
    }).then((contract) => {

      this.props.getContracts(this.props.companyState.setUserID);

    })
    if (this.state.progress == 100) {
      this.toggleModal("defaultModal")
      this.setState({progress:0})
    }
  
 
};

contractChosen = (e) =>{

  this.props.setValue(ActionTypes.CHOSE_CONTRACT, true);

  this.props.setValue(ActionTypes.SET_INVOICE_TYPE, false);
  this.props.setValue(ActionTypes.SET_INVOICE_CONTRACT, e)
}

checkInvoiceType = () => {

  var x = "Choose A Contract"
  if (this.props.companyState.setInvoiceType === true){
    x = "No Contract"
  }
  else if (this.props.companyState.setInvoiceType === false){


     
     if (this.props.companyState.invoiceContract == null) {
        x = "Choose A Contract"
      }

      else {
        x =  this.props.companyState.invoiceContract.data.name 

      }

  }

return x
}

    renderContracList = (contract, index) => {

        const { name, id } = contract.data; 
        return(
          <DropdownItem href="#" onClick={e => this.contractChosen(contract)}>
          {name}
          </DropdownItem>
    
        )
    
      }
      handleClick(company, id){
        this.props.setValue(ActionTypes.SET_INVOICE_CLIENT, company)
        this.props.setValue(ActionTypes.SET_INVOICE_CLIENT_ID, id)
    
      }
    render() {
        const contracts = this.props.companyState.contracts  || []
        const {  files } = this.state;

        const isEnabled = files != null && files[0].name.split('.').pop() == 'pdf' ;

    

        return(
          <div>
            <Row  className="justify-content-center" >
            <div style={{marginTop:'64px', marginBottom:'64px'}}>
                
                <Row className="col-sm">
                    <Col>
                    
                    <UncontrolledDropdown group  >
          <DropdownToggle caret color="default">
            { this.checkInvoiceType()  }</DropdownToggle>
          <DropdownMenu  >
            <DropdownItem href="#pablo" onClick={()=> this.toggleModal("defaultModal")}>
             Upload New Contract
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem href="#pablo" onClick={e => this.chooseInvoiceType(e) 
}>
             Don't Attach Contract
            </DropdownItem>
            <DropdownItem divider />

            {contracts.map(this.renderContracList)}
          
          </DropdownMenu>
        </UncontrolledDropdown>
        <Modal
                      className="modal-dialog-centered"
                      isOpen={this.state.defaultModal}
                      toggle={() => this.toggleModal("defaultModal")}
                    >
                      <div className="modal-body p-0">
                        <Card className="bg-secondary shadow border-0">
                          <ModalHeader className="text-center">
                            <h2>Upload a new contract   <p>(Fruitbowl.io accepts PDF files)</p></h2>
                          </ModalHeader>
                          <CardBody className="px-lg-5 py-lg-5">
                            <Row className="justify-content-center">
                            <div className="text-center">
                              <Input
                                className="my-4"
                                color="primary"
                                type="file"
                                onChange={(e) => {
                                  this.handleChange(e.target.files);
                                }}
                              >
                                Upload Contract
                              </Input>
                            {this.state.progress > 0 ?  < Progress style={{marginTop:'48px'}} className="text-center" animated max="100"  value={this.state.progress} color="success"></Progress>: null}
                             
                              <Button
                                className="my-4"
                                color="primary"
                                type="button"
                                onClick={this.handleSave}
                                disabled={!isEnabled}
                              >
                                Save
                              </Button>
                            </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </div>
                    </Modal>
    
                    </Col>
                 
                </Row>
              
            </div>
           
           
            </Row>
{/*  
            <CardHeader className="bg-transparent pb-1"><h2>Integrations</h2></CardHeader>
            
            <Row  className="justify-content-center" >
            <div style={{marginTop:'64px', marginBottom:'64px'}}>
                
                <Row >
                    <Col>
                    
               
                    <div className="custom-control custom-control-alternative custom-checkbox mb-3">
          <input
            className="custom-control-input"
            id="customCheck5"
            type="checkbox"
          />
          <label className="custom-control-label" htmlFor="customCheck5">
            Create me a new Slack Channel for <strong>{this.props.companyState.invoiceClient.data.company}</strong>
          </label>
        </div>
        
        <div className="custom-control custom-control-alternative custom-checkbox mb-3">
          <input
            className="custom-control-input"
            id="customCheck6"
            type="checkbox"
            defaultChecked
            value="checked"
          />
          <label className="custom-control-label" htmlFor="customCheck6">
            Invite <b><i>{this.props.companyState.invoiceClient.data.name}</i></b> to the Slack Channel
          </label>
        </div>
    
                    </Col>
         
                </Row>
              
            </div>
           
       
            </Row> */}
            {/* <Col >
              <Row><label className="custom-toggle">
          <input defaultChecked type="checkbox" />
          <span className="custom-toggle-slider rounded-circle" />
        </label></Row>
              <Row> <label className="custom-toggle">
          <input defaultChecked type="checkbox" />
          <span className="custom-toggle-slider rounded-circle" />
        </label></Row>
              <Row><label className="custom-toggle">
          <input defaultChecked type="checkbox" />
          <span className="custom-toggle-slider rounded-circle" />
        </label></Row>
            </Col> */}
         
           
         <CardFooter> </CardFooter>

              </div>
        )

    }

}
const mapStateToProps = (state) => ({
    companyState: state.companyState,
  });
  
  export default connect(mapStateToProps, {
    getContracts, setValue
  })(ProposalAddContract);
  