/*!

*/
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import CreateProposal from "views/examples/createProposal.js";
import Proposals from "views/examples/proposals";
import Clients from "views/examples/Clients";
import Contracts from "views/examples/Contracts";
import ViewContract from "views/examples/ViewContract";
import ViewInvoice from "views/examples/ViewInvoice";
import PayInvoice from "views/examples/PayInvoiceScreen";
import SignContract from "views/examples/SignContract";
import Onboarding from "views/examples/Onboarding";
import OnboardingAgency from "views/examples/OnboardingAgency";
import StripeOnboarding from "views/examples/StripeOnboarding";
import InitialScreen from "views/examples/InitialScreen";
import loadinvoice from "views/examples/loadInvoice"
import Redirect from 'views/examples/Redirect'

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    invisible: true,
  },

  {
    path: "/auth/newUser",
    name: "Initial Screen",
    icon: "ni ni-key-25 text-info",
    component: InitialScreen,
    layout: "/auth",
    invisible: true,
  },

  {
    path: "/stripeOnboardingSuccess/:scope?",
    name: "Stripe",
    icon: "ni ni-key-25 text-info",
    component: StripeOnboarding,
    layout: "/detail",
    invisible: true,
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/proposal",
    name: "Invoices",
    icon: "ni ni-send text-blue",
    component: Proposals,
    layout: "/admin",
  },
  {
    path: "/Clients",
    name: "Clients",
    icon: "ni ni-briefcase-24 text-blue",
    component: Clients,
    layout: "/admin",
  },
  {
    path: "/contracts",
    name: "Contracts",
    icon: "ni ni-book-bookmark text-blue",
    component: Contracts,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "Settings",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/createProposal",
    name: "New Proposal",
    icon: "ni ni-circle-08 text-pink",
    component: CreateProposal,
    layout: "/detail",
    invisible: true,
  },

  {
    path: "/viewContract",
    name: "View Contract",
    icon: "ni ni-circle-08 text-pink",
    component: ViewContract,
    layout: "/detail",
    invisible: true,
  },
  {
    path: "/viewInvoice/:id?",
    name: "View Invoice",
    icon: "ni ni-circle-08 text-pink",
    component: ViewInvoice,
    layout: "/detail",
    invisible: true,
  },
  {
    path: "/payInvoice/:invoiceId?",
    name: "Pay Invoice",
    icon: "ni ni-circle-08 text-pink",
    component: PayInvoice,
    layout: "/detail",
    invisible:true
  },
  {
    path: "/loadinvoice/:invoiceId?",
    name: "Load Invoice",
    icon: "ni ni-circle-08 text-pink",
    component: loadinvoice,
    layout: "/detail",
    invisible:true
  },
  {
    path: "/signcontract/:invoiceId?",
    name: "Sign Contract",
    icon: "ni ni-circle-08 text-pink",
    component: SignContract,
    layout: "/detail",
    invisible:true
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    icon: "ni ni-circle-08 text-pink",
    component: Onboarding,
    invisible: true,

    layout: "/detail",
  },
  {
    path: "/billing",
    name: "Billing",
    icon: "ni ni-circle-08 text-pink",
    component: OnboardingAgency,
    invisible: true,

    layout: "/detail",
  },
  {
    path: "/redirect",
    name: "Redirect",
    icon: "ni ni-circle-08 text-pink",
    component: Redirect,
    invisible: true,

    layout: "/detail",
  },
];
export default routes;
