import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import FileViewer from "react-file-viewer";
import ActionTypes from "redux/ActionTypes";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Button,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
  NavItem,
  NavLink,
  Nav,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader,
  Modal,
  CardBody,
  Spinner,
} from "reactstrap";
// core components
import { ToastContainer, toast } from "react-toastify";
import { Document, Page, pdfjs } from "react-pdf";
import { auth, db, storage } from "./Fire";
import { getContracts, setValue } from "../../redux/actions/CompanyActions";
import { connect } from "react-redux";
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";
import SignaturePad from "react-signature-canvas";
import { format } from "date-fns";
const file =
  "https://firebasestorage.googleapis.com/v0/b/fruitbowl-c0d90.appspot.com/o/Contracts%2FDVAIncorporation-pdf.pdf?alt=media&token=59d12972-2787-4ad9-85e7-600c22661f7b";
const type = "pdf";
export default (props) => {

  const { companyState } = useSelector((state) => state);


  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [invoice, setInvoice] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [empty, setEmpty] = useState(true);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [imgfile, setfiles] = useState([null]);
  const [checked, setChecked] = useState(false);
  const [signed, setSigned] = useState(false);
  // state = {
  //   numPages: null,
  //   pageNumber: 1,
  //   trimmedDataURL: null,
  //   empty: true
  // };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goForward = () => {
    setPageNumber(pageNumber + 1);
  };
  const goback = () => {
    setPageNumber(pageNumber - 1);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("invoiceId");

    getInvoice(code);

    setTrimmedDataURL(null);
  }, []); //notice the empty array here

  // const componentDidMount(){

  //
  // }

  var sigCanvas = {};

  const getInvoice = async (invoiceId) => {
    //invoiceId = "DwjO2mK1MZ3LEi7FMy5n";

    await db
      .collection("Invoices")
      .doc(invoiceId)
      .get()
      .then((doc) => {
        const data = doc.data();
        //(doc.id, data);
        setInvoice(data);
        setId(doc.id);
        //  setState({ invoice: data, id: doc.id });
        //    //( state.invoice.data.clientName);
        dispatch(setValue(ActionTypes.SET_ACTIVE_INVOICE, data));
        checkInvoiceStatus(data, doc.id);
        // //(data.clientName);
      })
      .then((doc) => {
        //(invoice);
      })
      .catch((err) => {
        //("Error getting documents", err);
      });
  };

  const downloadFile = () => {
    //   const url =  .props.companyState.activeInvoice.contractPath;
    // `url` is the download URL for 'images/stars.jpg'
    const url = invoice.contractPath;
    //   can be downloaded directly:
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function (event) {
      var blob = xhr.response;
    };
    xhr.open("GET", url);
    xhr.send();
    //(url);
    return url;
    // Or inserted into an <img> element:
    ////(xhr.status)
  };

  function srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const handleSave = async () => {
    var blob = dataURLtoBlob(
      sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    );

    //(blob);

    // //(file);
    let bucketName = "Signatures";

    let file = blob;

    let storageRef = storage.ref(`${bucketName}/${id + invoice.clientName}`);
    let uploadTask = storageRef.put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setLoading(true);
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        //(progress);
      },
      (error) => {
        // error function ....
        setLoading(false);
        //(error);
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false))

      },
      () => {
        
        // complete function ....
        storage
          .ref("Signatures")
          .child(id + invoice.clientName)
          .getDownloadURL()
          .then((url) => {
            //(url);
            updateInvoice(url);
            //this.saveOnboarded(url);
            // this.props.setValue(ActionTypes.SET_AGENCY_LOGO_URL, url);
            //testurl = url;
            // this.updateUserInfo(url);
       //     setLoading(false);
       
          });
      }
    );
  };

  const checkInvoiceStatus = (doc, iden) => {
    //("checking invoice");

    if (doc.contractSigned === true) {
      //("Invoice paid, contract signed");
      setStatus(true);
    } else if (doc.invoicePaid === true) {
      setStatus(false);
    }
  };

  const updateInvoice = async (url) => {
    const cityRef = db.collection("Invoices").doc(id);


    var signedDate = new Date(
      new Date().getTime()
    );

    var formatted =  format(signedDate, "MMM d, yyyy")

    // Set the 'capital' field of the city
    const res = await cityRef
      .update({
        clientSignature: url,
        contractSigned: true,
        signedOn: signedDate,
        signedOnString:formatted
      })
      .then((doc) => {
        const notify = () =>
          toast.success("Contract Signed Successfully", {
            autoClose: 2000,
            
            });
            dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false))

            window.location.reload()
        notify();
        //this.toggleModal("defaultModal");
      }).catch((error) => {

        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false))

      })
      
  };

  const tempty = () => {
    var x = false;
    //(x);
    return x;
  };
  const trim = () => {
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true))
    setTrimmedDataURL(sigCanvas.getTrimmedCanvas().toDataURL("image/png"));
    //(trimmedDataURL);
    handleSave();
    //(sigCanvas.get);
  };

  const clear = () => {
    sigCanvas.clear();
    setTrimmedDataURL(null);
    setEmpty(true);
    setChecked(false)
  };

  const onError = (e) => {
    //(e, "error in file-viewer");
  };

  // let { trimmedDataURL, empty } =  .state;
  // const { pageNumber, numPages } =  .state;
  const isEnabled = (trimmedDataURL !== null && tempty === false) || companyState.globalLoading;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const handleInputChange = (event) => {


setChecked(!checked)
//(checked)
  }
  const testEnable = checked == true
  return (
    <>
      <div>
        <Header></Header>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow border-0" style={{ minWidth: "20%" }}>
                <CardHeader className="bg-transparent pb-5">
                  <Row className="align-items-center">
                    <div className="col">
                      <h1 className="text-black mb-0">Sign Contract</h1>
                    </div>
                    <div className="col">
                    {status === false ? (
                      <Nav className=" justify-content-end " pills>
                        <NavItem>
                          {pageNumber > 1 ? (
                            <Button size="sm" color="default" onClick={goback}>
                              Previous Page
                            </Button>
                          ) : null}
                        </NavItem>
                        <NavItem>
                          {pageNumber == numPages ? null : (
                            <Button
                              size="sm"
                              color="default"
                              onClick={goForward}
                            >
                              Next Page
                            </Button>
                          )}
                        </NavItem>
                      </Nav>
                    ) : null}
                    </div>
                  </Row>
                </CardHeader>

                <CardBody>
                  {status === true ? (
                    <div>
                      <Row className="justify-content-center">
                        <Col>
                          <p>
                            Thank you. This contract has been signed.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      <Col>
                        {" "}
                        <Row
                          style={{
                            marginTop: "16px",
                            marginBottom: "32px",
                          }}
                          className="justify-content-center"
                        >
                          {invoice !== null ? (
                            <Document
                              style={{ border: "2px solid red" }}
                              file={invoice.contractPath}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page pageNumber={pageNumber} />
                            </Document>
                          ) : (
                            <Spinner></Spinner>
                          )}
                        </Row>
                        <Row
                          style={{
                            marginTop: "16px",
                            marginBottom: "32px",
                          }}
                          className="justify-content-center"
                        >
                          {" "}
                        </Row>
                        <Row className="justify-content-center">
                          {" "}
                          <SignaturePad
                            ref={(ref) => {
                              sigCanvas = ref;
                            }}
                            penColor="black"
                            backgroundColor="#F1F1F1"
                            canvasProps={{
                              width: 300,
                              height: 100,
                              className: "sigCanvas",
                            }}
                          />
                        </Row>
                        <Row
                          style={{ marginTop: "32px" }}
                          className="justify-content-center"
                        >
                          <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="customCheck5"
                              type="checkbox"
                              checked={checked}
                              onChange={handleInputChange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck5"
                            >
                              <strong>
                                {" "}
                                I have read & agree to the terms stated in this
                                contract{" "}
                              </strong>
                            </label>
                          </div>
                        </Row>
                        <Row
                          className="justify-content-center"
                          style={{ marginBottom: "16px", marginTop: "16px" }}
                        >
                          {" "}
                          <Button
                            className="btn-icon btn-2"
                            onClick={clear}
                            type="button"
                          >
                            <span className="btn-inner--icon">
                              <i className="ni ni-fat-remove" />
                            </span>
                          </Button>
                          <Button
                            className="btn-icon btn-2"
                            color="primary"
                            type="button"
                            disabled={!testEnable}
                            onClick={trim}
                          >
                            <span className="btn-inner--icon">
                              <i className="ni ni-check-bold" />
                            </span>
                            <span className="btn-inner--text">
                              Save Signature
                            </span>
                          </Button>
                          {companyState.globalLoading ? <Spinner></Spinner> : null}
                        </Row>
                        {/* <Row
                        className="justify-content-center"
                        style={{ marginBottom: "16px" }}
                      >
                        {" "}
                        {isEnabled ? (
                          <Button
                            disabled={!isEnabled}
                            color="primary"
                            onClick={(e) => e.preventDefault()}
                          >
                            Sign & Confirm Contract
                          </Button>
                        ) : null}
                      </Row> */}
                        <Row className="justify-content-center">
                          <p>You will be emailed a copy of contract to keep.</p>
                        </Row>
                      </Col>
                    </div>
                  )}
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>

      {/* {trimmedDataURL ? <img src={trimmedDataURL} /> : null} */}
    </>
  );
};
