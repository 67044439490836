/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { auth, db } from "./Fire";
import { connect } from "react-redux";
import ActionTypes from "redux/ActionTypes";
import { setValue } from "../../redux/actions/CompanyActions";
import Login from "./Login";
import Register from "./Register";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Spinner,
  Progress,
  Modal,
  Row,
  Col,
  Container,
  CardFooter,
} from "reactstrap";
import {
  withRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";
import OnboardingAgency from "./OnboardingAgency";
import Onboarding from "./Onboarding";

function Activeview(props) {
  if (props.value === 0) {
    return <Register />;
  }
  if (props.value === 1) {
    return <Login />;
  }
  if (props.value === 2) {
    return <Onboarding />;
  }
  if (props.value === 3) {
    return <OnboardingAgency />;
  }
}


function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}



function ActiveText(props) {
    if (props.value === 0) {
        return (
            "Have an Account?"
            )
      }
      if (props.value === 1) {
        return (
            "Don't have a Fruitbowl Account?"
            )

      }
      else {
        return null
      }
}


function ActiveBtn(props) {
    if (props.value === 0) {
        return (
            "Log In Here"
            )
      }
      if (props.value === 1) {
        return (
            "Create an Account"
            )
      }
      else{
        return null
      }
}

class InitialScreen extends React.Component {
  state = {
    value: 0,
    defaultModal: false,
    email: "",
    onboarded:false
  };

  
 
  loginView = () => {

    if (this.state.value == 0){

        this.props.setValue(ActionTypes.SET_AUTH_VIEW, 1);
        this.setState({value:1})
    }
    else if (this.state.value == 1){
      this.props.setValue(ActionTypes.SET_PROGRESS_TOTAL, 10);

    this.props.setValue(ActionTypes.SET_AUTH_VIEW, 0);
    this.setState({value:0})
    }
    else if (this.state.value == 2){

      this.props.setValue(ActionTypes.SET_AUTH_VIEW, 1);
      this.setState({value:1})
      }
  };

  constructor(props) {
    super(props);
  }

 componentDidMount(){




  this.props.setValue(ActionTypes.SET_AUTH_VIEW, 0);

  this.props.setValue(ActionTypes.SET_PROGRESS_TOTAL, 10);

 }


calculateTotal = () => {
  var total = this.props.companyState.progressTotal
  
return total
}

handleChange = (e) => {

this.setState({email: e.target.value})
//(this.state.email)
}

toggleModal = state => {
  this.setState({
    [state]: !this.state[state]
  });
};

resetPassword = (e) => {
e.preventDefault()

auth.sendPasswordResetEmail(this.state.email).then(function() {

//("email sent")


}).catch(function(error) {
 //(error)
});
this.toggleModal("defaultModal")
}

  render() {

    const isValid = validateEmail(this.state.email)

    return (
      <>

       
        <Container className="mt--7" fluid>
        <Row className="justify-content-center" style={{ marginTop: "20%" }}>
            <Col lg="5" md="7">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3" >
                    <img
                      width="300px"
                      src="https://d1pnnwteuly8z3.cloudfront.net/images/957037a9-a90f-436e-a37a-418387aac81a/bdeaea6e-09b6-4338-b995-7d53ce8aec42.png"
                    ></img>
                    
                          {this.props.companyState.setAuthView == 0 || this.props.companyState.setAuthView == 2 || this.props.companyState.setAuthView == 3 ?  < Progress style={{marginTop:'48px'}} className="text-center" animated max="100"  value={this.calculateTotal()} color="success"></Progress>
                          : null}
                             

                  </div>

                  {/* <div className="text-muted text-center mt-2 mb-3">
                    <small>Sign in with</small>
                  </div>
                  <div className="btn-wrapper text-center">
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="btn-inner--icon">
                        <img
                          alt="..."
                          src={require("assets/img/icons/common/google.svg")}
                        />
                      </span>
                      <span className="btn-inner--text">Google</span>
                    </Button>
                  </div> */}
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Activeview value={this.props.companyState.setAuthView} />
                  <div style={{ marginTop: "16px" }} className="text-center">
                    <p>
                {<ActiveText value={this.props.companyState.setAuthView} />}{" "}
                { this.props.companyState.setAuthView < 2 ?
                      <Button
                        aria-pressed={true}
                        className="active"
                        color="secondary"
                      
                        onClick={(e) => this.loginView(e)}
                        role="button"
                        size="sm"
                      >
                         {<ActiveBtn value={this.props.companyState.setAuthView} />}{" "}

                </Button> :  <Button
                        aria-pressed={true}
                        className="active"
                        color="secondary"
                        
                        onClick={(e) => this.loginView(e)}
                        role="button"
                        size="sm"
                      >
                         {<ActiveBtn value={this.props.companyState.setAuthView} />}{" "}

                </Button>}
                    </p>
                  </div>
                </CardBody>

           
              </Card>
{this.props.companyState.setAuthView == 1 ?    <Col>
         <Row className="justify-content-left">

<Button style={{marginTop:'16px'}} color="link" onClick={() => this.toggleModal("defaultModal")}>Forgot Password?</Button>
</Row>
</Col>  : null}
      
            </Col>

            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.defaultModal}
              toggle={() => this.toggleModal("defaultModal")}
            >
              <div className="modal-header">
                <h2 className="modal-title" id="modal-title-default">
                  Reset Your Password
                </h2>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                 Enter your email below and we will send you a link to reset your Fruitbowl.io password.
                </p>
                <Input
                 // className="form-control-alternative"
                  id="exampleFormControlInput1"
                  placeholder="name@example.com"
                  type="email"
                  onChange={(e) => this.handleChange(e)}
                />
              
              </div>
              <div className="modal-footer">
              
              <Button disabled={!isValid} color="primary" onClick={(e) => this.resetPassword(e)} type="button">
                  Reset Password
                </Button>
              </div>
            </Modal>

          </Row>
        </Container>
        
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  companyState: state.companyState,
});
export default connect(mapStateToProps, {
  setValue,
})(InitialScreen);
