/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  getClients,
  createStripeCharge,
  createStripeSubscription,
  attatchPaymentMethod,
  createPaymentIntent,
  setValue,
  myCompanyState
} from "../../redux/actions/CompanyActions";
import ActionTypes from "redux/ActionTypes";
import { connect } from "react-redux";
// reactstrap components
import { auth, db } from "./Fire";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Spinner,
  Container,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Row,
  Col,
  Nav,
  NavItem,
  Table,
  CardFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";

import { loadStripe } from "@stripe/stripe-js";
//import AppStitch from "@appstitch/js";
import { createCharge } from "@appstitch/stripe";

// import {loadStripe, } from "@stripe/stripe-js"






export default (props) => {



  const { companyState } = useSelector((state) => state);

  const dispatch = useDispatch();
 
  

  const [loading, setLoading] = useState(false);

  const [invoice, setInvoice] = useState(null);
  const [id, setId] = useState("");
  const [status, setStatus] = useState(false);


  
  const getInvoice = async (invoiceId) => {
    //invoiceId = "DwjO2mK1MZ3LEi7FMy5n";

    await db
      .collection("Invoices")
      .doc(invoiceId)
      .get()
      .then((doc) => {
        const data = doc.data();
        //(doc.id, data);
        setInvoice(data);
        setId(doc.id);
        //  setState({ invoice: data, id: doc.id });
        //    //( state.invoice.data.clientName);
        dispatch(setValue(ActionTypes.SET_ACTIVE_INVOICE, data));
        dispatch(setValue(ActionTypes.SET_ACTIVE_INVOICE_ID, doc.id));
        //(data.clientStripeId);
        checkInvoiceStatus(data, doc.id);
        
        nextPath(`/detail/payInvoice/?invoiceId=${doc.id}`)
      
        

      })
      .catch((err) => {
        //("Error getting documents", err);
      });
  };


  
  const nextPath = (path) => {
    props.history.push(path);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("invoiceId");

    // dispatch(onboardStripeStandardAccount(code))

    getInvoice(code);
  }, []);

  

  const checkInvoiceStatus = (doc, iden) => {
    //("checking invoice");
    if (doc.invoiceType === false) {
      if (doc.status === "PAID" && doc.contractSigned === false) {
        //("Invoice paid, need to sign contract");
        //nextPath(`/detail/signcontract/?invoiceId=${iden}`);
      }
       else if (doc.status === "PAID") {
          //("invoice has been paid");
          setStatus(true);
        }
      
    } else if (doc.invoiceType === true) {
      if (doc.status === "SENT") {
        //("Invoice needs to be paid, no to sign contract");
        setStatus(false);
      }  if (doc.status === "PAID") {
        //("invoice has been paid");
        setStatus(true);
      }
    }
  };

  
  


  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {invoice !== null ? (
          <Row>
            <div className="col">
              <Row className="justify-content-center">
                <Col md="6">
                  <Card className="shadow border-0">
                    {/* <CardHeader className="bg-transparent pb-1">
                      <h2>Pay Invoice #{id}</h2>
                      <div className="justify-content-center">
                        <Row
                          style={{ marginTop: "32px" }}
                          className="justify-content-center"
                        >
                          <img src={invoice.logo} width="48%" />
                        </Row>
                        <Row className="justify-content-center">
                          <small
                            className="text-muted"
                            style={{ fontSize: "15px", marginTop: "16px" }}
                          >
                            <b>Invoice from {invoice.company}</b>{" "}
                          </small>
                        </Row>
                        <Row
                          style={{ marginBottom: "32px" }}
                          className="justify-content-center"
                        >
                          <small
                            className="text-muted"
                            style={{ fontSize: "15px", marginTop: "8px" }}
                          >
                            Billed to {invoice.client} ({invoice.clientName})
                          </small>
                        </Row>
                      </div>
                    </CardHeader> */}
                    <CardBody>
              
                    </CardBody>
                  </Card>

                  <Row
                    style={{ marginTop: "16px" }}
                    className="justify-content-center"
                  >
                    <p style={{ fontWeight: "100", fontSize: "13px" }}>
                      {invoice.company} partners with Fruitbowl.io 🍉 to deliver
                      this invoice to you
                    </p>
                  </Row>
                </Col>
              </Row>
            </div>
          </Row>
        ) : (
          <Col>
            <Row className="justify-content-center">
              <Spinner>Loading</Spinner>
            </Row>
          </Col>
        )}
      </Container>
    </>
  )
        };
