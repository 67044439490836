import React from "react";
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";
import "assets/scss/argon-dashboard-react.scss";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  InputGroupAddon,
  InputGroupText,
  Label,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Button,
  Container,
  CardBody,
  Input,
  FormGroup,
  Row,
  UncontrolledTooltip,
  Col,
  ModalHeader,
  Form,
  CardText,
  Modal,
  Spinner,
} from "reactstrap";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Text from "react";
import { getClients, setValue, createStripeCustomer } from "../../redux/actions/CompanyActions";
import ReactDatetime from "react-datetime";
import ActionTypes from "redux/ActionTypes";
import { auth, db } from "./Fire";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
function refreshPage() {
  window.location.reload(false);
}

class ProposalAddClient extends React.Component {
  constructor(props) {
    super(props);
    //this.logOut = this.logOut.bind(this);

    this.addClient = this.addClient.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      name: "",
      email: "",
      company: "",
      uid: "",
      clientList: [],
      loading: false
    };
  }
  componentDidMount() {
    this.props.getClients(this.props.companyState.setUserID);
   
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  addClient = async(e) => {
    this.setState({loading: true})
    const customer = {
      name: this.state.name,
      email:this.state.email,
    
    }
  
//save cust id to client document


  await  db.collection("Clients").add({
      name: this.state.name,
      email: this.state.email,
      company: this.state.company,
      uid: auth.currentUser.uid,
    }).then(async(client) => {
      await this.props.createStripeCustomer(customer, client.id, false)
      var id = client.id
      const savedClient = await this.props.getClients(this.props.companyState.setUserID)
      var newClient;
  

      for (var i=0; i< this.props.companyState.clients.length; i++){
        if (this.props.companyState.clients.id === id){
          newClient = this.props.companyState.clients[i];
        }
      }


      
      //(newClient)
     this.props.setValue(ActionTypes.SET_INVOICE_CLIENT, newClient)
     this.setState({loading: false})
     this.toggleModal("defaultModal")

    }).catch((error)=> {
      
      this.toggleModal("defaultModal")
    })
  //  window.location.reload(false);
 

  
  }

getList = async() => {

  const savedClient = await this.props.getClients(this.props.companyState.setUserID)


}

dummyClient = {

  data: {
  company: "Rosie's Beauty LTD",
  email: "rosie.m@rosiesbeauty.com",
  name: "Rosie M"
  },
  id: "DUMMYCLIENT001"
}

  renderClientList = (client, index) => {
    const { name, company } = client.data;
    return (

       this.props.companyState.globalLoading === true ? <Spinner></Spinner> :

      <DropdownItem
        href="#"
        onClick={(e) =>
          this.props.setValue(ActionTypes.SET_INVOICE_CLIENT, client)
        }
      >
        {company} ({name})
      </DropdownItem>
    );
  };

  handleClick(company, id) {
    this.props.setValue(ActionTypes.SET_INVOICE_CLIENT, company);
    this.props.setValue(ActionTypes.SET_INVOICE_CLIENT_ID, id);
  }

  state = {
    defaultModal: false,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  render() {
    const clients = this.props.companyState.clients || [];
    const {  email, company, name } = this.state;
    const isEnabled =
    validateEmail(email) && company.length > 2 && name.length > 2;
    return (

      <div>

      <Row className="justify-content-center">
        <FormGroup>
        <Modal
                      className="modal-dialog-centered"
                      isOpen={this.state.defaultModal}
                      toggle={() => this.toggleModal("defaultModal")}
                    >
                      <div className="modal-body p-0">
                       
                        <Card className="bg-secondary shadow border-0">
                        <ModalHeader>
                          <Col>
                        <Row className="justify-contnet-center">
                          <div className="text-center">
                              <h2>New Client</h2></div>
                            </Row></Col>
                        </ModalHeader>
                          <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                              <FormGroup className="mb-3">
                                <InputGroup className="input-group">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-building" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input style={{color:'#3d3d3d'}}
                                    placeholder="Client's Business Name (e.g ABC Dentistry)"
                                    type="text"
                                    id="tooltip860969338"
                                    color="#000"
                                    value={this.state.company}
                                    name="company"
                                    onChange={this.handleChange}
                                  />
                                </InputGroup>
                              </FormGroup>
                              <FormGroup>
                                <InputGroup className="input-group">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-circle-08" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                  style={{color:'#3d3d3d'}}
                                    placeholder="Client's Name"
                                    type="text"
                                    id="tooltip297043631"
                                    value={this.state.name}
                                    name="name"
                                    onChange={this.handleChange}
                                  />
                                </InputGroup>
                              

                            
                              </FormGroup>
                              <FormGroup>
                                <InputGroup className="input-group">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-email-83" />
                                    </InputGroupText>
                                  </InputGroupAddon>

                                  <Input
                                  style={{color:'#3d3d3d'}}
                                    placeholder="Email"
                                    id="tooltip297043631"
                                    type="email"
                                    value={this.state.email}
                                    name="email"
                                    onChange={this.handleChange}
                                  />
                                </InputGroup>
                              </FormGroup>
                              <div className="text-center">
                                <Button
                                  className="my-4"
                                  color="primary"
                                  type="button"
                                  onClick={this.addClient}
                                  disabled={!isEnabled}
                                >
                                  Add Client
                                </Button> 
                                {this.state.loading ? <Spinner></Spinner> : null}
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                      </div>
                    </Modal>
                    <Form>
                   
          {/* <InputGroup
            className="input-group-alternative"
            style={{ width: "400px", marginBottom: "16px", marginTop: "64px" }}
          >
          
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
            </InputGroupAddon>
            <ReactDatetime
              defaultValue={Date.now()}
              value={this.props.companyState.invoiceDate }
              onChange={(e) =>
                this.props.setValue(ActionTypes.SET_INVOICE_DATE, e)
              }
              inputProps={{
                placeholder: "Invoice Start Date",
              }}
              timeFormat={false}
            />
          </InputGroup> */}
           <ToastContainer />
          <UncontrolledDropdown
            group
            style={{ marginBottom: "16px", marginTop:'64px' }}
          >
            <DropdownToggle caret color="default" className="btn-block" onClick={this.getList}>
              {this.props.companyState.invoiceClient
                ? this.props.companyState.invoiceClient.data.company
                : "Choose a Client"}
            </DropdownToggle>
            <DropdownMenu style={{ width: "400px" }}>
              <DropdownItem
                href="#pablo"
                onClick={() => {
                  
                  if (this.props.companyState.setUser.data.onboardingComplete) { 
                  
                  this.toggleModal("defaultModal") }

                  else {
                
                  var notify = () =>
                  toast.warning("Connect your Stripe to add new clients. You can do this in Settings", {
                    autoClose: 5000,
                  });
                notify();
                
                }
              }
            }
              >
                Add new client
              </DropdownItem>
              <DropdownItem divider />
              {this.props.companyState.setUser.data.onboardingComplete ? clients.map(this.renderClientList) : 
              
              <DropdownItem
        href="#"
        onClick={(e) =>
          this.props.setValue(ActionTypes.SET_INVOICE_CLIENT, this.dummyClient)
        }
      >
        {this.dummyClient.data.company} ({this.dummyClient.data.name})
      </DropdownItem>
              }
              
            </DropdownMenu>
          </UncontrolledDropdown>
          </Form>
        </FormGroup>
      </Row>


      <CardFooter> </CardFooter>
      
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyState: state.companyState,
});

export default connect(mapStateToProps, {
  getClients,
  setValue, createStripeCustomer
})(ProposalAddClient);
