/*!



*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import App from "./App"
import "assets/plugins/nucleo/css/nucleo.css";
//import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import Detail from "layouts/Detail.js"
import { createStore } from 'redux';
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from 'react-redux';
import rootReducer from './redux/reducers/rootReducer'
import {persistor, store } from "./redux/store/"



ReactDOM.render(

    <Provider store={store}>
      <PersistGate onBeforeLift={() => {}} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
,document.getElementById("root")
);
