import React, { useEffect } from "react";
import {
  getClients,
  onboardStripeStandardAccount,
  retrieveStripeAccount,
  createStripeSubscription,
  loading,
} from "../../redux/actions/CompanyActions";
import { connect, useDispatch, useSelector } from "react-redux";
import stripeLogo from "../../views/blue-on-dark@3x.png"; // with import
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Button,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
  NavItem,
  NavLink,
  Nav,
  Modal,
  CardBody,
  FormGroup,
  Form,
  Spinner,
  Input,
  InputGroup,
  Alert,
  InputGroupAddon,
  InputGroupText,
  Label,
  UncontrolledAlert,
  ModalHeader,
} from "reactstrap";

import {
  withRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";

import Header from "components/Headers/Header";
import { useHistory, useParams, useLocation } from "react-router-dom";

import { auth, db } from "./Fire";

export default (props) => {
  const { companyState } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    async function onboard() {
      await dispatch(onboardStripeStandardAccount(code));
      //(code)
      // nextPath("/admin/proposal");
    }

    onboard();
  }, []);

  const redirect = () => {
    nextPath("/admin/proposal");
  };

  const nextPath = (path) => {
    props.history.push(path);
  };
  const onboardStripe = () => {
    var xhr = new XMLHttpRequest();

    // get a callback when the server responds
    xhr.addEventListener("load", () => {
      // update the state of the component with the result here
      //(xhr.responseText)
    });
    // open the request with the verb and the url
    xhr.open(
      "GET",
      "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_HZINUW2GOvZhPNQEPG3qkihGR9HlU6Bl&scope=read_write"
    );
    // send the request
    xhr.send();
  };

  return (
    <>
      <div>
        <Header></Header>

        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="text-black mb-0">Stripe Onboarding</h2>
                    </div>
                    <div className="col">
                      <Nav className=" justify-content-end " pills>
                        {/* <Button onClick={this.logOut}>Log Out</Button> */}
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                {companyState.globalLoading === true ? <Spinner></Spinner> :
                <CardBody>
                  {companyState.stripeComplete == true ? 
    <Col>
    <Row className="justify-content-center">
          
      <h3>Congratulations! You have connected Stripe successfuly. Time to create your first invoice!</h3>

     
    </Row>
    <Row  className="justify-content-center"> <Button onClick={redirect} color="primary">My Invoices</Button></Row>
    </Col>
    : 
    
                  <Col>
                    <Row className="justify-content-start">
                      <p>
                        We were unable to connect your Stripe Account to
                        Fruitbowl.io. </p><br></br>
                        
            <p>
          
            This so that you can access all of your invoices, payments & clients within your Stripe Dashboard, even if you decide not to stay on after the trial period ends 😅

      
        </p>
                        for you.
                      
                    
                      <br></br>
                     
                    </Row>

                    <Row className="justify-content-start">
                      <h3>
                    Please try again below
                      </h3>
                      </Row>

                    <Row className="justify-content-start">
                    <a
                        href="https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_HZINUW2GOvZhPNQEPG3qkihGR9HlU6Bl&scope=read_write
"
                      >
                        {" "}
                        <img width="40%" src={stripeLogo}></img>
                      </a>
                    </Row>
                  </Col>
                  }
                </CardBody>
                }
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};
