/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  withRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";
// reactstrap components
import stripeLogo from "../../views/blue-on-dark@3x.png"; // with import
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
  Label,
} from "reactstrap";
import { db, auth, storage } from "./Fire";
// core components

import ActionTypes from "redux/ActionTypes";
import Header from "components/Headers/Header";
import { getUserInfo, setValue } from "../../redux/actions/CompanyActions";
import { connect } from "react-redux";
class Profile extends React.Component {
  authListener = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.setState({ onboarded: true });
      } else {
        this.setState({ onboarded: false });
      }
    });
  };
  componentDidMount() {
    this.authListener();
    this.setState({ image: this.props.companyState.setUser.data.logo });
  }

  state = {
    onboarded: true,
    image: null,
  };
  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({
        image: URL.createObjectURL(img),
      });
      this.props.setValue(ActionTypes.SET_ONBOARDING_AGENCY_LOGO, img);
    }
  };
  logoSave = async (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    var testurl = "";

    let file = this.props.companyState.onboardingAgencyLogo;

    const uploadTask = storage
      .ref(`images/${file.name + " " + this.props.companyState.setUser.id}`)
      .put(file);

    await uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        //(progress);
      },
      (error) => {
        // error function ....
        //(error);
      },
      () => {
        // complete function ....
        storage
          .ref("images")
          .child(file.name + " " + this.props.companyState.setUser.id)
          .getDownloadURL()
          .then((url) => {
            //(url);
            //this.saveOnboarded(url);
            //  this.props.setValue(ActionTypes.SET_AGENCY_LOGO_URL, url);
            testurl = url;
            this.updateUserInfo(url);
          });
      }
    );
  };

  updateUserInfo = async (url) => {
    //('updating')
    let citiesRef = await db
      .collection("Users")
      .doc(this.props.companyState.setUser.id);

    const res = await citiesRef
      .update({
        logo: url,
        customLogo: true,
      })
      .then((prom) => {
        this.setState({ loading: false });
      });

    //this.props.setValue(ActionTypes.SET_PROGRESS_TOTAL, 40);
    //this.props.setValue(ActionTypes.NEW_USER_PROGRESS, 2);
    //this.nextPath("/detail/billing");
  };

  render() {
    return (
      <>
        {this.props.companyState.newUserProgress !== 3 &&
        this.state.onboarded == false ? (
          <Redirect from="/admin/user-profile" to="/auth/login" />
        ) : (
          <div>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
              <Row>
                <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                  {/* <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={require("assets/img/theme/team-4-800x800.jpg")}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    <Button
                      className="mr-4"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="sm"
                    >
                      Connect
                    </Button>
                    <Button
                      className="float-right"
                      color="default"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="sm"
                    >
                      Message
                    </Button>
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        <div>
                          <span className="heading">22</span>
                          <span className="description">Friends</span>
                        </div>
                        <div>
                          <span className="heading">10</span>
                          <span className="description">Photos</span>
                        </div>
                        <div>
                          <span className="heading">89</span>
                          <span className="description">Comments</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                      Jessica Jones
                      <span className="font-weight-light">, 27</span>
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Bucharest, Romania
                    </div>
                    <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Solution Manager - Creative Tim Officer
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      University of Computer Science
                    </div>
                    <hr className="my-4" />
                    <p>
                      Ryan — the name taken by Melbourne-raised, Brooklyn-based
                      Nick Murphy — writes, performs and records all of his own
                      music.
                    </p>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      Show more
                    </a>
                  </div>
                </CardBody>
              </Card> */}
                </Col>
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Settings</h3>
                        </Col>
                        {/* <Col className="text-right" xs="4">
                          {this.state.image !==
                          this.props.companyState.onboardingAgencyLogo ? (
                            // <Button
                            //   color="primary"
                            //   href="#pablo"
                            //   onClick={(e) => this.logoSave(e)}
                            //   size="sm"
                            //   disabled={this.state.loading}
                            // >
                            //   Save
                            // </Button>
                          ) : null}
                          {this.state.loading ? <Spinner></Spinner> : null} */}
                        
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        {this.props.companyState.setUser.data
                          .onboardingComplete ? null : (
                          <div>
                            <h6 className="heading-small text-muted mb-4">
                              Payment Proccessor Settings
                            </h6>

                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <a href="https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_HZINUW2GOvZhPNQEPG3qkihGR9HlU6Bl&scope=read_write">
                                    {" "}
                                    <img width="200px" src={stripeLogo}></img>
                                  </a>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        )}
                        <h6 className="heading-small text-muted mb-4">
                          My Information
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-name"
                                >
                                  Name
                                </label>
                                <Input
                                  className="form-control"
                                  id="input-name"
                                  value={
                                    this.props.companyState.setUser.data.name
                                  }
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Email Address
                                </label>
                                <Input
                                  className="form-control"
                                  disabled
                                  value={
                                    this.props.companyState.setUser.data.email
                                  }
                                  id="input-email"
                                  type="email"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-agency-name"
                                >
                                  Business Name
                                </label>
                                <Input
                                  className="form-control"
                                  id="input-agency-name"
                                  disabled
                                  value={
                                    this.props.companyState.setUser.data.company
                                  }
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <hr className="my-4" />
                        {/* Address */}
                        <h6 className="heading-small text-muted mb-4">
                          Business Logo
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col md="12">
                              <FormGroup>
                  {this.props.companyState.setUser.data.customLogo ?

                                <img
                                  style={{ width: "35%" }}
                                  src={this.state.image}
                  /> : <p>Uploading a your business logo will make your invoices stand out! You can add one below 👇</p> }
                        
                                <Row className="justify-content-center">
                     
                                <input
                                  style={{ width: "100%" }}
                                  type="file"
                                  name="myImage"
                                  id="tooltip1596544371"
                                  onChange={this.onImageChange}
                                />
                                </Row>
                                <Row style={{marginTop:"24px"}}>
                                <Button
                              color="primary"
                              href="#pablo"
                              onClick={(e) => this.logoSave(e)}
                              size="sm"
                              disabled={this.state.loading}
                            >
                              Save
                            </Button>
                            {this.state.loading ? <Spinner></Spinner> : null}
                                </Row> 
                              </FormGroup>
                            </Col>
                          </Row>
                          {/* <Row>
                        <Col lg="4">
                         <Button>Change Logo</Button>
                        </Col>
                     
                      </Row> */}
                        </div>
                        <hr className="my-4" />
                        {/* Description */}
                        <h6 className="heading-small text-muted mb-4">
                          Support
                        </h6>
                        <div className="pl-lg-4">
                          <FormGroup>
                            <p>
                              Our support team is available 24/7 to assist you. Reach them here:{" "}
                              <strong>support@fruitbowl.io</strong>
                            </p>
                          </FormGroup>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyState: state.companyState,
});
export default connect(mapStateToProps, {
  getUserInfo,
  setValue,
})(Profile);
