/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  getClients,
  createStripeCharge,
  createStripeSubscription,
  attatchPaymentMethod,
  createPaymentIntent,
  setValue,
} from "../../redux/actions/CompanyActions";
import ActionTypes from "redux/ActionTypes";
import { connect } from "react-redux";
// reactstrap components
import { auth, db } from "./Fire";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Spinner,
  Container,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Row,
  Col,
  Nav,
  NavItem,
  Table,
  CardFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";

import { loadStripe } from "@stripe/stripe-js";
//import AppStitch from "@appstitch/js";
import { createCharge } from "@appstitch/stripe";


// import {loadStripe, } from "@stripe/stripe-js"





var sA;

export default (props) => {




  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [invoice, setInvoice] = useState(null);
  const [id, setId] = useState("");
  const [status, setStatus] = useState(false);

  // const stripe = useStripe()

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const { companyState } = useSelector((state) => state);

    const handleSubmit = async (event) => {
    //  setLoading(true)

      
      // Block native form submission.
      dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true))

      dispatch(setValue(ActionTypes.SET_LOADING, true))

      event.preventDefault();
   
    
      

      const cardElement = elements.getElement(CardElement);

      // Use your card Element with other Stripe.js APIs
      //setLoading(true)
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        //("[error]", error);
        dispatch(setValue(ActionTypes.SET_LOADING, false))
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false))

        setLoading(false)

      } else {
        //("[PaymentMethod]", paymentMethod);
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false))
        attatchPaymentSource(paymentMethod);
        dispatch(setValue(ActionTypes.SET_LOADING, false))



        // payInvoice(paymentMethod);
      }
      //setLoading(false)
    };

    return (
      <div style={{ width: "100%", marginTop: "32px" }}>
        {" "}
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <CardElement width="100%" style={{ marginBottom: "32px" }} />

            <Button
              color="primary"
              block
              size="sm"
              style={{ marginTop: "16px", marginBottom: "32px" }}
              type="submit"
              disabled={companyState.globalLoading}
              >
                { companyState.globalLoading ? "Proccessing..." : "Pay Invoice"}
            </Button>
            <Row className="justify-content-center">
           {companyState.globalLoading ? <Spinner color="primary"></Spinner> : null} 
           </Row>
          </FormGroup>
        </Form>
      </div>
    );
  };

  const getInvoice = async (invoiceId) => {
    //invoiceId = "DwjO2mK1MZ3LEi7FMy5n";

    await db
      .collection("Invoices")
      .doc(invoiceId)
      .get()
      .then((doc) => {
        const data = doc.data();
        //(doc.id, data);
        setInvoice(data);
        setId(doc.id);
        //  setState({ invoice: data, id: doc.id });
        //    //( state.invoice.data.clientName);
        dispatch(setValue(ActionTypes.SET_ACTIVE_INVOICE, data));
        dispatch(setValue(ActionTypes.SET_ACTIVE_INVOICE_ID, doc.id));
        //(data.clientStripeId);
        checkInvoiceStatus(data, doc.id);
        sA = data.stripeAccountId
        //(sA)
      })
      .then((doc) => {
        //(invoice);
      })
      .catch((err) => {
        //("Error getting documents", err);
      });
  };

  const renderServices = (service, index) => {
    // const { name, price, qty } =  props.service

    return (
      <tr key={index}>
        <td>{service.name}</td>

        <td>{service.qty}</td>
    <td>{invoice.currency.symbol}{service.price}</td>
      </tr>
    );
  };
  const nextPath = (path) => {
    props.history.push(path);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("invoiceId");

    // dispatch(onboardStripeStandardAccount(code))
    //(code);
   // sA = code
    getInvoice(code);
    dispatch(setValue(ActionTypes.SET_LOADING, false))
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false))

  }, []);

  const attatchPaymentSource = async (paymentMethod) => {

    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true))

    if (invoice.billingSchedule === "Once Off"){

      payInvoice(paymentMethod.id)
    }
    else {

    

    var attatchObject = {
      paymentMethod: paymentMethod.id,
    };

    //set state here

    const subscription = {
      customerId: invoice.stripeCustomerId,
      price: invoice.priceId,
      stripeAccount: invoice.stripeAccountId,
      paymentMethod: paymentMethod.id,
      document: id,
      type: false,
    };

    await dispatch(
      attatchPaymentMethod(
        attatchObject.paymentMethod,
        invoice.stripeCustomerId,
        invoice.stripeAccountId,
        false
      )
    );
      }
    ///  await payInvoice(paymentMethod.id);
  };

  const payInvoice = async (paymentMethodId) => {
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true))
      //payment intent
      //("Once off payment");

      const paymentIntent = {
        amount: invoice.total * 100,
        currency: invoice.currencyIso,
        customer: invoice.stripeCustomerId,
        stripeAccount: invoice.stripeAccountId,
        confirm: true,
        paymentMethod: paymentMethodId,
        invoice: id,
      };
      dispatch(createPaymentIntent(paymentIntent));
      //await AppStitch.attatchPaymentMethod()
      //  await AppStitch.createPaymentIntent()

      if (invoice.invoiceType === false) {
        //("sign contract next");
      //  nextPath(`/detail/signcontract/?invoiceId=${id}`);
      }
    
  };

  const signcontract = () => {


   nextPath(`/detail/signcontract/?invoiceId=${id}`);
  }

  const checkInvoiceStatus = (doc, iden) => {
    //("checking invoice");
    if (doc.invoiceType === false) {
      if (doc.status === "PAID" && doc.contractSigned === false) {
        //("Invoice paid, need to sign contract");
        //nextPath(`/detail/signcontract/?invoiceId=${iden}`);
        setStatus(true);
      }
       else if (doc.status === "PAID") {
          //("invoice has been paid");
          setStatus(true);
        }
      
    } else if (doc.invoiceType === true) {
      if (doc.status === "SENT") {
        //("Invoice needs to be paid, no to sign contract");
        setStatus(false);
      }  if (doc.status === "PAID") {
        //("invoice has been paid");
        setStatus(true);
      }
    }
  };

  const createPayment = async() => {

// const stripe = require('stripe')('sk_test_51GzofzBpEmPwvym4nFbUPZXlwOl6uFJd4Yw5pQlVm5Y3nBCBvcrzNyuer2iJZSX2iFr4ef1YEBIqOKWNJd5UUGf0008Lkz5aE4');

// const paymentMethod = await stripe.paymentMethods.create({
//   type: 'card',
//   card: {
//     number: '4242424242424242',
//     exp_month: 8,
//     exp_year: 2021,
//     cvc: '314',
//   },
//   stripeAccount:"acct_1GzofzBpEmPwvym4"
// });
  }

  const calculateTotal = () => {
    var sub = 0;
    const items = invoice.services;

    const usersByLikes = items.map((item) => {
      const { qty, price } = item;

      const subtotal = qty * price;

      sub = subtotal;
    });

    return sub;
  };

  //const services =  state.invoice.services || [];

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {invoice !== null ? (
          <Row>
            <div className="col">
              <Row className="justify-content-center">
                <Col md="6">
                  <Card className="shadow border-0">
                    <CardHeader className="bg-transparent pb-1">
                      <h2>Pay Invoice #{id}</h2>
                      <div className="justify-content-center">
                        <Row
                          style={{ marginTop: "32px" }}
                          className="justify-content-center"
                        >
                          <img src={invoice.logo} width="250px" />
                        </Row>
                        <Row className="justify-content-center">
                          <small
                            className="text-muted"
                            style={{ fontSize: "15px", marginTop: "16px" }}
                          >
                            <b>Invoice from {invoice.company}</b>{" "}
                          </small>
                        </Row>
                        <Row
                          style={{ marginBottom: "32px" }}
                          className="justify-content-center"
                        >
                          <small
                            className="text-muted"
                            style={{ fontSize: "15px", marginTop: "8px" }}
                          >
                            Billed to {invoice.client} ({invoice.clientName})
                          </small>
                        </Row>
                      </div>
                    </CardHeader>
                    <CardBody>
                      {status === true ? (
                      <div>
                          <Row className="justify-content-center">
                            <Col md='12'>
                              <p>Thank you. This invoice has been paid.</p>
                            </Col>
                            <Col>
                         
                       
                         
                            </Col>
                          </Row>
                          <Row className="justify-content-center">
                            {invoice.invoiceType === false  && !invoice.contractSigned ? <Button onClick={signcontract} color="default">Sign Contract</Button> : null}
                            </Row>
                            </div>
                      ) : (
                        <div>
                          <Col>
                            <Row className="justify-content-center">
                              <strong>
                                <p
                                  style={{
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  {invoice.currency.symbol}
                                  {invoice.total} {invoice.currency.code}{" "}
                                  <br></br>Due on {invoice.dueOnString}
                                </p>
                              </strong>
                            </Row>
                     
                     
                            <Row className="justify-content-center">
                              <Elements  stripe={ loadStripe("pk_live_VboC8sR4clpBJFBGdagUVyvQ00UO4YDqfg", {
      stripeAccount:invoice.stripeAccountId,
    })}>
                                <CheckoutForm />
                              </Elements>
                            </Row>
                             <Row
                              style={{
                                marginTop: "16px",
                                marginBottom: "16px",
                              }}
                              className="justify-content-center"
                            >

                            <p>{invoice.memo}</p>
                     
                            </Row> 
                            <Row
                              style={{ marginTop: "0px" }}
                              className="justify-content-center"
                            >
                              <Table
                                className="align-items-center table-flush"
                                responsive
                              >
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col">Item</th>

                                    <th scope="col">Quantity</th>
                                    <th scope="col">Price</th>

                                    <th> </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {invoice.services.map(renderServices)}
                                </tbody>
                              </Table>
                            </Row>

                            <Row className="justify-content-end">
                              <small style={{ textAlign: "left" ,fontSize:'16px'}}>
                              
                                  Subtotal: {invoice.currency.symbol}
                                  {calculateTotal().toFixed(2)}
                              
                              </small>
                            </Row>

                            <Row
                              className="justify-content-end"
                              style={{ marginTop: "8px" }}
                            >
                              <small style={{fontSize:'16px'}}>
                               
                                  Tax ({invoice.taxRate}%):{" "}
                                  {invoice.currency.symbol}
                                  {(invoice.total - calculateTotal()).toFixed(2)}
                             
                              </small>
                            </Row>
                            <Row
                              className="justify-content-end"
                              style={{ marginTop: "8px" }}
                            >
                                 <small style={{fontSize:'16px'}}>
                                <b>
                                  Amount Due: {invoice.currency.symbol}
                                  {invoice.total}
                                </b>
                              </small>
                            </Row>
                          </Col>
                        </div>
                      )}
                    </CardBody>
                  </Card>

                  <Row
                    style={{ marginTop: "16px" }}
                    className="justify-content-center"
                  >
                    <p style={{ fontWeight: "100", fontSize: "13px" }}>
                      {invoice.company} partners with Fruitbowl.io 🍉 to deliver
                      this invoice to you
                    </p>
                  </Row>
                </Col>
              </Row>
            </div>
          </Row>
        ) : (
          <Col>
            <Row className="justify-content-center">
              <Spinner>Loading</Spinner>
            </Row>
          </Col>
        )}
      </Container>
    </>
  )
        };
