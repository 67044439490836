/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { auth } from "./Fire";
import { connect } from "react-redux";
import ActionTypes from "redux/ActionTypes";
import { setValue } from "../../redux/actions/CompanyActions";
import { getUserInfo, showError, showSuccess } from "../../redux/actions/CompanyActions";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Spinner,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Modal,
  Col,
  Container,
} from "reactstrap";
import {
  withRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";



class RedirectNow extends React.Component {
  constructor(props) {
    super(props);
   
    
    this.state = {
      email: "",
      password: "",
      loading: false,
      onboarded: false,
      fgEmail: "",
    };
  }
  componentDidMount() {
    this.authListener();
  }

  nextPath(path) {
    this.props.history.push(path);
  }

  authListener = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.setState({ onboarded: true });
        //(user);
      } else {
        this.setState({ onboarded: false });
        //("user");
      }
    });
  };

  

  render() {
   
    
    return (
      <>
    

    
       
          <Redirect from="/detail/redirect" to="/admin/proposal" />
     
          
          

        )  
      </>
    );
  }

  teset = () => {};
}
const mapStateToProps = (state) => ({
  companyState: state.companyState,
});
export default connect(mapStateToProps, {
  setValue,
  getUserInfo,
  showError,
  showSuccess
})(RedirectNow);
