import React from "react";
import { auth, db, storage } from "./Fire";
import OnboardingAgency from "./OnboardingAgency";
import OnboardingIntegrations from "./OnboardingIntegrations";
import stripeLogo from "../../views/blue-on-dark@3x.png"; // with import
import currency from "./currency";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  CardFooter,
  Nav,
  NavItem,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  DropdownItem,
  DropdownMenu,
  Row,
  Col,
  Progress,
  UncontrolledTooltip,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  Label,
  Spinner,
} from "reactstrap";
import {
  withRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Headers/Header";
import {
  getContracts,
  setValue,
  getUserInfo,
} from "../../redux/actions/CompanyActions";
import ActionTypes from "redux/ActionTypes";

function Activeview(props) {
  if (props.value === 0) {
    return <OnboardingAgency />;
  }
  if (props.value === 1) {
    return <OnboardingIntegrations />;
  }
}

function ActiveTitle(props) {
  if (props.value === 0) {
    return <h2>Select Your Client</h2>;
  }
  if (props.value === 1) {
    return <h2>Add Your Services</h2>;
  }
  if (props.value === 2) {
    return <h2>Attatch A Contract</h2>;
  }
}

class Onboarding extends React.Component {
  componentDidMount() {
    this.props.setValue(ActionTypes.SET_ACTIVE_ONBOARDING, 0);
   // this.props.setValue(ActionTypes.SET_ONBOARDING_NAME, "");
    this.props.setValue(ActionTypes.SET_ONBOARDING_AGENCY, "");
    // this.props.setValue(ActionTypes.SET_CURRENCY, currency);

    this.getCurrentUser();

    //  this.getOnboardState()
  }

  getCurrentUser = async () => {
    var userId = "";

    await auth.onAuthStateChanged((user) => {
      if (user) {
        userId = user.uid;
        //(userId);
        this.setState({ userId: user.uid });
        this.getOnboardState(user.uid);
        this.props.getUserInfo(user.uid);
      }
      return userId;
    });
  };

  getOnboardState = async (uid) => {
    var testState = 0;
    let citiesRef = await db.collection("Users");

    //var uid = await this.getCurrentUser()

    let query = await citiesRef
      .where("uid", "==", uid)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const user = {
            id: doc.id,
            data: doc.data(),
          };

          testState = user.data.newUserProgress;
          // //(user.data)
        });

        //(testState);
        this.setState({ newUserState: testState });
      });

    return testState;
  };

  newUserProgressState = (state) => {
    //("state", state);
    return state;
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeFullName = ({ target }) => {
    this.props.setValue(ActionTypes.SET_ONBOARDING_NAME, target.value);
    if (target.value.length > 5 && target.value.length > 3) {
      this.props.setValue(
        ActionTypes.SET_AUTH_PROGRESS,
        this.props.companyState.setAuthProgress + 15
      );
    }
  };
  handleChangeAgencyName = ({ target }) => {
    this.props.setValue(ActionTypes.SET_ONBOARDING_AGENCY, target.value);
  };
  nextPath(path) {
    this.props.history.push(path);
  }
  logoSave = async (e) => {

   if ( this.state.image == null) {
     this.setState({hasLogo: false})
    this.updateUserInfo("")
   }
else {
  this.setState({hasLogo: true})
    this.setState({ loading: true });
    e.preventDefault();
    var testurl = "";

    let file = this.props.companyState.onboardingAgencyLogo;

    const uploadTask = storage.ref(`images/${file.name + " " + this.props.companyState.setUser.id}`).put(file);

    await uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        //(progress);
      },
      (error) => {
        // error function ....
        //(error);
      },
      () => {
        // complete function ....
        storage
          .ref("images")
          .child(file.name  + " " + this.props.companyState.setUser.id)
          .getDownloadURL()
          .then((url) => {
            //(url);
            //this.saveOnboarded(url);
            this.props.setValue(ActionTypes.SET_AGENCY_LOGO_URL, url);
            testurl = url;
            this.updateUserInfo(url);
          });
      }
    );
  }};


  state = {
    value: 0,
    newUserState: 0,
    userId: "",
    currency: null,
    hasLogo: false
  };

  updateUserInfo = async (url) => {
    var dueDate = new Date(
      new Date().getTime() + 7 * 24 * 60 * 60 * 1000
    );

    //('updating')
    let citiesRef = await db
      .collection("Users")
      .doc(this.props.companyState.setUser.id);

    const res = await citiesRef.update({
      name: this.props.companyState.onboardingName,
      company: this.props.companyState.onboardingAgency,
      onboardingComplete: false,
      newUserProgress: 3,
      current_period_end: dueDate,
      logo: url,
      customLogo: this.state.hasLogo,
      currency: this.props.companyState.currency,
      currencyIso: this.props.companyState.currencyIso,
    });

    this.props.setValue(ActionTypes.SET_PROGRESS_TOTAL, 40);
    this.props.setValue(ActionTypes.NEW_USER_PROGRESS, 2);
    this.nextPath("/detail/billing");
    this.setState({ loading: false });
  };

  constructor(props) {
    super(props);
    this.state = {
      files: null,
      name: "",
      fileURL: "",
      uid: "",
      contractList: [],
      testName: null,
      image: null,
      agencyName: "",
      loading: false,
    };
    this.onImageChange = this.onImageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({
        image: URL.createObjectURL(img),
      });
      this.props.setValue(ActionTypes.SET_ONBOARDING_AGENCY_LOGO, img);
    }
  };

  nextView = () => {
    this.props.setValue(ActionTypes.SET_PROGRESS_TOTAL, 90);
  };

  renderCurrencyList = (currency, index) => {
    const { name } = currency.data;
    return (
      <DropdownItem
        href="#"
        // onClick={(e) =>
        //   this.props.setValue(ActionTypes.SET_INVOICE_CLIENT, client)
        // }
      >
        ({name})
      </DropdownItem>
    );
  };

  saveCurrency = (currency) => {
    //(currency);
    this.props.setValue(ActionTypes.SET_CURRENCY, currency);

    var isoCode = currency.code.toLowerCase();
    this.props.setValue(ActionTypes.SET_CURRENCY_ISO, isoCode);
    this.setState({ currency: currency });
  };

  render() {
    const { onboardingName, onboardingAgency } = this.props.companyState;

    const isEnabled =
      onboardingName.length > 2 &&
     
      onboardingAgency.length > 3 &&
      this.props.companyState.currency != null &&
      this.state.loading === false;

    var progress = this.state.newUserState;
    //(progress);

    return (
      <>
        {/* <div className="text-center text-muted mb-4">
          <h3>My Info</h3>
        </div> */}
        {progress == null ? (
          <Spinner></Spinner>
        ) : progress === 0 ? ( //0
          <Redirect from="/detail/onboarding" to="/auth/login" />
        ) : progress === 2 ? (
          <Redirect from="/detail/onboarding" to="/detail/billing" />
        ) : progress === 3 ? ( //3
          <Redirect from="/detail/onboarding" to="/admin/proposal" />
        ) : progress === 1 ? ( //1
          <div>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Row className="justify-content-center">
                    <img
                      width="250px"
                      src="https://d1pnnwteuly8z3.cloudfront.net/images/957037a9-a90f-436e-a37a-418387aac81a/bdeaea6e-09b6-4338-b995-7d53ce8aec42.png"
                    />
                  </Row>

                  <Row className="justify-content-center">
                    <CardBody>
                    <Row className="justify-content-center">
                    <Col md="8">
                    <Row className="justify-content-center">
                          <Col md="8">
                          <h2>Account Setup</h2>
                          
                      <h6 className="heading-small text-muted mb-4">
                        1. My Info
                      </h6>
                      
                      <div className="pl-lg-4">
                        <FormGroup>
                          <Label className="form-control-label">
                            Full Name
                          </Label>
                          <Input
                            style={{ color: "black" }}
                            className="form-control"
                            placeholder="John Smith"
                            type="text"
                            id="tooltip1596544373"
                            name="name"
                            onChange={this.handleChangeFullName}
                            value={this.props.companyState.onboardingName}
                          />
                        </FormGroup>
                      </div>
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">
                        2. My Business
                      </h6>
                      <div className="pl-lg-4">
                        <FormGroup>
                          <Label className="form-control-label">
                            Business Name
                          </Label>
                          <Input
                            style={{ color: "black" }}
                            className="form-control"
                            placeholder="Digital Creationz Ltd"
                            type="text"
                            id="tooltip1596544332"
                            value={this.props.companyState.onboardingAgency}
                            name="agencyName"
                            onChange={this.handleChangeAgencyName}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="form-control-label">Logo</Label>

                          <img
                            style={{ width: "100%" }}
                            src={this.state.image}
                          />

                          <input
                            style={{ width: "100%" }}
                            type="file"
                            name="myImage"
                            id="tooltip1596544371"
                            onChange={this.onImageChange}
                          />
                        </FormGroup>

                        <FormGroup>
                          <UncontrolledDropdown>
                            <DropdownToggle caret color="default">
                              {this.props.companyState.currency == null
                                ? "Select Your Currency"
                                : this.props.companyState.currency.name +
                                  " (" +
                                  this.props.companyState.currency.symbol +
                                  ")"}
                            </DropdownToggle>
                            <DropdownMenu
                              style={{ overflowY: "scroll", height: "250px" }}
                            >
                              {currency.currencies.map((currency, i) => {
                                return (
                                  <div key={i}>
                                    <DropdownItem
                                      onClick={(e) =>
                                        this.saveCurrency(currency)
                                      }
                                    >
                                      {" "}
                                      {currency.name}{" "}
                                    </DropdownItem>
                                  </div>
                                );
                              })}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </FormGroup>
                      </div>
                      <div className="text-center">
                        <Button
                          className="mt-4"
                          color="primary"
                          type="button"
                          disabled={!isEnabled}
                          onClick={(e) => this.logoSave(e)}
                        >
                          Finish
                        </Button>

                        {this.state.loading == true ? (
                          <Spinner
                            color="primary"
                            animation="border"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        ) : null}

                        <UncontrolledTooltip
                          delay={0}
                          placement="right"
                          target="tooltip1596544373"
                        >
                          e.g John Smith
                        </UncontrolledTooltip>
                        <UncontrolledTooltip
                          delay={0}
                          placement="right"
                          target="tooltip1596544332"
                        >
                          e.g Digital Creationz LTD
                        </UncontrolledTooltip>

                        <UncontrolledTooltip
                          delay={0}
                          placement="right"
                          target="tooltip1596544371"
                        >
                          Your logo will be visible on invoices
                        </UncontrolledTooltip>
                      </div>
                      </Col>

</Row>                      </Col>
                      </Row>
                    
                    </CardBody>
                  </Row>
                </div>
              </Row>
            </Container>
          </div>
        ) : (
          <Redirect from="/detail/onboarding" to="/auth/login" />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  companyState: state.companyState,
});

export default connect(mapStateToProps, {
  setValue,
  getUserInfo,
})(Onboarding);

class OnboardingCredentials extends React.Component {}
