import ActionType from "../ActionTypes";

const INITIAL_STATE = {};

export default function CompanyReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // case ActionType.SET_SELECTED_GIFT_CARDS: {
    //   return {
    //     ...state,
    //     giftCard: action.payload,
    //   };
    // }
    case ActionType.SET_CLIENTS: {
      return {
        ...state,
        giftCard: action.payload,
      };
    }
    default:
        return state;
}
}