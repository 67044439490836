import React, { useState, Component } from "react";
import ReactDOM from "react-dom";
import { auth, db } from "./views/examples/Fire";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";

import OnboardingAgency from "./views/examples/OnboardingAgency";

import AppRoute from "./AppRoute";
import "assets/plugins/nucleo/css/nucleo.css";
//import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import Detail from "layouts/Detail.js";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import Clients from "./views/examples/Clients";
import Login from "./views/examples/Login";
import Proposals from "./views/examples/proposals";
import Index from "../src/views/Index";
import Register from "./views/examples/Register";
import Contracts from "./views/examples/Contracts";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import Profile from "views/examples/Profile.js";
import { connect } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";

import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import CreateProposal from "views/examples/createProposal.js";

import ViewContract from "views/examples/ViewContract";
import ViewInvoice from "views/examples/ViewInvoice";
import PayInvoice from "views/examples/PayInvoiceScreen";
import SignContract from "views/examples/SignContract";
import { persistor, store } from "./redux/store";
import InitialScreen from "views/examples/InitialScreen";
import Onboarding from "views/examples/Onboarding";
import { getKeys } from "redux/actions/CompanyActions";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      onboardingComplete: true,
      onboardState: 0
    };
  }

  componentDidMount() {
    this.authListener();
    this.props.getKeys();
  }

  checkAuthState = async (user) => {
    let citiesRef = await db.collection("Users");

    let query = await citiesRef
      .where("uid", "==", user.uid)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const cust = {
            id: doc.id,
            data: doc.data(),
          };

          //("d")

          //(cust.data.onboardState)

            this.setState({onboardState:cust.data.onboardState})
            // this.props.setValue(ActionTypes.SET_AUTH_VIEW, 3);
            // this.props.setValue(ActionTypes.SET_PROGRESS_TOTAL, 80);
        

          
        });
      });
  };
  nextPath(path) {
    this.props.history.push(path);
  }

  authListener = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
        this.checkAuthState(user)

      } else {
        this.setState({ user: null });
      }
    });
  };

  // const hi = (param) => {

  //     setValue(3)

  //  //   value
  // }

  render() {
    return (
      <div className="App">

      
         
        
            <BrowserRouter>
              <Route path="/detail"  render={(props) => <Detail {...props} />} />
              <Route path="/admin"  render={(props) => <AdminLayout {...props} />} />
              <Route path="/auth"  render={(props) => <AuthLayout {...props} />} />
              <Route exact path="/"  render={(props) => <AdminLayout {...props} />} />
               {/* { this.state.user ?  this.state.onboardState == 0 ? <Redirect from="/" to="/admin/proposal" /> : this.state.onboardState == 1 ? <Redirect from="/" to="/detail/onboarding" />  :
               this.state.onboardState == 2 ? null
               :  null : null} */}

              {/* {this.state.user ?  <Redirect from="/auth/newuser" to="/admin/proposal" /> : <Redirect from="/" to="/auth/newuser" />} */}

              {/* {this.state.user ? (<Route path="/admin" render={(props) => <AdminLayout {...props} />}/>) : (<InitialScreen />)} */}
              {/* <Redirect from="" to="admin/proposal" /> */}
              <Route exact path="admin/proposal" component={Proposals} />
              <Route exact path="/" component={Proposals} />
           
           
             <Route path="detail/billing" component={OnboardingAgency} />
              <Route path="detail/onboarding" component={Onboarding} />
              <Route path="auth/login" component={Login} />        
                         
            
            </BrowserRouter>
        
     
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyState: state.companyState,
});
export default connect(mapStateToProps, {
  
  getKeys,
})(App);

