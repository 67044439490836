import React from "react";
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";
import "assets/scss/argon-dashboard-react.scss";
import Register from "./Register";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Button,
  Container,
  CardBody,
  Input,
  FormGroup,
  Row,
  UncontrolledTooltip,
  Col,
  Form,
  CardText,
  Label,
  Modal,
} from "reactstrap";
import { connect } from "react-redux";
import {
  getClients,
  setValue,
  setNewServices,
} from "../../redux/actions/CompanyActions";
import Text from "react";
import ActionTypes from "redux/ActionTypes";
class ProposalAddService extends React.Component {
  state = {
    defaultModal: false,
    listOfServices: [],
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });

      this.props.setValue(ActionTypes.SET_SERVICE_QUANTITY, null);
      this.props.setValue(ActionTypes.SET_SERVICE_NAME, null);
      this.props.setValue(ActionTypes.SET_SERVICE_PRICE, null);

    
  };
  renderBillingScheduleList = () => {
    return (
      <DropdownMenu>
        <DropdownItem
          href="#pablo"
          onClick={(e) =>
            this.props.setValue(ActionTypes.SET_BILLING_SCHEDULE, "Once Off")
          }
        >
          Once Off
        </DropdownItem>

        <DropdownItem
          href="#pablo"
          onClick={(e) =>
            this.props.setValue(ActionTypes.SET_BILLING_SCHEDULE, "Weekly")
          }
        >
          Weekly
        </DropdownItem>

        <DropdownItem
          href="#pablo"
          onClick={(e) =>
            this.props.setValue(ActionTypes.SET_BILLING_SCHEDULE, "Monthly")
          }
        >
          Monthly
        </DropdownItem>

        {/* <DropdownItem
          href="#pablo"
          onClick={(e) =>
            this.props.setValue(ActionTypes.SET_BILLING_SCHEDULE, "Quarterly")
          }
        >
          Quaterly
        </DropdownItem> */}
      </DropdownMenu>
    );
  };
  renderServices = (service, index) => {
    // const { name, price, qty } = this.props.service

    return (
      <tr key={index}>
        <td>{service.name}</td>

        <td>{service.qty}</td>
    <td>{this.props.companyState.setUser.data.currency.symbol}{service.price}</td>
        <td>
          <Button size="sm" onClick={() => this.adjustServices(index)}>
            Remove
          </Button>
        </td>
      </tr>
    );
  };

  componentDidMount() {
    if (this.props.companyState.setServices != null) {
      this.setState({ listOfServices: this.props.companyState.setServices });
    }

    this.props.setValue(ActionTypes.SET_SERVICE_PRICE, null);

    this.props.setValue(ActionTypes.SET_SERVICE_NAME, null)

    this.props.setValue(ActionTypes.SET_SERVICE_QUANTITY, null)
  }

  addService = () => {
    const { companyState } = this.props;

    let { serviceName, serviceQuantity, servicePrice } = companyState;

    let services = companyState.services || [];

    // get name price qty

    const service = {
      name: this.props.companyState.serviceName,
      price: this.props.companyState.servicePrice,
      qty: this.props.companyState.serviceQuantity,
    };

    this.state.listOfServices.push(service);
    //(this.state.listOfServices);

    ////(service)
    this.props.setValue(ActionTypes.SET_SERVICES, this.state.listOfServices);
  };

  handleChangeMemo = ({ target }) => {
    //(target.value);
    this.props.setValue(ActionTypes.SET_MEMO, target.value);
  };

  handleChangeTax = ({ target }) => {
    //(target.value);
    this.props.setValue(ActionTypes.SET_SERVICE_TAX, target.value);
  };

  handleChangeName = ({ target }) => {
    //(target);

    this.props.setValue(ActionTypes.SET_SERVICE_NAME, target.value);
    if (target.value === "") {
      this.props.setValue(ActionTypes.SET_SERVICE_NAME, null);

    }
  };

  handleChangeQty = ({ target }) => {
    //(target);

    this.props.setValue(ActionTypes.SET_SERVICE_QUANTITY, target.value);
    if (target.value === "") {
      this.props.setValue(ActionTypes.SET_SERVICE_QUANTITY, null);

    }
  };

  handleChangeInterval = ({ target }) => {
    //(target);

    this.props.setValue(ActionTypes.SET_INTERVAL, target.value);
  };

  handleChangePrice = ({ target }) => {
    //(target);

    this.props.setValue(ActionTypes.SET_SERVICE_PRICE, target.value);

    if (target.value === "") {
      this.props.setValue(ActionTypes.SET_SERVICE_PRICE, null);

    }

  };

  calculateTotal = () => {
    this.addService();
    this.toggleModal("defaultModal");
  };

  adjustServices = (index) => {
    const list = this.state.listOfServices;

    list.splice(index, 1);

    this.setState({ list });
  };

  mapServices = () => {
    if (this.state.listOfServices) {
    } else
      return (
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
  };

  render() {
    //  //(this.props.companyState.setServices.name);
    const services = this.props.companyState.setServices || [];

    //  const testList = this.state.listOfServices.map((service, index) => {

    //     return(
    //       <tr key={index}>
    //       <td>{service.name}</td>
    //       <td>{service.price}</td>
    //       <td>{service.qty}</td>
    //       <td><Button size="sm" onClick={() => this.adjustServices(index)}>Remove</Button></td>
    //       </tr>
    //     )

    //       })

    return (
      <div style={{ marginTop: "64px" }}>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.defaultModal}
          toggle={() => this.toggleModal("defaultModal")}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-5">
                <div className="text text-center mt-2 mb-3">
                  <h2>Add A New Item</h2>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-building" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        onChange={this.handleChangeName}
                        //  onChange={({nativeEvent}) => //(nativeEvent) }
                        id="exampleFormControlInput1"
                        className="form-control"
                        // value={this.props.companyState.serviceName}
                        placeholder="Item Name"
                        type="text"
                        style={{ color: "#3d3d3d" }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-copy-04" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        onChange={this.handleChangeQty}
                        placeholder="Quantity"
                        type="number"
                        style={{ color: "#3d3d3d" }}
                        className="form-control"
                        //  value={this.props.companyState.serviceQuantity}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-tag" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        // value={this.props.companyState.servicePrice}
                        onChange={this.handleChangePrice}
                    //    id="exampleFormControlInput1"
                        className="form-control"
                        placeholder="Price"
                        type="number"
                        style={{ color: "#3d3d3d" }}
                       // value={this.props.companyState.setUser.data.currency.symbol + }
                        
                      
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button color="primary"  disabled={this.props.companyState.servicePrice === null || this.props.companyState.serviceName === null || this.props.companyState.serviceQuantity === null}   onClick={this.calculateTotal}>
                      Save Service
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>

        <Form>
          <Row className="justify-content-center">
            <Col lg="10">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Item</th>

                    <th scope="col">Quantity</th>
                    <th scope="col">Price</th>

                    <th> </th>
                  </tr>
                </thead>
                <tbody>{services.map(this.renderServices)}</tbody>
              </Table>

              <Row className="justify-content-center">
                <Button
                  size="sm"
                  style={{ marginTop: "16px" }}
                  color="default"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  Add Item
                </Button>
              </Row>

              <Row
                className="justify-content-center"
                style={{ marginTop: "48px", marginBottom: "48px" }}
              >
                <Col>
                  <div style={{ marginTop: "32px" }}>
                  <Label
                    style={{ marginTop: "32px" }}
                    className="form-control-label"
                  >
                    Billing Schedule *
                  </Label>
                  <FormGroup>
                    <UncontrolledDropdown group>
                      <DropdownToggle caret color="default">
                        {this.props.companyState.billingSchedule
                          ? this.props.companyState.billingSchedule
                          : "Is this a recurring invoice?"}
                      </DropdownToggle>

                      {this.renderBillingScheduleList()}
                    </UncontrolledDropdown>
                  </FormGroup>
                  <Label
                      style={{ marginTop: "0px" }}
                      className="form-control-label"
                    >
                      Payment Due *
                    </Label>
                    <Input
                      style={{ width: "50%" }}
                      onChange={this.handleChangeInterval}
                      id="exampleFormControlInput1"
                      className="form-control"
                      placeholder="30"
                      value={this.props.companyState.invoiceInterval}
                      type="number"
                      min="0"
                    />{" "}
                    <p>Days after invoice sent</p>
                    <Label className="form-control-label">
                      Invoice Memo (optional)
                    </Label>
                    <Input
                      style={{ width: "50%" }}
                      id="exampleFormControlTextarea1"
                      placeholder="e.g We appreciate your business"
                      onChange={this.handleChangeMemo}
                      rows="3"
                      value={this.props.companyState.setMemo}
                      type="textarea"
                    />
                    <Label
                      style={{ marginTop: "32px" }}
                      className="form-control-label"
                    >
                      Tax Rate (%)
                    </Label>
                    <Input
                      style={{ width: "50%" }}
                      onChange={this.handleChangeTax}
                      id="exampleFormControlInput1"
                      className="form-control"
                      placeholder="20%"
                      min="0"
                      value={this.props.companyState.taxRate }
                      type="number"
                    />
                 
                  </div>
                
                </Col>
              </Row>
            </Col>
          </Row>

          {/* <Row className="justify-content-center">
            <Col  lg="5">
              <Label className="form-control-label">Services</Label>
              <FormGroup>
                <Input
                  onChange={this.handleChangeName}
                  //  onChange={({nativeEvent}) => //(nativeEvent) }
                  id="exampleFormControlInput1"
                  className="form-control-alternative"
                  value={this.props.companyState.serviceName}
                  placeholder="Paid Traffic Services"
                  type="text"
                />
              </FormGroup>
            </Col>

            <Col  lg={{size: "auto"}}>
              <Label className="form-control-label">Quantity</Label>
              <FormGroup>
                <Input
                  onChange={this.handleChangeQty}
                  placeholder="1"
                  type="number"
                  className="form-control-alternative"
                  value={this.props.companyState.serviceQuantity}
                />
              </FormGroup>
            </Col>



            <Col lg={{size: "auto"}}>
              <Label className="form-control-label">Price</Label>
              <FormGroup>
                <Input
                  value={this.props.companyState.servicePrice}
                  onChange={this.handleChangePrice}
                  id="exampleFormControlInput1"
                  className="form-control-alternative"
                  placeholder="£1800"
                  type="number"
                  prefix="£"
                />

             
              </FormGroup>
            </Col>
            <Col md="1">
              <FormGroup>
                <Label>Add to total</Label>
                <Button color="secondary" onClick={this.calculateTotal}>
                  Save Service
                </Button>
              </FormGroup>
            </Col>
          </Row> */}
        </Form>

        <Col>
          <Row className="justify-content-center">
            {/* <Button color="secondary" size="sm" type="button" >
         Add Another Service
         <i className="ni ni-fat-add"></i>
        </Button> */}
          </Row>
        </Col>

        <Row className="justify-content-center">
          <Col></Col>
        </Row>
        <CardFooter> </CardFooter>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  companyState: state.companyState,
});

export default connect(mapStateToProps, {
  getClients,
  setValue,
})(ProposalAddService);
