import React from "react";
import moment from "moment";
import { format } from "date-fns";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Button,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
  NavItem,
  NavLink,
  Nav,
  FormGroup,
  Form,
  Input,
  Spinner,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  

  ModalHeader,
  Modal,
  CardBody,
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter,  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,} from "react-router-dom";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";

import ProposalAddContract from "./ProposalAddContract";
import { db, auth, storage } from "./Fire";
import ActionTypes from "redux/ActionTypes";
import { getContracts, setValue } from "../../redux/actions/CompanyActions";
import { connect } from "react-redux";
var delay = ( function() {
  var timer = 0;
  return function(callback, ms) {
      clearTimeout (timer);
      timer = setTimeout(callback, ms);
  };
})();
class Contracts extends React.Component {
  state = {
    defaultModal: false,
    hasContracts: false,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      files: null,
      name: "",
      fileURL: "",
      uid: "",
      progress:0,
      contractList: [],
      testName: null,
    };
  }

  openContract = (index) => {
    //("hi");

    var t = this.props.companyState.contracts[index];
    this.props.setValue(ActionTypes.SET_CURRENT_CONTRACT, t);
    //(t);
    this.nextPath("/detail/viewcontract");
  };
  authListener = async() => {
    auth.onAuthStateChanged(async(user) => {
      if (user) {
        this.setState({  onboarded:true });
    
       
      
      } else {
        this.setState({  onboarded:false  });
      }
    });
  };
  componentDidMount(clientsRetrieved) {
    this.authListener()

    this.props.getContracts(this.props.companyState.setUserID);
    this.setState({ hasContracts: this.props.companyState.hasContracts });

  }

  renderCont = (cont, index) => {
    const { name, fileURL, date } = cont.data;
    return (
      <tr key={index}>
        <td>{name}</td>

        {/* <td>{date}</td> */}
        <td>
          {" "}
          <Button
            color="default"
            size="sm"
            onClick={() => this.openContract(index)}
          >
            View Contract
          </Button>
        </td>
      </tr>
    );
  };

  handleChange = (files) => {
    this.setState({
      files: files,
      testName: files[0].name,
    });
  };
  nextPath(path) {
    this.props.history.push(path);
  }
  handleSave = () => {
    let file = this.state.files[0];

    const uploadTask = storage.ref(`Contracts/${file.name + " " + this.props.companyState.setUserID}`).put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
          this.setState({progress:progress})
        //(progress);
      },
      (error) => {
        // error function ....
        //(error);
      },
      () => {
        // complete function ....
        storage
          .ref("Contracts")
          .child(file.name + " " + this.props.companyState.setUserID)
          .getDownloadURL()
          .then((url) => {
            //(url);
            this.uploadContract(url, file.name);
            
          });
      }
    );
  };

  uploadContract = async (url, name) => {


  var date = Date.now()
  var formattedDate = format(date, "MMM d, yyyy");

    const cityRef = db
      .collection("Contracts")
      .add ({
        date: formattedDate,
        fileURL: url ,
        name: name,
        uid: this.props.companyState.setUserID
      }).then((contract) => {

        const notify = () => toast.success(name + " has been saved", {autoClose:3000});
        notify()
        delay(function(){
          window.location.reload(); 
      }, 3000 )
      }).catch((err)=> {

        const notify = () => toast.error(name + " could not be uploaded", {autoClose:3000});
        notify()
  
      });
  
      if (this.state.progress == 100) {
        this.toggleModal("defaultModal")
        this.setState({progress:0})
      }
    
   
  };

  render() {
    const contracts = this.props.companyState.contracts || [];
    const { hasContracts, files } = this.state;

    const isEnabled = files != null && files[0].name.split('.').pop() == 'pdf' ;

    return (
      <>
         { this.props.companyState.newUserProgress !== 3 && this.state.onboarded == false ?   <Redirect from="/admin/contracts" to="/auth/newuser" /> : <div>
          <Header></Header>
          <Container className="mt--7" fluid>
          <ToastContainer />
            <Row>
              <div className="col">
                <Card className="shadow border-0">
                  <CardHeader className="bg-transparent pb-5">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="text-black mb-0">Contracts</h2>
                      </div>
                      <div className="col">
                        <Nav className=" justify-content-end " pills>
                          <NavItem>
                            <Button
                              color="primary"
                              onClick={() => this.toggleModal("defaultModal")}
                            >
                              New Contract
                              {/* <i className="ni ni-fat-add"></i> */}
                            </Button>
                          </NavItem>
                        </Nav>
                      </div>
                    </Row>
                    <Modal
                      className="modal-dialog-centered"
                      isOpen={this.state.defaultModal}
                      toggle={() => this.toggleModal("defaultModal")}
                    >
                      <div className="modal-body p-0">
                        <Card className="bg-secondary shadow border-0">
                          <ModalHeader className="text-center">
                            <h2>Upload a new contract    <p>(PDF files accepted)</p></h2>
                          
                          </ModalHeader>
                          <CardBody className="px-lg-5 py-lg-5">
                            <Row className="justify-content-center">
                            <div className="text-center">
                              <Input
                                className="my-4"
                                color="primary"
                                type="file"
                                onChange={(e) => {
                                  this.handleChange(e.target.files);
                                }}
                              >
                                Upload Contract
                              </Input>
                            {this.state.progress > 0 ?  < Progress style={{marginTop:'48px'}} className="text-center" animated max="100"  value={this.state.progress} color="success"></Progress>: null}
                             
                              <Button
                                className="my-4"
                                color="primary"
                                type="button"
                                onClick={this.handleSave}
                                disabled={!isEnabled}
                              >
                                Save
                              </Button>
                            </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </div>
                    </Modal>
                  </CardHeader>


                  {this.props.companyState.globalLoading === true ? 
                    
                    <CardBody>
                      <Col>
                      <Row className="justify-content-center"> 
                        
                        
                         <Spinner></Spinner>    </Row>
                         <Row style={{marginTop:'32px'}} className="justify-content-center"> 
                          Loading Contracts...
                    </Row>
                    
                   
                      </Col>
                      </CardBody>
                    : 
                        hasContracts ?
                        <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Contract</th>
                            {/* <th scope="col">Uploaded On</th> */}
    
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>{contracts.map(this.renderCont)}</tbody>
                      </Table>
                      :
                      
                        <div style={{ marginTop: "5%", marginBottom: "5%" }}>
                          <Col>
                            <Row className="justify-content-center">
                              <img
                                width="30%"
                                src="https://blush.ly/xXfpO4nWh/p"
                              ></img>
                            </Row>
                            <Row className="justify-content-center">
                              <h1>Upload your first contract!</h1>
                            </Row>
                            <Row className="justify-content-center">
                              <p>Tap 'New Contract' to begin.</p>
                            </Row>
    
                            <Row className="justify-content-center">
                            <Button
                              color="primary"
                              onClick={() => this.toggleModal("defaultModal")}
                            >
                              New Contract
                              {/* <i className="ni ni-fat-add"></i> */}
                            </Button>
                            </Row>
                          </Col>
                        </div>
                      
                      }

              
                </Card>
              </div>
            </Row>
          </Container>
                    </div> }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyState: state.companyState,
});
export default connect(mapStateToProps, {
  getContracts,
  setValue,
})(Contracts);
