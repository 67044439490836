import React from "react";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Button,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
  NavItem,
  NavLink,
  Nav,
  Modal,
  CardBody,
  FormGroup,
  Form,
  Spinner,
  Input,
  InputGroup,
  Alert,
  InputGroupAddon,
  InputGroupText,
  Label,
  UncontrolledAlert,
  ModalHeader,
} from "reactstrap";
import {
  withRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ActionTypes from "redux/ActionTypes";
import {
  getClients,
  createStripeCustomer, setValue
} from "../../redux/actions/CompanyActions";
import { connect } from "react-redux";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";
import { useHistory } from "react-router-dom";

import NewClientModal from "views/examples/NewClientModal";
import { auth, db } from "./Fire";

var delay = (function () {
  var timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function newAlert() {
  return (
    <Alert className="alert-default">
      <strong>Default!</strong> This is a default alert—check it out!
    </Alert>
  );
}

class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.addClient = this.addClient.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      name: "",
      email: "",
      company: "",
      uid: "",
      clientList: [],
      loading: false,
    };
  }

  nextPath(path) {
    this.props.history.push(path);
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  addClient = async(e) => {
    this.setState({ loading: true });

    const customer = {
      name: this.state.name,
      email: this.state.email,
    };

    const res = await db.collection('Clients').add({
      name: this.state.name,
      email: this.state.email,
      company: this.state.company,
      uid: auth.currentUser.uid,
    }).then((doc) => {

this.props.setValue(ActionTypes.SET_NEW_CLIENT, doc.id);
//(this.props.companyState.newClient)
this.props.createStripeCustomer(customer, doc.id, false)
     // //('Added document with ID: ', res.id);
    //  delay(function () {
    //       window.location.reload();
    //       }, 3000);
      

    }).catch((err) => {
      const notify = () =>
        toast.error(this.state.name + " could not be saved", {
          autoClose: 3000,
        });
      notify();
    });
    
   

    //this.props.createStripeCustomer(customer)
    //save cust id to client document

    // const sres = await  db.collection("Clients")
    //   .add({
    //     name: this.state.name,
    //     email: this.state.email,
    //     company: this.state.company,
    //     uid: auth.currentUser.uid,
    //   })
    //   .then((doc) => {
        


       
     
    //    // this.props.createStripeCustomer(customer, doc.id, true)
      
    //     delay(function () {
    //    // window.location.reload();
    //     }, 3000);
    //   })
    //   .catch((err) => {
    //     const notify = () =>
    //       toast.error(this.state.name + " could not be saved", {
    //         autoClose: 3000,
    //       });
    //     notify();
    //   });

 

    this.toggleModal("defaultModal");
    this.setState({ loading: false });
  //  window.location.reload();
  }

  testRefresh = () => {
    window.location.reload(false);
    this.nextPath("/admin/clients");
  };

  logOut() {
    auth.signOut();
  }

  createClient = () => {
    if (this.props.companyState.setUser.data.onboardingComplete){
      this.toggleModal("defaultModal")
    }
    else{
      const notify = () =>
      toast.warning("Connect your stripe to add new clients", {
        autoClose: 5000,
      });
    notify();
    }
  }

  componentDidMount(clientsRetrieved) {
    this.authListener();
    this.props.getClients(this.props.companyState.setUserID);

    this.setState({
      hasClients: this.props.companyState.hasClients,
      tooltipOpen: true,
    });
    // let citiesRef = db.firestore().collection('Clients');

    //   //var clientList = [];
    //   let query = citiesRef.where('uid', '==', "G6VDYTxIRUbLKB9LE9jJjSvC9BD2").get()
    //   .then(snapshot => {
    //     const newclientlist = []
    //     snapshot.forEach(doc => {

    //       const customer = {
    //         id : doc.id,
    //         data : doc.data()
    //       }
    //       // const data = doc.data()
    //       newclientlist.push(customer);
    //       // //(this.newclientlist);
    //     })

    //     this.setState({clientList: newclientlist})

    //     if (snapshot.empty) {
    //       //('No matching documents.');
    //       return;
    //     }

    //   })
    //   .catch(err => {
    //     //('Error getting documents', err);
    //   });
  }

  renderClient = (client, index) => {
    const { name, company, email } = client.data;
    
    return (


       

      <tr key={index}>
        <td>{company}</td>
        <td>{name}</td>
        <td>{email}</td>
      
      </tr>

    
      );
  };

  testList = [
 
  ];

  state = {
    defaultModal: false,
    hasClients: false,
    tooltipOpen: false,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };
  authListener = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.setState({ onboarded: true });
      } else {
        this.setState({ onboarded: false });
      }
    });
  };
  render() {
    const clients = this.props.companyState.clients || [];
    const { hasClients, email, company, name } = this.state;
    //(clients);

    const isEnabled =
      validateEmail(email) && company.length > 2 && name.length > 2;

    const notify = () => toast.success("Wow so easy !");

    return (
      <>
        {this.state.onboarded == false &&
        this.props.companyState.newUserProgress !== 3 ? (
          <Redirect from="/admin/clients" to="/auth/newuser" />
        ) : (
          <div>
            <Header></Header>
            <ToastContainer />
            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow border-0">
                    <CardHeader className="bg-transparent pb-5">
                      <Row className="align-items-center">
                        <div className="col">
                          <h2 className="text-black mb-0">Clients</h2>
                        </div>
                        <div className="col">
                          <Nav className=" justify-content-end " pills>
                            {/* <Button onClick={this.logOut}>Log Out</Button> */}
                            <NavItem>
                              <Button
                                color="primary"
                                onClick={() => this.createClient()}
                             
                              >
                                New Client
                                {/* //    <i className="ni ni-fat-add"></i> */}
                              </Button>
                            </NavItem>
                          </Nav>
                        </div>
                      </Row>

                      <Modal
                        className="modal-dialog-centered"
                        isOpen={this.state.defaultModal}
                        toggle={() => this.toggleModal("defaultModal")}
                      >
                        <div className="modal-body p-0">
                          <Card className="bg-secondary shadow border-0">
                            <ModalHeader>
                              <Col>
                                <Row className="justify-contnet-center">
                                  <div className="text-center">
                                    <h2>New Client</h2>
                                  </div>
                                </Row>
                              </Col>
                            </ModalHeader>
                            <CardBody className="px-lg-5 py-lg-5">
                              <Form role="form">
                                <FormGroup className="mb-3">
                                  <InputGroup className="input-group">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="ni ni-building" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      style={{ color: "#3d3d3d" }}
                                      placeholder="Client's Business Name (e.g ABC Dentistry)"
                                      type="text"
                                      id="tooltip860969338"
                                      color="#000"
                                      value={this.state.company}
                                      name="company"
                                      onChange={this.handleChange}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                  <InputGroup className="input-group">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="ni ni-circle-08" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      style={{ color: "#3d3d3d" }}
                                      placeholder="Client's Name"
                                      type="text"
                                      id="tooltip297043631"
                                      value={this.state.name}
                                      name="name"
                                      onChange={this.handleChange}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                  <InputGroup className="input-group">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="ni ni-email-83" />
                                      </InputGroupText>
                                    </InputGroupAddon>

                                    <Input
                                      style={{ color: "#3d3d3d" }}
                                      placeholder="Email"
                                      id="tooltip297043631"
                                      type="email"
                                      value={this.state.email}
                                      name="email"
                                      onChange={this.handleChange}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <div className="text-center">
                                  <Button
                                    className="my-4"
                                    color="primary"
                                    type="button"
                                    onClick={this.addClient}
                                    disabled={!isEnabled}
                                  >
                                    Add Client
                                  </Button>
                                  {this.state.loading == true ? (
                                    <Spinner animation="border" role="status">
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </Spinner>
                                  ) : null}
                                </div>
                              </Form>
                            </CardBody>
                          </Card>
                        </div>
                      </Modal>
                    </CardHeader>

                    {this.props.companyState.globalLoading === true ? 
                    
                    <CardBody>
                      <Col>
                      <Row className="justify-content-center"> 
                        
                        
                         <Spinner></Spinner>    </Row>
                         <Row style={{marginTop:'32px'}} className="justify-content-center"> 
                          Loading Clients...
                    </Row>
                    
                   
                      </Col>
                      </CardBody>
                    : 

                    hasClients || this.props.companyState.setUser.data.onboardingComplete === false ? (
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Client</th>
                            <th scope="col">Name</th>

                            <th scope="col">Email</th>

                            <th scope="col" />
                          </tr>
                        </thead>
                        {this.props.companyState.setUser.data.onboardingComplete ?   <tbody>{clients.map(this.renderClient)}</tbody> : 
                        
                        <tbody>
                          <tr>
                            <td>Rosie's Beauty Ltd</td>
                            <td>Rosie M</td>
                            <td>rosie.m@rosiesbeauty.com</td>
                            <td><Badge color={"success"} pill>
            EXAMPLE CLIENT
          </Badge></td>
                          </tr>
                        </tbody>
                        
                        }
                      
                      </Table>
                    ) : (
                      <div style={{ marginTop: "5%", marginBottom: "5%" }}>
                        <Col>
                          <Row className="justify-content-center">
                            <img
                              width="30%"
                              src="https://blush.ly/TXFFiUOmg/p"
                            ></img>
                          </Row>
                          <Row className="justify-content-center">
                            <h1>Create your first client!</h1>
                          </Row>
                          <Row className="justify-content-center">
                            <p>Tap 'New Client' to add a new client.</p>
                          </Row>

                          <Row className="justify-content-center">
                            <Button
                              color="primary"
                              onClick={() => this.toggleModal("defaultModal")}
                            >
                              New Client
                              {/* <i className="ni ni-fat-add"></i> */}
                            </Button>
                          </Row>
                        </Col>
                      </div>
                    ) }
                  </Card>
                </div>
              </Row>
            </Container>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyState: state.companyState,
});
export default connect(mapStateToProps, {
  getClients,
  setValue,
  createStripeCustomer,
})(Clients);
