import { db, auth } from "../../views/examples/Fire";
// import auth from './Fire'
import ActionTypes from "../ActionTypes";
import { format } from "date-fns";
import AppStitch from "@appstitch/core";
import * as StripeClient from "@appstitch/stripe"

import { ToastContainer, toast } from "react-toastify";
import { Redirect } from 'react-router-dom';

//onst stitch = new appStitch();

function getDateTimeFromTimestamp(unixTimeStamp) {
  let date = new Date(unixTimeStamp);
  return (
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear() +
    " " +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2)
  );
}

export const getClients = (clientID) => {
  return async(dispatch) => {
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));

    let citiesRef = await db.collection("Clients");

    //var clientList = [];
    let query = await citiesRef
      .where("uid", "==", clientID)
      .orderBy("company", "asc")
      .get()
      .then((snapshot) => {
        const newclientlist = [];
        snapshot.forEach((doc) => {
          const customer = {
            id: doc.id,
            data: doc.data(),
          };
          // const data = doc.data()
          newclientlist.push(customer);
          // //(this.newclientlist);
        });

        if (snapshot.empty) {
          //("no docs m8");
          dispatch(setValue(ActionTypes.SET_HAS_CLIENTS, false));
          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

        } else if (!snapshot.empty) {
          //("we have data");
          dispatch(setValue(ActionTypes.SET_HAS_CLIENTS, true));
          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

        }
        dispatch(setValue(ActionTypes.SET_CLIENTS, newclientlist));
      })

      .catch((err) => {
        //("Error getting documents", err);
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

      });
  };
};





export const loading = () => {
  return  (dispatch, getState) => {

    const { companyState } = getState();

    return companyState.globalLoading


  }
}


export const getInvocies = (clientID) => {
  return async (dispatch) => {
    //clientID  = "G6VDYTxIRUbLKB9LE9jJjSvC9BD2"
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));

    let citiesRef = await db.collection("Invoices");

    //var clientList = [];
    let query = citiesRef
      .where("uid", "==", clientID)
      .orderBy("createdOn", "desc")
      .get()
      .then((snapshot) => {
        const newInvoiceList = [];
        snapshot.forEach((doc) => {
          const invoice = {
            id: doc.id,
            data: doc.data(),
          };
          // const data = doc.data()
          newInvoiceList.push(invoice);
          // //(this.newclientlist);
        });
        if (snapshot.empty) {
          //("no docs m8");
          dispatch(setValue(ActionTypes.SET_HAS_INVOICES, false));
          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

        } else if (!snapshot.empty) {
          //("we have data");
          dispatch(setValue(ActionTypes.SET_HAS_INVOICES, true));
          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

        }
        dispatch(setValue(ActionTypes.SET_INVOICES, newInvoiceList));
      })
      .catch((err) => {
        //("Error getting documents", err);
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

      });
  };
};


export const getKeys = (clientID) => {
  return async (dispatch) => {

    db
      .collection("Keys")
      // .id("PrivateKeys")
      .get()
      .then((result) => {
        result.forEach((doc) => {
          if (doc.id == "PrivateKeys") {
            console.log(doc.data());

            const { AppstitchClientId, AppstitchKey } = doc.data();
            AppStitch.initialize({
              clientID: AppstitchClientId,
              appStitchKey: AppstitchKey,
            });

          
          }
        });

        return result;
      })
      .catch((err) => {
        console.log(err);

        return err;
      });

  }
}

export const getContracts = (clientID) => {
  return async (dispatch) => {
    ///tesh
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));

    //clientID  = "G6VDYTxIRUbLKB9LE9jJjSvC9BD2"
    let citiesRef = await db.collection("Contracts");

    //var clientList = [];
    let query = await citiesRef
      .where("uid", "==", clientID)
      .orderBy("name", "asc")
      .get()
      .then((snapshot) => {
        const newcontractlist = [];
        snapshot.forEach((doc) => {
          const cust = {
            id: doc.id,
            data: doc.data(),
          };
          // const data = doc.data()
          newcontractlist.push(cust);
          // //(this.newclientlist);
        });
        if (snapshot.empty) {
          //("no docs m8");
          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

          dispatch(setValue(ActionTypes.SET_HAS_CONTRACTS, false));
        } else if (!snapshot.empty) {
          //("we have data");
          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

          dispatch(setValue(ActionTypes.SET_HAS_CONTRACTS, true));
        }
        dispatch(setValue(ActionTypes.SET_CONTRACTS, newcontractlist));
      })
      .catch((err) => {
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

        //("Error getting documents", err);
      });
  };

  // return dispatch => {
  //   clientID  = "G6VDYTxIRUbLKB9LE9jJjSvC9BD2"
  //   let citiesRef = db.collection('Contracts');

  //   //var clientList = [];
  //   let query = citiesRef.where('uid', '==', "G6VDYTxIRUbLKB9LE9jJjSvC9BD2").get()
  //   .then(snapshot => {
  //     const newcontractlist = []
  //     snapshot.forEach(doc => {

  //       const cust = {
  //         id : doc.id,
  //         data : doc.data()
  //       }
  //       // const data = doc.data()
  //       newcontractlist.push(cust);
  //        //(this.newcontractlist);
  //     })

  //     dispatch(setValue(ActionTypes.SET_CONTRACTS, newcontractlist))

  //   })
  //   .catch(err => {
  //     //('Error gretting documents', err);
  //   });

  // }
};

export const retrieveStripeAccount = (accountId) => {
  return async (dispatch) => {
    const account = {
      accountId: accountId,
    };
  };
};

export const onboardStripeStandardAccount = (authorizationCode) => {
  return async (dispatch, getState) => {
    const { companyState } = getState();
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));
    const authObject = {
      code: authorizationCode,
      grant_type: "authorization_code",
    };

    //("attemmpt to onboard");

    AppStitch
      .authorizeStripeClient({
        stripe_client_id: "ca_HZINUW2GOvZhPNQEPG3qkihGR9HlU6Bl",
        code: authorizationCode,
        grant_type: "authorization_code",
      })
      .then( async(res) => {
        //(res.data.stripe_user_id);
          console.log(res)
        if (res.scope === "read_write"){

          console.log("onboard success")
        //("onboarded success");
        const onSuccess = () => {
          return true;
        };
        const callback = {
          onSuccess,
        };
        dispatch(setValue(ActionTypes.SET_STRIPE_ID, true));
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));


       await dispatch(
          updateUser({
            userDoc: companyState.setUser.id,
            data: { stripeAccountId: res.stripe_user_id, onboardingComplete: true },
            callback,
          })
        );

      
        
        }
        else if (res.statusCode === 400) {

          console.log("onboard error")
          dispatch(setValue(ActionTypes.SET_STRIPE_ID, false));
          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));
        }

       


      })
      .catch((error) => {
        //(error);
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));
      });

    // appStitch.createStripeCustomer(customer).then(result => {
    // const result = {
    //   status: true,
    // };

    // if (result.status) {
    //   //("new stripe customer");
    // } else {
    //   // show toast
    // }

    // // })
  };
};

const nextPath = (path) => {
  //props.history.push(path);
}

export const createStripeCustomer = (customer, docId, type) => {
  return async (dispatch, getState) => {

    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));
    const { companyState } = getState();
    var cust = customer;
    var document = companyState.newClient;

    if (type == true) {
      //true means its customers of fruitbowl.io false means its clients of fruitbowl.io customers
      const { companyState } = getState();
      var cust = customer;
      var document = companyState.newClient;
      //("create cust attempt");

      StripeClient.createCustomer({
          name: customer.name,
          email: customer.email,
        })
        .then((res) => {
          console.log(res)

          if (res.object === "customer") {

            dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));
            var data = {
              stripeCustomerId: res.id,
            };

            dispatch(
              updateNewUser({
                docId: docId,
                data: { stripeCustomerId: data.stripeCustomerId },
              })
            );
          }
        })
        .catch((error) => {

          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));
          dispatch(showError({ message: "Stripe Customer couldn't be saved" }));
          //(error);
        });
    } else {
      //("create cust attempt");

      StripeClient.createCustomer({
          name: customer.name,
          email: customer.email,
          stripeAccount: companyState.setUser.data.stripeAccountId,
        })
        .then((res) => {
          console.log(res)
          if (res.object === "customer") {
            var data = {
              stripeCustomerId: res.id,
            };

            dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));
            dispatch(
              updateClient({
                docId: docId,
                data: { stripeCustomerId: data.stripeCustomerId },
              })
            );
          }
        })
        .catch((error) => {

          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));
          dispatch(showError({ message: "Stripe Customer couldn't be saved" }));
          //(error);
        });
    }
  };
};
export const updateClient = ({ docId, data }) => {
  return async (dispatch) => {
    //clientID  = "G6VDYTxIRUbLKB9LE9jJjSvC9BD2"
    let citiesRef = await db
      .collection("Clients")
      .doc(docId)
      .update(data)
      .then((result) => {
        //("cust id saved successfuly");
        dispatch(showSuccess({ message: "Client Saved Successfully" }));
      })
      .catch((err) => {
        //("error updating doc", err);
        dispatch(showError({ message: "Client couldn't be saved" }));
      });
  };
};

export const updateNewUser = ({ docId, data }) => {
  return async (dispatch) => {
    //clientID  = "G6VDYTxIRUbLKB9LE9jJjSvC9BD2"
    let citiesRef = await db
      .collection("Users")
      .doc(docId)
      .update(data)
      .then((result) => {
        //("cust id saved successfuly");
        dispatch(showSuccess({ message: "Client Saved Successfully" }));
      })
      .catch((err) => {
        //("error updating doc", err);
        dispatch(showError({ message: "Client couldn't be saved" }));
      });
  };
};

export const showSuccess = ({ message }) => {
  return async (dispatch) => {
    const notify = () =>
      toast.success(message, {
        autoClose: 3000,
      });

    return notify();
  };
};

export const showError = ({ message }) => {
  return async (dispatch) => {
    const notify = () =>
      toast.error(message, {
        autoClose: 3000,
      });

    return notify();
  };
};

export const createStripeChargex = (charge) => {
  return async (dispatch) => {
    // appStitch.createStripeCharge(charge).then(result => {

    const result = {
      status: true,
    };
    const onSuccess = () => {
      //
      // redirect
    };
    const callback = {
      onSuccess,
    };

    if (result.status) {
      var d = new Date();
      var datePaid = new Date(d.getFullYear(), d.getMonth(), d.getDate());
      var datePaidString = format(datePaid, "MMM d, yyyy");
      dispatch();
      //     updateInvoice({ invoiceId, data: { status: "PAID", stat:"primary", datePaid: datePaid, datePaidString: datePaidString  }, callback })
    } else {
      //("payement failed");
    }
  };
};


export const createStripeProduct = (invoice) => {
  return async (dispatch, getState) => {
    AppStitch
      .createStripeProduct({
        name: invoice.name,
        stripeAccount: invoice.stripeAccount,
      })
      .then(async (res) => {
        if (res.object === "product") {
          //("product created");
        }
      })
      .catch((error) => {
        //("error", error);
      });
  };
};


export const checkLoading = () => {
  return  (dispatch, getState) => {

    const { companyState } = getState();

    return companyState.loading


  }
}

export const myCompanyState = () => {
  return async (dispatch, getState) => {
    const { companyState } = getState();
    return companyState
  };
};

export const createStripePrice = (price, product) => {
  return async (dispatch, getState) => {
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));
    var t = await StripeClient
      .createPrice({
        currency: price.currency,
        unit_amount: price.amount,
        stripeAccount: price.stripeAccount,
        recurring: { interval: price.schedule },
        product: product,
      })
      .then(async (res) => {
        if (res.object === "price") {
          //(res.data);

          const onSuccess = () => {
            return true;
          };
          const callback = {
            onSuccess,
          };
          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

          //   //(price.docId)
         await dispatch(
            updateInvoice({
              invoiceId: price.document,
              data: { priceId: res.id },
              callback,
            })
          );
        }
      })
      .catch((error) => {
        //("error", error);
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));
      });
  };
};

export const saveRecurringInvoice = (invoice, schedule) => {
  return async (dispatch, getState) => {
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));
    var price = {
      currency: invoice.currency,
      amount: invoice.amount,
      stripeAccount: invoice.stripeAccount,
      schedule: schedule,
      document: invoice.docId,
    };

    StripeClient
      .createProduct({
        name: price.document,
        stripeAccount: invoice.stripeAccount,
      })
      .then(async (res) => {
        if (res.object === "product") {
          //("product created");
          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));
          dispatch(createStripePrice(price, res.id));
        }
      })
      .catch((error) => {
        //("error", error);
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));
      });
  };
};

export const attatchPaymentMethod = (
  paymentMethod,
  customer,
  stripeAccount
) => {
  return async (dispatch, getState) => {
    const { companyState } = getState();

    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));

    //("trying to attatch pm");
    //stitch.attachStripePaymentMethod({ customer: "cus_Hmop1GNanROW1i", id: paymentMethod, stripeAccount:stripeAccount})
    AppStitch
      .attachStripePaymentMethod({
        customer: customer,
        stripeAccount: stripeAccount,
        id: paymentMethod,
      })
      .then((pm) => {
        //("payment method saved");
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));
        const subscription = {
          price: companyState.activeInvoice.priceId,
          customer: companyState.activeInvoice.stripeCustomerId,
          stripeAccount: companyState.activeInvoice.stripeAccountId,
          paymentMethod: pm.data.id,
        };

        dispatch(
          createStripeSubscription(
            subscription,
            companyState.activeInvoiceId,
            false
          )
        );
       

      })
      .catch((err) => {
        //(err);
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

      });
  };
};

export const attatchPaymentMethodFruitbowl = (
  paymentMethod,
  customer,
  stripeAccount,
  document

) => {
  return async (dispatch, getState) => {
    const { companyState } = getState();
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));

    //("trying to attatch pm");
    //stitch.attachStripePaymentMethod({ customer: "cus_Hmop1GNanROW1i", id: paymentMethod, stripeAccount:stripeAccount})
    AppStitch
      .attachStripePaymentMethod({
        customer: customer,
        stripeAccount: stripeAccount,
        id: paymentMethod,
      })
      .then((pm) => {
        //("payment method saved");
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

        const subscription = {
          price: "price_1HM1DaAAaeaoFWBpsAkyIDsK",
          customer: customer,
          stripeAccount: stripeAccount,
          paymentMethod: pm.data.id,
          doc: document
        };

        dispatch(
          createStripeSubscription(
            subscription,
            companyState.userDoc,
            true
          )
        );
      })
      .catch((err) => {
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));
        dispatch(showError({ message: "Unable to process your payment. Please try again." }));
        //(err);
      });
  };
};

export const createPaymentIntent = (paymentIntent) => {
  return async (dispatch, getState) => {
    //("trying to attatch pm");
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));

    //stitch.attachStripePaymentMethod({ customer: "cus_Hmop1GNanROW1i", id: paymentMethod, stripeAccount:stripeAccount})
    AppStitch
      .createPaymentIntent({
        amount: paymentIntent.amount,
        customer: paymentIntent.customer,
        stripeAccount: paymentIntent.stripeAccount,
        currency: paymentIntent.currency,
        confirm: true,
        payment_method: paymentIntent.paymentMethod,
      })
      .then(async(res) => {

        if (res.object === "payment_intent") {
        
          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

        //("payment Success");
        const onSuccess = () => {

          return true;
        };
        const callback = {
          onSuccess,
        };

        var paidDate = new Date(new Date().getTime());

        var formatted = format(paidDate, "MMM d, yyyy");

       await dispatch(
          updateInvoice({
            invoiceId: paymentIntent.invoice,
            data: {
              status: "PAID",
              stat: "primary",
              paidOn: paidDate,
              paidOnString: formatted,
            },
            callback,
          })
        );
        dispatch(setValue(ActionTypes.SET_LOADING, false))
        dispatch(showSuccess("Invoice has been paid!"));
       

        window.location.reload();
        }
        else {
          //("error")
          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

        }
        
      })
      .catch((err) => {
        dispatch(setValue(ActionTypes.SET_LOADING, false))
        //(err);
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

      });
  };
};

export const createStripeSubscription = (subscription, docId, type) => {
  return async (dispatch, getState) => {
    const { companyState } = getState();
    //("attempt sub");
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));

    //(subscription);

    if (type == false) {
      var paidDate = new Date(new Date().getTime());

      var formatted = format(paidDate, "MMM d, yyyy");

      //clients of fruitbowl.io customers
      StripeClient
        .createSubscription({
          items: [{ price: subscription.price }],
          customer: subscription.customer,
          stripeAccount: subscription.stripeAccount,
          default_payment_method: subscription.paymentMethod,
        })
        .then( async(res) => {
          if (res.object === "subscription") {
            dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

            const onSuccess = () => {
              return true;
            };
            const callback = {
              onSuccess,
            };

           await dispatch(
              updateInvoice({
                invoiceId: docId,
                data: {
                  activeSubscription: true,
                  status: "PAID",
                  stat: "primary",
                  paidOn: paidDate,
                  paidOnString: formatted,
                },
                callback,
              })
            );
            //("Subscription created");
              window.location.reload()
             
            dispatch(setValue(ActionTypes.SET_LOADING, false))
            
          }
        })
        .catch((error) => {
          dispatch(setValue(ActionTypes.SET_LOADING, false))
          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));



          dispatch(
            showError({ message: "Stripe subscrption couldn't be saved" })
            
          );
          //(error);
        });
    } else {
      //fruitbowl.io customers

      StripeClient
        .createSubscription({
          items: [{ price: "price_1HM1DaAAaeaoFWBpsAkyIDsK" }],
          customer: subscription.customer,
          default_payment_method: subscription.paymentMethod,
        })
        .then(async (res) => {
          if (res.object === "subscription") {
            dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

            const onSuccess = () => {
              return true;
            };
            const callback = {
              onSuccess,
            };

            var dueDate = new Date(
              new Date().getTime() + 35 * 24 * 60 * 60 * 1000
            );

            // dispatch(
            //   updateUser({
            //     userDoc: companyState.setUser.id,
            //     data: { newUserProgress: 3, current_period_end: dueDate },
            //     callback,
            //   })
            // );

            const cityRef = db.collection("Users").doc(companyState.setUser.id);
            dispatch(showSuccess({ message: "Payment Successful!" }));
            // Set the 'capital' field of the city
            const resi = await cityRef.update({
              newUserProgress: 3,
              current_period_end: dueDate,
            }).then((res) => {

              dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

            }).catch((eror) => {

              dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

            });
            dispatch(setValue(ActionTypes.SET_LOADING, false))
            //("Subscription created");
           // dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

            window.location.reload();
          }
        })
        .catch((error) => {
          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

          dispatch(setValue(ActionTypes.SET_LOADING, false))
          dispatch(
            showError({ message: "Stripe subscrption couldn't be saved" })
          );
          dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

          //(error);
        });
    }
  };
};

export const updateInvoice = ({ invoiceId, data, callback }) => {
  return async (dispatch) => {
    //clientID  = "G6VDYTxIRUbLKB9LE9jJjSvC9BD2"
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));

    //("updating invoice....")
    let citiesRef = await db
      .collection("Invoices")
      .doc(invoiceId)
      .update(data)
      .then((result) => {
        callback.onSuccess();
        //("invoice Updated....")
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

      })
      .catch((err) => {
        //("invoice couldnt be Updated....")
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

      });
  };
};

export const updateUser = ({ userDoc, data, callback }) => {
  return async (dispatch) => {
    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));

    //clientID  = "G6VDYTxIRUbLKB9LE9jJjSvC9BD2"
    let citiesRef = await db
      .collection("Users")
      .doc(userDoc)
      .update(data)
      .then((result) => {
        callback.onSuccess();
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

      })
      .catch((err) => {
        //(err);
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

      });
  };
};
export const getUserInfo = (clientID) => {
  var testVar = false;
  return async (dispatch) => {
    //clientID  = "G6VDYTxIRUbLKB9LE9jJjSvC9BD2"
   // dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));
   dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, true));

    
    let citiesRef = await db.collection("Users");

    let query = await citiesRef
      .where("uid", "==", clientID)
      .get()
      .then((snapshot) => {
        const newcontractlist = [];
        snapshot.forEach((doc) => {
          const cust = {
            id: doc.id,
            data: doc.data(),
          };
          // const data = doc.data()
          dispatch(setValue(ActionTypes.SET_USER, cust));
          //(cust);
          testVar = true;
          dispatch(setValue(ActionTypes.SET_USER_DOC, cust.id));

          // //(this.newclientlist);
        });
     //   dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));
        //  dispatch(setValue(ActionTypes.SET_USER, newcontractlist))
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

      })
      .catch((err) => {
        dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));
    //    dispatch(setValue(ActionTypes.SET_GLOBAL_LOADING, false));

        console.log("Error getting documents", err);
      });
  };

  return testVar;
};

const setValue = (type, payload) => {
  //(type, payload);
  return {
    type: type,
    payload,
  };
};

const setNewServices = (type, payload) => {
  //(type, payload);
  return {
    type: type,
    payload,
  };
};

export { setValue, setNewServices };
