import firebase from 'firebase';
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'


const config = {
    apiKey: "AIzaSyASrquHYXkanYviDFfgEuEpD7GF035mhW4",
    authDomain: "app.fruitbowl.io",
    databaseURL: "https://fruitbowl-c0d90.firebaseio.com",
    projectId: "fruitbowl-c0d90",
    storageBucket: "fruitbowl-c0d90.appspot.com",
    messagingSenderId: "249058193283",
    appId: "1:249058193283:web:e33c901374af670b2390b6",
    measurementId: "G-GXBT028BBH"
  };
  var cors = require('cors')

// firebase.initializeApp({
//   apiKey: "AIzaSyASrquHYXkanYviDFfgEuEpD7GF035mhW4",
//   authDomain: "fruitbowl-c0d90.firebaseapp.com",
//   databaseURL: "https://fruitbowl-c0d90.firebaseio.com",
//   projectId: "fruitbowl-c0d90",
//   storageBucket: "fruitbowl-c0d90.appspot.com",
//   messagingSenderId: "249058193283",
//   appId: "1:249058193283:web:e33c901374af670b2390b6",
//   measurementId: "G-GXBT028BBH"
// });

//var defualtApp = firebase.initializeApp(config)
const app = firebase.initializeApp(config);




export const db = app.firestore();
export const storage = app.storage();
export const auth = app.auth();
export const fb = firebase
// export default fire;