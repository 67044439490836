/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { format } from "date-fns";
import Moment from "react-moment";
import {
  withRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";
import stripeLogo from "../../views/blue-on-dark@3x.png"; // with import

import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  CardBody,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Button,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
  Nav,
  Spinner,
  NavItem,
  NavLink,
  Modal,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";
import { getInvocies, getUserInfo } from "../../redux/actions/CompanyActions";
import { connect } from "react-redux";
import ActionTypes from "redux/ActionTypes";
import { setValue } from "../../redux/actions/CompanyActions";
import { db, auth } from "./Fire";
import { da } from "date-fns/locale";
Moment.globalFormat = "D MMM YYYY";

const style = {
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "red",
  backgroundColor: "red",
};

class Proposals extends React.Component {
  //var formattedDate = format(today, "MMM d, yyyy");

  state = {
    defaultModal: false,
    hasInvoices: false,
    onboarded: true,
    hasPaid: true,
    loading: false,
    newUserProgress: 3,
    newUserState: 0,
    rosie: 0,
    signedIn: false,
    stripeSetup: false
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  dummyInvoice = {
    id: "DUMMYINVOICE#001",
    data:{
    billingSchedule: "Once Off",
    client: "Rosie's Beauty Ltd",
    clientEmail: "rosie.m@rosiesbeauty.com",
    clientName:"Rosie M",
    clientId: "testclientId",
    company: "test como",
    createdOnString: "Sept 10, 2020",
    contractSigned: true,
    signedOnString:"Sept 24, 2020",
    paidOnString: "Sept 24, 2020",
    contractName: "Service Provider Terms.pdf",
    currency:   {
      "symbol": "$",
      "name": "US Dollar",
      "symbol_native": "$",
      "decimal_digits": 2,
      "rounding": 0,
      "code": "USD",
      "name_plural": "US Dollar's"
  },
    dueOnString: "Sept 25, 2020",
    clientSignature: "https://firebasestorage.googleapis.com/v0/b/fruitbowl-c0d90.appspot.com/o/signature.jpg?alt=media&token=44509e03-c0b2-46de-8cf1-a332bfd8d13f",
    invoiceType: false,
    memo: "Thank you for your business!",
    services: [
      { name: "Website Creation", price: 1500, qty: "1" }
    ],
    stat: "success",

    status: "PAID",
    symbol:  "£",
    total: 1800,
    taxRate: 20,
  }
  };

  renderInvoice = (invoice, index) => {
    const { client, createdOnString, status, total, stat } = invoice.data;

    return (
      <tr key={index}>
        <td>{client}</td>
        <td>
          {this.props.companyState.setUser.data.currency.symbol}
          {total}
        </td>
        <td>{createdOnString}</td>

        <td>
          {" "}
          <Badge color={stat} pill>
            {status}
          </Badge>
        </td>

        <td>
          {" "}
          <Button
            color="default"
            size="sm"
            onClick={() => this.openInvoice(index)}
            //  style={style}
          >
            View Invoice
          </Button>
        </td>
      </tr>
    );
  };
  nextPath(path) {
    this.props.history.push(path);
  }


openDummyInvoice = () =>{

 // var t = this.props.companyState.setInvoices[index];
    this.props.setValue(ActionTypes.SET_CURRENT_INVOICE, this.dummyInvoice);
    //(t);
    this.nextPath("/detail/viewinvoice");

}

  openInvoice = (index) => {
    //("hi");

    var t = this.props.companyState.setInvoices[index];
    this.props.setValue(ActionTypes.SET_CURRENT_INVOICE, t);
    //(t);
    this.nextPath("/detail/viewinvoice");
  };

  //   getUserData = () => {

  //     const citiesRef = db.collection('Users');
  //     const snapshot =  citiesRef.where('uid', '==', this.props.companyState.setUserID).get();
  //     if (snapshot.empty) {
  //       //('No matching documents.');
  //       return;
  //     }

  //       this.props.setValue(ActionTypes.SET_USER, snapshot.data);

  // }

  async getUid() {
    let user = await auth.currentUser.uid;

    return user;
  }

  componentDidMount() {
    // this.authListener();

    // this.getCurrentUser();

    this.loadProposalView();
    this.props.setValue(ActionTypes.SET_AUTH_VIEW, 0);

    this.props.setValue(ActionTypes.SET_ACTIVE_VIEW, 0);
    //this.props.setValue(ActionTypes.SET_USER, db.);
    this.setState({ hasInvoices: this.props.companyState.hasInvoices });
    console.log(this.state.newUserState);
    // var t = auth.currentUser.uid.get
    this.setState({ rosie: 2 });
    console.log(this.state.rosie);
    //this.authtracker();
    this.props.setValue(ActionTypes.SET_SERVICES, []);

    // this.toggleModal("defaultModal")
  }

  checkOnboarding = async () => {
    //this.props.companyState.setUser[0].data.onboardingComplete

    //  var t = await this.props.getUserInfo(this.props.companyState.setUserID)

    if (this.props.companyState.setUser != null) {
      if (this.props.companyState.setUser.data.onboardingComplete == true) {
        this.setState({stripeSetup: true})
        return; //("user already onboarded");
      } else if (
        this.props.companyState.setUser.data.onboardingComplete == false
      ) {
        this.setState({stripeSetup: false})
        return this.toggleModal("defaultModal");

      }
    } else {
      //("setUser not set")
    }
  };

  getCurrentUser = async () => {
    var userId = "";

    await auth.onAuthStateChanged((user) => {
      if (user) {
        userId = user.uid;
        //(userId);
        this.setState({ userId: user.uid });
      }
      return userId;
    });
  };
  getOnboardState = async (uid) => {
    var testState = 0;
    let citiesRef = await db.collection("Users");

    //var uid = await this.getCurrentUser()
    let query = await citiesRef
      .where("uid", "==", uid)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const user = {
            id: doc.id,
            data: doc.data(),
          };

          //  console.log('Document data:', query.data());
          //    var data = query.data()
          this.setState({ newUserState: user.data.newUserProgress });
          this.props.setValue(
            ActionTypes.NEW_USER_PROGRESS,
            user.data.newUserProgress
          );
        });
      });
  };

  authListener = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.setState({ signedIn: true, onboarded: true });
        await this.props.getUserInfo(user.uid);
        await this.getOnboardState(user.uid);
        var date = new Date();
        var seconds = date.getTime() / 1000;
        //(seconds)

        if (this.props.companyState.setUser.data.current_period_end == null) {
        } else {
          var untilDate = this.props.companyState.setUser.data
            .current_period_end.seconds;

          if (seconds < untilDate) {
            //("Active sub")
            this.setState({ hasPaid: true });
          } else {
            //("need to pay")
            this.setState({ hasPaid: false });
            this.updateUserProgress();
          }
        }
        //   var newDate = new Date(untilDate);
        // //(newDate)

        this.props.getInvocies(user.uid);
        this.checkOnboarding();
      } else {
        //not signed in
        this.setState({ signedIn: false, onboarded: false, newUserState: 0 });
      }
    });
  };

  loadProposalView = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        //signed in

        await this.props.getUserInfo(user.uid);
        await this.getOnboardState(user.uid);

        if (this.state.newUserState == 3) {
          //signed in and onboarded fully
          var date = new Date();
          var seconds = date.getTime() / 1000;
          this.props.getInvocies(user.uid);
          this.checkOnboarding();

          var untilDate = this.props.companyState.setUser.data
            .current_period_end.seconds;

          if (seconds < untilDate) {
            //("Active sub")
            this.setState({ hasPaid: true });
          } else {
            //("need to pay")
            this.setState({ hasPaid: false });
            this.updateUserProgress();
          }
        }

        this.setState({ onboarded: true });
        console.log(this.state.newUserState);
      } else {
        //not signed in
        this.setState({ newUserState: 0 });
        this.setState({ onboarded: false });
      }
    });
  };

  updateUserProgress = async () => {
    const cityRef = db
      .collection("Users")
      .doc(this.props.companyState.setUser.id);

    // Set the 'capital' field of the city
    const res = await cityRef.update({ newUserProgress: 2 }).then((res) => {
      window.location.reload();
    });
  };

  chooseInvoiceType = (value) => {
    if (value == 0) {
      this.props.setValue(ActionTypes.SET_INVOICE_TYPE, true);
      this.nextPath("/detail/createproposal");
    } else if (value == 1) {
      this.props.setValue(ActionTypes.SET_INVOICE_TYPE, false);
      this.nextPath("/detail/createproposal");
    }
  };

  onboardStripe = () => {
    var xhr = new XMLHttpRequest();

    // get a callback when the server responds
    xhr.addEventListener("load", () => {
      // update the state of the component with the result here
      //(xhr.responseText)
    });
    // open the request with the verb and the url
    xhr.open(
      "GET",
      "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_HZINUW2GOvZhPNQEPG3qkihGR9HlU6Bl&scope=read_write"
    );
    // send the request
    xhr.send();
  };

  // testFunc = async() => {

  //   await this.props.getUserInfo("G6VDYTxIRUbLKB9LE9jJjSvC9BD2")
  //   console.log(this.props.companyState.setUser)
  //   return this.props.companyState.setUser.data.newUserProgress
  // }

  render() {
    const invoices = this.props.companyState.setInvoices || [];
    const { hasInvoices } = this.state;
   // const onboardedComplete = this.props.companyState.setUser.data.onboardingComplete || false
    var progress = this.state.newUserState;

    return (
      <>
        {this.state.onboarded == true && this.state.newUserState == 2 ? (
          <Redirect from="/admin/proposal" to="/detail/billing" />
        ) : this.state.onboarded == true && this.state.newUserState == 1 ? (
          <Redirect from="/admin/clients" to="/detail/onboarding" />
        ) : this.state.onboarded == false && this.state.newUserState == 0 ? (
          <Redirect from="/admin/clients" to="/auth/login" />
        ) : (
          <div>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.defaultModal}
              // toggle={() => this.toggleModal("defaultModal")}
            >
              <div className="modal-header">
                <h2 className="modal-title" id="modal-title-default">
                  Hooray! Your 7 day trial is now active
                </h2>
                {/* <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button> */}
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                 You can now start using Fruitbowl & experience all of it's time saving features!
                </p>
                <p>To send your first invoice, you'll need to connect your Stripe account. Don't worry though, you can always do this later in Settings.</p>
                <a href="https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_HZINUW2GOvZhPNQEPG3qkihGR9HlU6Bl&scope=read_write">
                  {" "}
                  <img width="50%" src={stripeLogo}></img>
                </a>
              </div>
            </Modal>

            <Header></Header>
            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow border-0">
                    <CardHeader className="bg-transparent pb-5">
                      <Row className="align-items-center">
                        <div className="col">
                          <h2 className="text-black mb-0">Invoices</h2>
                        </div>
                        <div className="col">
                          <Nav className=" justify-content-end " pills>
                            <NavItem>
                              <Button
                                color="primary"
                                onClick={() => {

                                  this.props.setValue(ActionTypes.SET_INVOICE_CLIENT_ID, null);
                                  this.props.setValue(ActionTypes.SET_INVOICE_CLIENT, null);
                                  this.nextPath("/detail/createproposal")
                                }
                                 
                                }
                              >
                                New Invoice
                              </Button>
                            </NavItem>
                          </Nav>
                        </div>
                      </Row>
                    </CardHeader>

                    {this.props.companyState.globalLoading === true ? (
                      <CardBody>
                        <Col>
                          <Row className="justify-content-center">
                            <Spinner></Spinner>{" "}
                          </Row>
                          <Row
                            style={{ marginTop: "32px" }}
                            className="justify-content-center"
                          >
                            Loading Invoices...
                          </Row>
                        </Col>
                      </CardBody>
                    ) : hasInvoices || this.state.stripeSetup === false ? (
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Client</th>
                            <th scope="col">Value</th>
                            <th scope="col">Date</th>
                            <th scope="col">Status</th>

                            <th scope="col" />
                          </tr>
                        </thead>

                        {this.state.stripeSetup ? (
                          <tbody>{invoices.map(this.renderInvoice)}</tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td>{this.dummyInvoice.data.client}</td>
                              <td>
                               $
                                {this.dummyInvoice.data.total}
                              </td>
                              <td>{this.dummyInvoice.data.createdOnString}</td>

                              <td>
                                {" "}
                                <Badge color={"success"} pill>
                                  {"PAID"}
                                </Badge>
                              </td>

                              <td>
                                {" "}
                                <Button
                                  color="default"
                                  size="sm"
                                  onClick={() => this.openDummyInvoice()}
                                  //  style={style}
                                >
                                  View Example Invoice
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    ) : (
                      <div style={{ marginTop: "5%", marginBottom: "5%" }}>
                        <Col>
                          <Row className="justify-content-center">
                            <img
                              width="30%"
                              src="https://blush.ly/1TD8UExZJ/p"
                            ></img>
                          </Row>
                          <Row
                            style={{ marginTop: "16px" }}
                            className="justify-content-center"
                          >
                            <h1>Create your first invoice!</h1>
                          </Row>
                          <Row className="justify-content-center">
                            <p>Tap 'New Invoice' to begin.</p>
                          </Row>

                          <Row className="justify-content-center">
                            <Button
                              color="primary"
                              onClick={() =>
                                this.nextPath("/detail/createproposal")
                              }
                            >
                              New Invoice
                            </Button>
                          </Row>
                        </Col>
                      </div>
                    )}
                  </Card>
                </div>
              </Row>
            </Container>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyState: state.companyState,
});
export default connect(mapStateToProps, {
  getInvocies,
  setValue,
  getUserInfo,
})(Proposals);
